/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
    MdOutlineInsertComment,
    MdOutlineKeyboardBackspace,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import WavesurferPlayer from "@wavesurfer/react";
import { Button, Table, Tabs } from "antd";
import { BsDownload } from "react-icons/bs";
import { MdOutlineLayers } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { GoHeart } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { LuLink } from "react-icons/lu";
import { HiPause, HiPlay } from "react-icons/hi";
import axios from "axios";
import { getCookie } from "../../common/utils";

const TrackDetails = ({ track }) => {
    const navigate = useNavigate();

    const config = {
        options: {
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColor: "#ffffff",
                        strokeWidth: 2,
                    },
                },
            },
            xaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: ["#ffffff"],
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                        fontWeight: 600,
                    },
                    offsetY: 5,
                },
            },
            yaxis: {
                show: false,
            },

            colors: ["#17B3FF"],
            labels: [
                "Energetic",
                "Uplifting",
                "Happy",
                "Chill",
                "Calm",
                "Sad",
                "Dark",
                "Aggressive",
                "Ethereal",
                "Sexy",
                "Epic",
                "Romantic",
            ],
            stroke: {
                width: 2,
                colors: ["#ffffff"],
            },
            fill: {
                opacity: 0.6,
            },
            // markers: {
            //     // size: (seriesIndex) => (seriesIndex === 0 ? 0 : 5),
            //     size: 5,
            //     // colors: "#FF0000",
            //     // colors: (seriesIndex) => (seriesIndex === 0 ? "#ffffff" : "#FA3750"),
            //     // colors: ["#8AC900", "#FFAE00", "#CD53FF", "#FA3750", "#008DD2", "#FFFFFF", "#FFFFFF", "#E31E23", "#009B13", "#F5B3B6", "#FFFFFF", "#FF7100"],

            // },
            markers: {
                size: 0,
            },
            chart: {
                toolbar: {
                    show: false,
                },
            },
        },
        series: [
            {
                name: "",
                data: [20, 40, 12, 65, 16, 25, 41, 29, 18, 24, 51, 33],
            },
            // {
            //     name: "",
            //     data: ["100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%"],
            // },
        ],
    };

    const onPlayPause = () => {
        wavesurfer && wavesurfer.playPause();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`;
    };

    const onChangeTab = (key) => {
        console.log(key);
    };

    const dataSource = [
        {
            key: "1",
            title: (
                <div className="edit-stems-tabs-name">
                    <span className="edit-stems-title">Edit 1</span>
                    <span className="edit-stems-player">
                        {isPlaying === true ? (
                            <HiPause
                                className="pause-btn"
                                size={30}
                                color="#fff"
                                onClick={() => onPlayPause()}
                                style={{ cursor: "pointer" }}
                            />
                        ) : (
                            <HiPlay
                                size={30}
                                color="#fff"
                                onClick={() => onPlayPause()}
                                style={{ cursor: "pointer" }}
                            />
                        )}
                    </span>
                </div>
            ),
            duration: "0:10",
            bpm: "123 BPM",
            btn: (
                <div
                    className="audio-player-icons"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <MdOutlineInsertComment size={25} color="#fff" />
                    <GoHeart size={25} color="#fff" />
                    <FiPlus size={25} color="#fff" />
                    <BsDownload size={25} color="#fff" />
                    <LuLink size={25} color="#fff" />
                </div>
            ),
        },
        {
            key: "2",
            title: (
                <div className="edit-stems-tabs-name">
                    <span className="edit-stems-title">Edit 2</span>
                    <span className="edit-stems-player">
                        <HiPlay
                            size={30}
                            color="#fff"
                            onClick={() => onPlayPause()}
                            style={{ cursor: "pointer" }}
                        />
                    </span>
                </div>
            ),
            duration: "0:40",
            bpm: "123 BPM",
            btn: (
                <div
                    className="audio-player-icons"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <MdOutlineInsertComment size={25} color="#fff" />
                    <GoHeart size={25} color="#fff" />
                    <FiPlus size={25} color="#fff" />
                    <BsDownload size={25} color="#fff" />
                    <LuLink size={25} color="#fff" />
                </div>
            ),
        },
        {
            key: "3",
            title: (
                <div className="edit-stems-tabs-name">
                    <span className="edit-stems-title">Edit 3</span>
                    <span className="edit-stems-player">
                        <HiPlay
                            size={30}
                            color="#fff"
                            onClick={() => onPlayPause()}
                            style={{ cursor: "pointer" }}
                        />
                    </span>
                </div>
            ),
            duration: "0:13",
            bpm: "123 BPM",
            btn: (
                <div
                    className="audio-player-icons"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <MdOutlineInsertComment size={25} color="#fff" />
                    <GoHeart size={25} color="#fff" />
                    <FiPlus size={25} color="#fff" />
                    <BsDownload size={25} color="#fff" />
                    <LuLink size={25} color="#fff" />
                </div>
            ),
        },
    ];

    const columns = [
        {
            title: "Music Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
        },
        {
            title: "BPM",
            dataIndex: "bpm",
            key: "bpm",
        },
        {
            title: (
                <div className="table-header-btn-wrap">
                    <Button
                        className="header-btn"
                        shape="round"
                        icon={<MdOutlineLayers />}
                    >
                        Request Stems
                    </Button>
                    <Button
                        className="header-btn"
                        shape="round"
                        icon={<MdOutlineModeEdit />}
                    >
                        Request New Edit
                    </Button>
                    <BsDownload size={30} color="#fff" />
                </div>
            ),
            dataIndex: "btn",
            key: "btn",
        },
    ];

    const items = [
        {
            key: "1",
            label: "Edits",
            children: (
                <Table dataSource={dataSource} columns={columns} bordered={false} />
            ),
        },
        {
            key: "2",
            label: "Stems",
            children: <Table dataSource={dataSource} columns={columns} />,
        },
    ];

    const [wavesurfer, setWavesurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [audioBlob, setAudioBlob] = useState();
    const blankAudio = "/music/silent_quarter-second.mp3";

    useEffect(() => {
        axios
            .get(`${process.env.apiUrl}track_download?track_id=${track?.track_id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
                responseType: "arraybuffer",
            })
            .then((response) => {
                setIsPlaying(true);
                const blob = new Blob([response.data]);

                setAudioBlob(URL.createObjectURL(blob));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const onReady = (ws) => {
        setWavesurfer(ws);
        setIsPlaying(false);
        setDuration(ws.getDuration());
        ws.on("audioprocess", () => {
            setCurrentTime(ws.getCurrentTime());
        });
    };

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="home-page-components">
                <div
                    className="back-btn"
                    style={{ position: "absolute" }}
                    onClick={() => navigate(-1)}
                >
                    <MdOutlineKeyboardBackspace />
                    Back
                </div>
                <div className="radar-and-player">
                    <ReactApexChart
                        options={config.options}
                        series={config.series}
                        type="radar"
                        height="400"
                        width={450}
                    />
                    <div className="music-player-wrap">
                        <div className="track-title">Music Title</div>
                        <div className="track-details">
                            <span>
                                <div>Genre: Classical</div>
                                <div>Moods: Hopeful, Laid Back</div>
                            </span>
                            <span style={{ borderRight: "1px solid #fff" }}></span>
                            <span>
                                <div>
                                    Duration: {formatTime(currentTime)} / {formatTime(duration)}
                                </div>
                                <div>BPM: 156</div>
                            </span>
                        </div>

                        <div className="music-player-wave-wraper">
                            {isPlaying === true ? (
                                <HiPause
                                    className="pause-btn"
                                    size={60}
                                    color="#fff"
                                    onClick={() => onPlayPause()}
                                    style={{ cursor: "pointer" }}
                                />
                            ) : (
                                <HiPlay
                                    size={60}
                                    color="#fff"
                                    onClick={() => onPlayPause()}
                                    style={{ cursor: "pointer" }}
                                />
                            )}
                            <div>
                                <div>
                                    <WavesurferPlayer
                                        height={80}
                                        waveColor="#FFFFFF40"
                                        progressColor="#ffc400"
                                        autoplay={false}
                                        url={audioBlob || blankAudio}
                                        onReady={(e) => onReady(e)}
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs-section">
                <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
            </div>
        </div>
    );
};

export default TrackDetails;
