import React, { useState } from 'react';
import { Button, Col, DatePicker, Divider, Modal, Row, Switch } from 'antd';
import axios from 'axios';
import { getCookie } from '../../common/utils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getAccessRequestListAction } from '../../pages/ClientAdmin/logic';

export default function AccessRequestDetailsModal({
  showAccessRequestDetailsModal,
  setShowAccessRequestDetailsModal,
  selectedAccessRequest,
}) {
  const user_details_col1_span = 16;
  const user_details_col2_span = 8;

  const dispatch = useDispatch();

  const handleOnApprovalDurationChange = (e) => {
    console.log('handleOnApprovalDurationChange', e);
  };

  const approveAccessRequest = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.apiUrl}admin/accessRequest?status=APPROVED&id=${id}`,
        {
          headers: {
            Authorization: getCookie('accessToken'),
            accept: 'application/json',
          },
        }
      );
      if (response.status === 200 || response.data.data) {
        console.log('Approved Successfully', response.data);
        toast.success(
          response?.data?.message || 'Access Request Approved Successfully',
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        dispatch(getAccessRequestListAction());
        setShowAccessRequestDetailsModal(false);
      }
    } catch (err) {
      console.log('Error in Approving Request', err);
      toast.error('Error in Approving Request', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleAccessRequestAccept = (e, requestDetails) => {
    console.log('AR Accepted', e, requestDetails.id);
    approveAccessRequest(requestDetails.id);
  };

  const handleAccessRequestReject = (e) => {
    console.log('AR rejected', e);
  };

  return selectedAccessRequest ? (
    <div className="access-request-details-modal">
      <Modal
        wrapClassName="access-request-details-modal-wrap"
        title="Access Request"
        centered
        open={showAccessRequestDetailsModal}
        onOk={() => setShowAccessRequestDetailsModal(false)}
        onCancel={() => setShowAccessRequestDetailsModal(false)}
        width={1500}
        footer={false}
      >
        <Divider />

        <div className="access-request-user-details">
          <Row>
            <Col span={user_details_col1_span}>Name:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.name}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Organisation Name:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.organization_name}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Business Email:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.email}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Purpose:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.purpose}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Referee Email:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.referee_email}
            </Col>
          </Row>
        </div>

        <Row className="permission-title">
          <Col span={24}>Permissions</Col>
        </Row>

        <Divider />

        <div className="permissions-contents">
          <Row>
            <Col span={user_details_col1_span}>Client to be Handled:</Col>
            <Col span={user_details_col2_span}>
              <input
                type="text"
                placeholder="Text (Infosys)"
                className="arm-permission-client-input-text"
              />
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Approval Duration:</Col>
            <Col span={user_details_col2_span}>
              <DatePicker
                format={{
                  format: 'DD MMMM YYYY',
                }}
                showNow={false}
                onChange={handleOnApprovalDurationChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>View Tracks/ Edits/ Stems:</Col>
            <Col span={user_details_col2_span}>
              No
              <Switch className="toggle-switch" />
              Yes
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Play Tracks/ Edits/ Stems:</Col>
            <Col span={user_details_col2_span}>
              No
              <Switch className="toggle-switch" />
              Yes
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>
              Download Tracks/ Edits/ Stems:
            </Col>
            <Col span={user_details_col2_span}>
              No
              <Switch className="toggle-switch" />
              Yes
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>
              Upload Tracks/ Edits/ Stems:
            </Col>
            <Col span={user_details_col2_span}>
              No
              <Switch className="toggle-switch" />
              Yes
            </Col>
          </Row>

          <Row className="access-request-modal-buttons">
            <Col span={7}></Col>
            <Col span={5}>
              <Button
                onClick={(e) => {
                  handleAccessRequestAccept(e, selectedAccessRequest);
                }}
                className="arm-btn-approve"
                size="large"
              >
                Approve
              </Button>
            </Col>
            <Col span={5}>
              <Button
                className="arm-btn-reject"
                onClick={handleAccessRequestReject}
                size="large"
              >
                Reject
              </Button>
            </Col>
            <Col span={7}></Col>
          </Row>
        </div>

        <div></div>
      </Modal>
    </div>
  ) : null;
}
