/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Collapse, Tabs } from 'antd';
import RequestTable from '../../components/ClientAdminRequestTable/RequestTable.jsx';
import AccessRequestDetailsModal from '../../components/AccessRequestDetailsModal/AccessRequestDetailsModal.jsx';
import BriefRequestDetailsModal from '../../components/BriefRequestDetailsModal/BriefRequestDetailsModal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAcceptedBriefRequestListAction,
  getAccessRequestListAction,
  getBriefRequestListAction,
  getTracksReceivedListAction,
} from './logic.js';
import TracksReceivedDetailsModal from '../../components/TracksReceivedDetailsModal/TracksReceivedDetailsModal.jsx';
import ClientAdminReports from '../../components/ClientAdminReports/ClientAdminReports.jsx';
import NoDataTable from '../../components/NoDataTable/NoDataTable.jsx';
import { getCookie } from '../../common/utils/index.js';
import moment from 'moment';
import CustomSpin from '../../components/CustomSpin/CustomSpin.jsx';
import Loader from '../../components/Loader/Loader.jsx';
import axios from 'axios';
import UploadTracksModal from '../../components/UploadTrackModal/UploadTrackModal.jsx';
import { toast } from 'react-toastify';

export default function ClientAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(1);

  const [showAccessRequestDetailsModal, setShowAccessRequestDetailsModal] =
    useState(false);
  const [selectedAccessRequest, setSelectedAccessRequest] = useState(false);

  const [showBriefRequestDetailsModal, setShowBriefRequestDetailsModal] =
    useState(false);
  const [selectedBriefRequest, setSelectedBriefRequest] = useState(false);

  const [showTrackReceivedDetailsModal, setShowTrackReceivedDetailsModal] =
    useState(false);
  const [selectedTrackReceived, setSelectedTrackReceived] = useState(false);

  const [allBMQApprovedBriefsData, setAllBMQApprovedBriefsData] =
    useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [briefRequestId, setBriefRequestId] = useState(null);
  const [clientList, setClientList] = useState(false);
  const [selectedClients, setSelectedClients] = useState(['']);
  const [selectedPage, setSelectedPage] = useState(1);
  const [permissionList, setPermissionList] = useState(false);

  const role = getCookie('roles');
  console.log(role, role?.includes('Client Admin'));

  useEffect(() => {
    console.log('from admin');
    if (!clientList) {
      getAllClients();
    }

    if (role?.includes('Client Admin') || role?.includes('BMQ Admin')) {
      getAllPermissions();
    }
  }, []);

  const {
    data: dataSource,
    flag: arFlag,
    loading: CARLoading,
  } = useSelector((state) => state.clientAdminAccessRequestData);

  const {
    data: briefRequestData,
    flag: brFlag,
    loading: BRLoading,
  } = useSelector((state) => state.clientAdminBriefRequestData);

  const {
    data: acceptedBriefRequestData,
    flag: abrFlag,
    loading: ABRLoading,
  } = useSelector((state) => state.bmqAcceptedBriefRequestData);

  const {
    data: tracksReceivedData,
    flag: trFlag,
    loading: TRLoading,
  } = useSelector((state) => state.tracksReceivedData);

  useEffect(() => {
    console.log('selectedTab', selectedTab);
    if (selectedTab == 1) {
      // Access Requests
      if (role?.includes('BMQ Admin')) {
        console.log(selectedClients);
        // need this Api from backend
        // dispatch(
        //   getAccessRequestListAction(
        //     [],
        //     `briefRequestBMQAdmin?client_id=${selectedClients}`
        //   )
        // );
        dispatch(
          getAccessRequestListAction([], '', { page: 1, page_size: 10 })
        );
      } else if (role?.includes('BMQ User')) {
        dispatch(
          getAcceptedBriefRequestListAction([], '', { page: 1, page_size: 10 })
        );
        // getAllBMQApprovedBriefs();
      } else {
        dispatch(
          getAccessRequestListAction([], '', {
            page: selectedPage,
            page_size: 10,
          })
        );
      }
    } else if (selectedTab == 2) {
      // Brief Requests
      if (role?.includes('BMQ Admin')) {
        console.log(selectedClients);
        dispatch(
          getBriefRequestListAction(
            [],
            `briefRequestBMQAdmin?client_id=${selectedClients}`
          )
        );
      } else {
        dispatch(getBriefRequestListAction([], '', { page: 1, page_size: 40 }));
      }
    } else if (selectedTab == 3) {
      console.log('Tracks Received Dispatched');
      dispatch(getTracksReceivedListAction());
    }
    // return () => {
    //   second
    // }
  }, [selectedClients, selectedTab, selectedPage]);

  const handlePageChange = (e) => {
    setSelectedPage(e);
  };

  const getAllClients = async () => {
    try {
      const response = await axios.get(`${process.env.apiUrl}clientList`);
      if (response.data.status == 200 && response.data.response) {
        console.log('Fetched clients Successfully', response.data.response);
        let clients = [
          {
            label: 'All',
            value: '',
          },
        ];

        if (Array.isArray(response.data.response)) {
          response.data.response.forEach((cl) => {
            clients.push({
              label: cl.name,
              value: cl.id,
            });
          });
        }
        setClientList(clients);
      }
    } catch (err) {
      console.log('Error in gettign client list', err);
    }
  };

  const getAllPermissions = async () => {
    try {
      const response = await axios.get(`${process.env.apiUrl}permissionlist`, {
        headers: {
          Authorization: getCookie('accessToken'),
          accept: 'application/json',
        },
      });
      if (
        response.data.status == 200 &&
        response.data?.data &&
        Array.isArray(response.data.data)
      ) {
        console.log('Fetched permissions Successfully', response.data.data);
        setPermissionList(response.data.data);
      }
    } catch (err) {
      console.log('Error in getting permission list', err);
    }
  };

  const onChangeTab = (key) => {
    console.log(key);
    setSelectedTab(key);
  };

  function formatDateFromTimestamp(timestamp) {
    // Convert timestamp to milliseconds
    const date = new Date(timestamp * 1);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Format date as dd-mm-yyyy
    return `${day}-${month}-${year}`;
  }

  // const getAllBMQApprovedBriefs = async () => {
  //   try {
  //     let response = await axios.get(
  //       `${process.env.apiUrl}admin/briefRequestBMQUser`,
  //       {
  //         headers: {
  //           Authorization: getCookie('accessToken'),
  //           accept: 'application/json',
  //         },
  //       }
  //     );

  //     if (response.status === 200 || response.data.data) {
  //       console.log('Brief Requests', response.data);
  //       setAllBMQApprovedBriefsData(response?.data?.response);
  //     }
  //   } catch (error) {
  //     console.log('Error in getting requests', error);
  //     toast.error(
  //       error.response?.data?.message
  //         ? error.response.data.message
  //         : 'Error in getting requests',
  //       {
  //         position: 'top-center',
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       }
  //     );
  //   }
  // };

  const accessRequestColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (name, record, index) => {
        return <span>{(selectedPage - 1) * 10 + index + 1}</span>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 350,
      render: (name, record, index) => {
        return (
          <>
            <span className="ar-name-data">{name}</span>
            <br />
            <span className="ar-name-email">
              {record.email ? record.email : ''}
            </span>
          </>
        );
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD-MM-YYYY'),
    },
    {
      title: 'Valid Upto',
      dataIndex: 'valid_till',
      key: 'valid_till',
      render: (valid_till) =>
        valid_till ? moment(valid_till).format('DD-MM-YYYY') : '_ _/_ _',
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
      width: 400,
      render: (permission) =>
        permissionList && permission && permission.length > 0
          ? permission.map((el) => {
              console.log(permissionList?.filter((per) => per.value == el));
              // return <div key={el}>{el}</div>;
              return (
                <div key={el}>
                  {permissionList?.filter((per) => per.value == el)[0].label}
                </div>
              );
            })
          : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          {status == 'PENDING' ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewARDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
              <span
                className="ar-approved-edit-text"
                onClick={() => {
                  handleViewARDetails(event, record);
                }}
              >
                Edit
              </span>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const briefRequestColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (name, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Requester',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name, record, index) => {
        return (
          <>
            <span className="ar-name-data">{name}</span>
            <br />
            <span className="ar-name-email">
              {record.email ? record.email : ''}
            </span>
          </>
        );
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD-MM-YYYY'),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline_final',
      key: 'deadline_final',
      render: (deadline_final) =>
        deadline_final ? moment(deadline_final).format('DD-MM-YYYY') : '  ',
    },
    // {
    //   title: 'Contact Info.',
    //   dataIndex: 'contact',
    //   key: 'contact',
    //   render: (contact) => <span>{contact ? contact : '+91-XXXXX-XXXXX'}</span>,
    // },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <span>{type ? type : 'New Brief'}</span>,
    },
    {
      title: 'Status',
      dataIndex: role?.includes('BMQ Admin')
        ? 'bmq_approval_status'
        : 'client_request_approval_status',
      key: role?.includes('BMQ Admin')
        ? 'bmq_approval_status'
        : 'client_request_approval_status',
      render: (status, record) => (
        <div>
          {status == 'PENDING' ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewBRDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const tracksReceivedColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (name, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Received From',
      dataIndex: 'received_from',
      key: 'received_from',
      width: 300,
      render: (received_from, record, index) => {
        return (
          <>
            <span className="ar-name-data">{received_from}</span>
          </>
        );
      },
    },
    {
      title: 'Received Date',
      dataIndex: 'received_date',
      key: 'received_date',
      render: (received_date) => moment(received_date).format('DD-MM-YYYY'),
    },
    {
      title: 'Requested On',
      dataIndex: 'requested_on',
      key: 'requested_on',
      render: (requested_on) => moment(requested_on).format('DD-MM-YYYY'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          {status == 'PENDING' ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewTRDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const bmqAdminApprovedBriefsColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record, index) => {
        return (
          <>
            <span className="ar-name-data">{name}</span>
            <br />
          </>
        );
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD-MM-YYYY'),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline_final',
      key: 'deadline_final',
      render: (deadline_final) => moment(deadline_final).format('DD-MM-YYYY'),
    },
    {
      title: 'Request Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => type ?? 'New Brief',
    },
    {
      title: 'Link to Track',
      dataIndex: 'link',
      key: 'link',
      render: (link) => link ?? 'No Link',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          {record.final_status == 'APPROVED' ? (
            <div>Approved</div>
          ) : (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewBRDetails(event, record);
              }}
            >
              View Details
            </span>
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'upload',
      key: 'upload',
      render: (uploadStatus, record) => (
        <div>
          <Button
            className="upload-btn"
            onClick={() => {
              console.log({ record });
              setShowUploadModal(true);
              setBriefRequestId(record?.id);
            }}
          >
            Upload
          </Button>
          {/* {uploadStatus == 'PENDING' ? (
            <Button
              className="ar-pending-text"
              onClick={() => {
                setShowUploadModal(true);
              }}
            >
              Upload
            </Button>
          ) : uploadStatus == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )} */}
        </div>
      ),
    },
  ];

  const items = [
    // {
    //   key: '4',
    //   label: 'Reports',
    //   children: <ClientAdminReports />,
    // },
    // {
    //   key: '5',
    //   label: 'Track Received',
    //   children: (
    //     <RequestTable
    //       columns={tracksReceivedColumns}
    //       dataSource={tracksReceivedData}
    //     />
    //   ),
    // },
  ];

  if (role?.includes('BMQ Admin') || role?.includes('Client Admin')) {
    items.push({
      key: '1',
      label: 'Access Request',
      children:
        arFlag &&
        Array.isArray(dataSource?.data) &&
        dataSource?.data?.length > 0 ? (
          <RequestTable
            columns={accessRequestColumns}
            dataSource={dataSource?.data}
            showPagination={true}
            pagination={{
              total: dataSource.total_count,
              current: selectedPage,
              onChange: handlePageChange,
            }}
          />
        ) : CARLoading ? (
          <Loader />
        ) : (
          <NoDataTable
            loading={CARLoading}
            textToDisplay={'No Access Requests Available'}
          />
        ),
    });
    items.push({
      key: '2',
      // For BMQ admin change the label to 'Client Brief Request'
      label: role?.includes('BMQ Admin')
        ? 'Client Brief Request'
        : 'Brief Request',
      children:
        brFlag &&
        Array.isArray(briefRequestData?.data) &&
        briefRequestData?.data?.length > 0 ? (
          <RequestTable
            columns={briefRequestColumns}
            dataSource={briefRequestData?.data}
          />
        ) : BRLoading ? (
          <Loader />
        ) : (
          <NoDataTable textToDisplay={'No Requests Available'} />
        ),
    });
    items.push({
      key: '3',
      label: 'Track Received',
      children:
        trFlag &&
        Array.isArray(tracksReceivedData?.data) &&
        tracksReceivedData?.data?.length > 0 ? (
          <RequestTable
            columns={tracksReceivedColumns}
            dataSource={tracksReceivedData?.data}
          />
        ) : TRLoading ? (
          <Loader />
        ) : (
          <NoDataTable textToDisplay={'Tracks Received Not Available'} />
        ),
    });
  }

  if (role?.includes('BMQ User')) {
    items.push({
      key: '5',
      label: 'BMQ Admin Approved Briefs',
      children:
        abrFlag &&
        Array.isArray(acceptedBriefRequestData?.response) &&
        acceptedBriefRequestData?.response?.length > 0 ? (
          <RequestTable
            columns={bmqAdminApprovedBriefsColumns}
            dataSource={acceptedBriefRequestData?.response}
          />
        ) : ABRLoading ? (
          <Loader />
        ) : (
          <NoDataTable
            textToDisplay={'Approved Brief Requests Not Available'}
          />
        ),
    });
  }

  const handleViewARDetails = (e, data) => {
    // console.log(e, data)
    setSelectedAccessRequest(data);
    setShowAccessRequestDetailsModal(true);
  };

  const handleViewBRDetails = (e, data) => {
    setSelectedBriefRequest(data);
    setShowBriefRequestDetailsModal(true);
  };

  const handleViewTRDetails = (e, data) => {
    console.log('selectedTrack:', data);
    setSelectedTrackReceived(data);
    setShowTrackReceivedDetailsModal(true);
  };

  const handleSelectedClientChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onChangeMoodTopic = (e) => {
    console.log(e);
    setSelectedClients(e);
  };

  const clientItems = [
    {
      key: 'Clients',
      label: 'Clients',
      children: (
        <Checkbox.Group
          options={clientList}
          onChange={onChangeMoodTopic}
          defaultValue={['']}
        />
      ),
    },
  ];

  return (
    <div className="home-page">
      <Header showSearch={true} />

      <div className="client-admin-component">
        <div
          className="back-btn"
          style={{ position: 'absolute' }}
          onClick={() => navigate(-1)}
        >
          <MdOutlineKeyboardBackspace />
          Back
        </div>

        <div className="client-admin-tabs-section">
          <AccessRequestDetailsModal
            selectedAccessRequest={selectedAccessRequest}
            showAccessRequestDetailsModal={showAccessRequestDetailsModal}
            setShowAccessRequestDetailsModal={setShowAccessRequestDetailsModal}
            roles={role}
            permissionList={permissionList}
          />

          <BriefRequestDetailsModal
            selectedBriefRequest={selectedBriefRequest}
            showBriefRequestDetailsModal={showBriefRequestDetailsModal}
            setShowBriefRequestDetailsModal={setShowBriefRequestDetailsModal}
            formatDateFromTimestamp={formatDateFromTimestamp}
            role={role}
            selectedClients={selectedClients}
          />

          <TracksReceivedDetailsModal
            selectedTrackReceived={selectedTrackReceived}
            showTrackReceivedDetailsModal={showTrackReceivedDetailsModal}
            setShowTrackReceivedDetailsModal={setShowTrackReceivedDetailsModal}
          />

          <UploadTracksModal
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
            briefRequestId={briefRequestId}
          />

          <Tabs
            className="client-admin-tabs"
            defaultActiveKey={'1'}
            items={items}
            onChange={onChangeTab}
          />
        </div>

        {role?.includes('BMQ Admin') && clientList ? (
          <div className="client-dropdown">
            <div className="collapse-wraper">
              <Collapse
                className="filter-collapse"
                defaultActiveKey={''}
                expandIconPosition={'end'}
                ghost
                accordion
                items={clientItems}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
