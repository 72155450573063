/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { genericActionCreator } from "../../app/epics";

const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const POST_LOGIN = "POST_LOGIN";
const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";
const POST_LOGIN_RESET = "POST_LOGIN_RESET";

export const postLoginAction = (payload) =>

    genericActionCreator(POST_LOGIN, payload, {

        url: `${process.env.apiUrl}login`,
        method: "POST",
        auth: true,

    });


export const postLoginResetAction = () => ({
    type: POST_LOGIN_RESET,
});

export function postLoginReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case POST_LOGIN_RESET: {
            return INITIAL_STATE;
        }
        case POST_LOGIN: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case POST_LOGIN_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case POST_LOGIN_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}
