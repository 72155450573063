import { combineReducers } from "@reduxjs/toolkit";
import { modalReducer } from "./Modal/logic";
import { sessionReducer } from "./Status/logic";
import { customNotificationReducer, notificationReducer } from "../common/Notification/logic";
import { getImagesReducer } from "./pics/Pics.logic";
import { postSignupReducer } from "../pages/signup/logic";
import { getHomeCardsReducer, getRecentlyAddedReducer } from "../pages/home/logic";
import { getCharactersListReducer, getGenresListReducer, getMoodsListReducer, getThemesListReducer, getTrackListReducer, storeTrackIdReducer } from "../pages/topGenreDetail/logic";
import { getAccessRequestListReducer, getBriefRequestListReducer } from "../pages/ClientAdmin/logic";
import { postLoginReducer } from "../pages/login/logic";

const rootReducer = combineReducers({
  modal: modalReducer,
  session: sessionReducer,
  notification: notificationReducer,
  customNotification: customNotificationReducer,
  images: getImagesReducer,
  signupData: postSignupReducer,
  loginData: postLoginReducer,
  homeCardData: getHomeCardsReducer,
  trackListData: getTrackListReducer,
  clientAdminAccessRequestData: getAccessRequestListReducer,
  clientAdminBriefRequestData: getBriefRequestListReducer,
  storeTrackId: storeTrackIdReducer,
  genresList: getGenresListReducer,
  themesList: getThemesListReducer,
  moodsList: getMoodsListReducer,
  charactersList: getCharactersListReducer,
  recentlyAddedTracks: getRecentlyAddedReducer,
});

export default rootReducer;
