/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Menu } from "antd";
import SvgLoader from "../../common/SvgLoader";
import SquareCard from "../../components/SquareCard";
import {
  homeDataRecentlyAdded,
  homeDataSuggestion,
} from "./demoHomeData";
import CircularCard from "../../components/CircularCard";
import {
  ClockCircleOutlined,
  DownloadOutlined,
  HeartOutlined,
  MoreOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getHomeCardsAction, getRecentlyAddedAction } from "./logic";
import { handleLogout } from "../../common/utils";
import ReactApexChart from "react-apexcharts";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [genreId, setGenreId] = useState("");



  useEffect(() => {
    dispatch(getHomeCardsAction({}));
    dispatch(getRecentlyAddedAction({}));
  }, []);

  const { data: homeCardData } = useSelector((state) => state.homeCardData);
  const { data: recentlyAddedTracks } = useSelector((state) => state.recentlyAddedTracks);

  console.log({ recentlyAddedTracks });
  const items = [
    {
      key: "home",
      icon: <SvgLoader svgName="home-icon" height={40} width={40} />,
      label: "Home",
    },
    {
      key: "explore",
      icon: <SvgLoader svgName="explore-icon" height={40} width={40} />,
      label: "Explore",
    },
    {
      key: "gallery",
      icon: <SvgLoader svgName="gallery-icon" height={40} width={40} />,
      label: "My Gallery",
    },
    {
      key: "request",
      icon: <SvgLoader svgName="request-icon" height={40} width={40} />,
      label: "Request New Brief",
    },
    {
      key: "upload",
      icon: <UploadOutlined style={{ fontSize: "40px" }} />,
      label: "Upload Track",
    },
    {
      key: "admin-panel",
      icon: <MdOutlineAdminPanelSettings size={40} />,
      label: "Admin Panel",
    },
    {
      key: "logout",
      icon: <SvgLoader svgName="logout-icon" height={40} width={40} />,
      label: "Logout",
    },
  ];
  const onClick = (e) => {
    console.log("click ", e);
    if (e.key === "explore") {
      navigate("/top-genre-detail", { state: { genreId: genreId, title: "All Tracks" } });
    }
    if (e.key === "gallery") {
      navigate("/my-gallery");
    }
    if (e.key === "request") {
      navigate("/request-new-track");
    }
    if (e.key === "upload") {
      navigate("/uploaded-tracks");
    }
    if (e.key === "admin-panel") {
      navigate("/client-admin");
    }
    if (e.key === "logout") {
      handleLogout();
    }
  };

  return (
    <div className="home-page">
      {" "}
      <Header showSearch={true} />
      <div className="home-page-components">
        <div className="menu-bar">
          <Menu
            onClick={onClick}
            defaultSelectedKeys={["home"]}
            // defaultOpenKeys={["sub1"]}
            mode="inline"
            items={items}
            className="menu"
          />
        </div>
        <div className="home-content">
          {/* {homeCardData && */}
          <div className="section-genre">
            <div className="section-genre-title">Top Genre</div>
            <div className="section-genre-top">
              {homeCardData?.data?.genres?.map((e, i) => {
                return (
                  <SquareCard
                    key={i}
                    name={e?.name}
                    count={e?.count}
                    icon={e?.logo_url?.split(" ")[0]}
                    index={i}
                    imageHeight={150}
                    imageWidth={180}
                  // onClick={navigate("/top-genre-detail", { state: { genreId: e.genreId, title: e.genre_name } })}
                  />
                );
              })}
            </div>
          </div>
          {/* } */}
          <div className="section-mood">
            <div className="section-mood-title">Top Mood</div>
            <div className="section-mood-top">
              {homeCardData?.data?.moods?.map((e, i) => {
                return (
                  <CircularCard
                    name={e?.name}
                    count={e?.count}
                    icon={e?.logo_url?.split(" ")[0]}
                    index={i}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
          <div className="section-genre">
            <div className="section-genre-title">Top Theme</div>
            <div className="section-genre-top">
              {homeCardData?.data?.themes?.map((e, i) => {
                return (
                  <SquareCard
                    key={i}
                    name={e?.name}
                    count={e?.count}
                    icon={e?.logo_url?.split(" ")[0]}
                    index={i}
                    imageHeight={150}
                    imageWidth={180}
                  />
                );
              })}
            </div>
          </div>
          {/* <div className="section-mood">
            <div className="section-mood-title">Popular/Trending</div>
            <div className="section-mood-top">
              {homeDataMood?.map((e, i) => {
                return <CircularCard data={e} index={i} key={i} />;
              })}
            </div>
          </div> */}
          <div className="section-recently-suggestion">
            <div className="section-recently">
              <div className="title-4">
                <span>Recently Added</span>
                <span className="title-see-all">See All</span>
              </div>
              <div className="track-list">
                {recentlyAddedTracks?.data?.map((e, i) => {
                  return (
                    <div className="track-details" key={i}>
                      <span className="track-desc">
                        <div className="track-image">
                          <SvgLoader
                            svgName="no-image-found"
                            height={30}
                            width={30}
                          />
                        </div>
                        <div className="title-5">
                          {e?.title} <br />{" "}
                          <span className="song-type">{e?.genres?.map((e) => String(e.name))?.join(", ")}</span>
                        </div>
                      </span>
                      <div className="play-count">
                        <SvgLoader svgName="song-play" height={30} width={30} />
                        <span className="viewed-count">
                          {e?.play_count} Plays
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="section-suggestion">
              <div className="title-4">
                <span>Suggestions</span>
                <span className="title-see-all"> See All</span>
              </div>
              <div className="track-list">
                {homeDataSuggestion?.map((e, i) => {
                  return (
                    <div className="track-details" key={i}>
                      <span className="track-desc">
                        <div className="track-image">
                          <SvgLoader
                            svgName="no-image-found"
                            height={30}
                            width={30}
                          />
                        </div>
                        <div className="title-5">
                          {e?.track_name} <br />{" "}
                          <span className="song-type">Genre</span>
                        </div>
                      </span>
                      <div className="play-count">
                        <SvgLoader svgName="song-play" height={30} width={30} />
                        <span className="viewed-count">{e?.play_count}</span>
                      </div>
                      <div className="track-stats">
                        <span>
                          <HeartOutlined className="track-icons" />{" "}
                          {e?.track_whish}
                        </span>
                        <span>
                          <DownloadOutlined className="track-icons" />{" "}
                          {e?.track_download}
                        </span>
                        <span>
                          <ClockCircleOutlined className="track-icons" />{" "}
                          {e?.time}
                        </span>
                        <span>
                          <MoreOutlined
                            className="track-icons more"
                            id="more-icon"
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Home;
