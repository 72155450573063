/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-constant-condition */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Checkbox, Collapse, Dropdown, Menu, Pagination, Slider } from "antd";
import Search from "antd/es/transfer/search";
import { MdSort } from "react-icons/md";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { GoHeartFill } from "react-icons/go";
import { GoHeart } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getTrackListAction,
    getGenresListAction,
    getThemesListAction,
    getMoodsListAction,
} from "./logic";
import TrackCard from "../../components/TrackCard";

const ExplorePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // const [genreId, setGenreId] = useState();
    const [orderBy, setOrderBy] = useState("ASC");
    const [pageNo, setPageNo] = useState(1);
    const [search, setSearch] = useState(
        location?.state?.trackId ? location?.state?.trackId : ""
    );
    const [selectedMood, setSelectedMood] = useState(
        location?.state?.moodId ? [location?.state?.moodId] : []
    );
    const [selectedTheme, setSelectedTheme] = useState(
        location?.state?.themeId ? [location?.state?.themeId] : []
    );
    const [selectedCharacter, setSelectedCharacter] = useState(
        location?.state?.characterId ? [location?.state?.characterId] : []
    );
    const [selectedGenre, setSelectedGenre] = useState(
        [location?.state?.genreId] || []
    );
    const [resetList, setResetList] = useState(false);
    const [BPMvalue, setBPMvalue] = useState([-120, 200]);
    const [likeFilter, setLikeFilter] = useState(false);

    useEffect(() => {
        dispatch(getGenresListAction({}));
        dispatch(getThemesListAction({}));
        dispatch(getMoodsListAction({}));
    }, []);

    const { data: genresList } = useSelector((state) => state?.genresList);
    const { data: themesList } = useSelector((state) => state?.themesList);
    const { data: moodsList } = useSelector((state) => state?.moodsList);

    useEffect(() => {
        const data = {
            // genre_id: location.state.genreId,
            order_by: orderBy,
            page: pageNo,
            search: search,
            mood: selectedMood.join(", "),
            theme: selectedTheme.join(", "),
            genre: selectedGenre.join(", "),
            min_bpm: BPMvalue[0],
            max_bpm: BPMvalue[1],
            is_liked_filter: likeFilter,
            character: selectedCharacter,
        };
        dispatch(getTrackListAction(data));
    }, [
        location,
        orderBy,
        pageNo,
        BPMvalue,
        selectedTheme,
        selectedGenre,
        selectedMood,
        resetList,
        likeFilter,
    ]);

    const { data: trackListData } = useSelector((state) => state.trackListData);

    const [filteredData, setFilteredData] = useState();

    useEffect(() => {
        setFilteredData({
            moodTopic: moodsList?.response?.moods,
            themeTopic: themesList?.response?.themes,
            genresTopic: genresList?.response?.genres,
        });
    }, [themesList, moodsList, genresList]);

    const onSearchSideFilter = (e) => {
        const searchValue = e.toLowerCase();

        const filtered = Object?.fromEntries(
            Object?.entries(filteredData)?.map(([key, value]) => [
                key,
                value?.filter((item) =>
                    item?.name?.toLowerCase()?.includes(searchValue)
                ),
            ])
        );
        setFilteredData(filtered);
    };

    const onChangeComplete = (value) => {
        setBPMvalue(value);
    };

    const items = [
        {
            key: "Mood",
            label: "Mood",
            children: (
                <div>
                    {filteredData?.moodTopic?.map((item) => (
                        <>
                            <Checkbox
                                className="collapse-checkbox"
                                onChange={(e) => onChangeMoodTopic(e, item?.name)}
                                checked={selectedMood.includes(item?.name)}
                            >
                                {item?.name}
                            </Checkbox>
                        </>
                    ))}
                </div>
            ),
        },
        {
            key: "theme",
            label: "Theme",
            children: (
                <div>
                    {filteredData?.themeTopic?.map((item) => (
                        <>
                            <Checkbox
                                className="collapse-checkbox"
                                onChange={(e) => onChangeThemeTopic(e, item?.name)}
                                checked={selectedTheme.includes(item?.name)}
                            >
                                {item?.name}
                            </Checkbox>
                        </>
                    ))}
                </div>
            ),
        },
        {
            key: "genre",
            label: "Genre",
            children: (
                <div>
                    {filteredData?.genresTopic?.map((item) => (
                        <>
                            <Checkbox
                                className="collapse-checkbox"
                                onChange={(e) => onChangeGenreTopic(e, item?.name)}
                                checked={selectedGenre.includes(item?.name)}
                            >
                                {item?.name}
                            </Checkbox>
                        </>
                    ))}
                </div>
            ),
        },
        {
            key: "BPM",
            label: "BPM",
            children: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="bpm-count">{BPMvalue[0]}</div>
                    <Slider
                        range
                        defaultValue={BPMvalue}
                        max={200}
                        min={-120}
                        style={{ width: "100%" }}
                        onChangeComplete={onChangeComplete}
                    />
                    <div className="bpm-count">{BPMvalue[1]}</div>
                </div>
            ),
        },
    ];

    const onChangeMoodTopic = (e, idx) => {
        if (!e?.target?.checked) {
            setSelectedMood((prev) => prev.filter((item) => item !== idx));
        } else {
            setSelectedMood((prev) => [...prev, idx]);
        }
    };

    const onChangeThemeTopic = (e, idx) => {
        if (!e?.target?.checked) {
            setSelectedTheme((prev) => prev.filter((item) => item !== idx));
        } else {
            setSelectedTheme((prev) => [...prev, idx]);
        }
    };

    const onChangeGenreTopic = (e, idx) => {
        if (!e?.target?.checked) {
            setSelectedGenre((prev) => prev.filter((item) => item !== idx));
        } else {
            setSelectedGenre((prev) => [...prev, idx]);
        }
    };

    // const wavesurferRef = useRef();

    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(255, 205, 0, 0);
    gradient.addColorStop(0, "rgb(255, 205, 0)");
    gradient.addColorStop(1, "rgb(96, 63, 221)");

    const [visible, setVisible] = useState(false);
    // const [currentTime, setCurrentTime] = useState(0);

    const handleClose = () => {
        setVisible(false);
    };

    const menuItems = [
        {
            key: "DESC",
            label: "Oldest to Newest",
            onClick: () => {
                setOrderBy("DESC");
            },
        },
        {
            key: "ASC",
            label: "Newest to Oldest",
            onClick: () => {
                setOrderBy("ASC");
            },
        },
    ];

    const { data: getPlayListData, loading: getPlayListLoading } = useSelector(
        (state) => state.getPlayListData
    );

    const handlePageChange = (page, pageSize) => {
        setPageNo(page);
    };

    function getMergedLength(arr1, arr2, arr3) {
        const mergedArray = [...arr1, ...arr2, ...arr3].filter(
            (item) => item !== undefined && item !== ""
        );
        return mergedArray.length;
    }

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="home-page-components">
                <div className="menu-bar">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="collapse-wraper">
                        <Search
                            placeholder=""
                            allowClear
                            onChange={(e) => onSearchSideFilter(e.target.value)}
                            className="search-field"
                        />
                        <Collapse
                            className="filter-collapse"
                            defaultActiveKey={"Mood"}
                            expandIconPosition={"end"}
                            ghost
                            accordion
                            items={items}
                        />
                    </div>
                </div>
                <div className="home-content">
                    <div className="genre-detail-wrap">
                        <div className="filters">
                            <span className="heading">
                                {getMergedLength(selectedGenre, selectedTheme, selectedMood) ===
                                    1
                                    ? location?.state?.title
                                    : "All Tracks"}
                            </span>

                            <Dropdown
                                overlay={<Menu onClick={handleClose} items={menuItems} />}
                                visible={visible}
                                onVisibleChange={setVisible}
                                trigger={["click"]}
                            >
                                <span className="chip-btn">
                                    <MdSort />
                                    <span>Sort by</span>
                                </span>
                            </Dropdown>
                            {/* <span className="chip-btn">
                                <HiPlay />
                                Play all
                            </span> */}
                            {/* <span className="chip-btn">
                                <SiApplemusic />
                                Add to collection
                            </span> */}
                            <span
                                className="chip-btn"
                                style={{ padding: "15px" }}
                                onClick={() => setLikeFilter((prev) => !prev)}
                            >
                                {likeFilter ? (
                                    <GoHeartFill
                                        size={30}
                                        color={"#ff0000"}
                                        className="all-icons"
                                    />
                                ) : (
                                    <GoHeart size={30} color={"#fff"} className="all-icons" />
                                )}
                            </span>
                        </div>
                        {trackListData &&
                            <TrackCard
                                getPlayListData={getPlayListData}
                                trackListData={trackListData.data}
                                resetList={resetList}
                                setResetList={setResetList}
                            />}

                        <Pagination
                            onChange={handlePageChange}
                            defaultCurrent={trackListData?.page}
                            total={trackListData?.total_count || 0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExplorePage;
