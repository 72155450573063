export const homeDataGenre = [
  {
    genre_name: "Country",
    genre_count: 45,
    genre_image: "country",
  },
  {
    genre_name: "POP",
    genre_count: 45,
    genre_image: "pop",
  },
  {
    genre_name: "Classical",
    genre_count: 45,
    genre_image: "classical",
  },
  {
    genre_name: "R&B",
    genre_count: 45,
    genre_image: "rb",
  },
  {
    genre_name: "Dance",
    genre_count: 45,
    genre_image: "dance",
  },
  {
    genre_name: "Sentimental",
    genre_count: 45,
    genre_image: "sentimental",
  },
  {
    genre_name: "Country",
    genre_count: 45,
    genre_image: "country",
  },
  {
    genre_name: "POP",
    genre_count: 45,
    genre_image: "pop",
  },
  {
    genre_name: "Classical",
    genre_count: 45,
    genre_image: "classical",
  },
  {
    genre_name: "R&B",
    genre_count: 45,
    genre_image: "rb",
  },
  {
    genre_name: "Dance",
    genre_count: 45,
    genre_image: "dance",
  },
  {
    genre_name: "Sentimental",
    genre_count: 45,
    genre_image: "sentimental",
  },
];

export const homeDataMood = [
  {
    mood_name: "Happy",
    mood_count: 45,
    mood_image: "country",
  },
  {
    mood_name: "Romantic",
    mood_count: 45,
    mood_image: "pop",
  },
  {
    mood_name: "Smooth",
    mood_count: 45,
    mood_image: "classical",
  },
  {
    mood_name: "Dreamy",
    mood_count: 45,
    mood_image: "rb",
  },
  {
    mood_name: "Hopeful",
    mood_count: 45,
    mood_image: "dance",
  },
  {
    mood_name: "Epic",
    mood_count: 45,
    mood_image: "sentimental",
  },
  {
    mood_name: "Dreamy",
    mood_count: 45,
    mood_image: "country",
  },
  {
    mood_name: "Hopeful",
    mood_count: 45,
    mood_image: "pop",
  },
  {
    mood_name: "Epi",
    mood_count: 45,
    mood_image: "classical",
  },
  {
    mood_name: "Romatic",
    mood_count: 45,
    mood_image: "rb",
  },
  {
    mood_name: "Happy",
    mood_count: 45,
    mood_image: "dance",
  },
  {
    mood_name: "Dreamy",
    mood_count: 45,
    mood_image: "sentimental",
  },
];
export const homeDataRecentlyAdded = [
  { track_name: "Track track track track track track 1", play_count: 3 },
  { track_name: "Track 2", play_count: 5 },
  { track_name: "Track 3", play_count: 6 },
  { track_name: "Track 4", play_count: 8 },
  { track_name: "Track 5", play_count: 32 },
  { track_name: "Track 6", play_count: 23},

];
export const homeDataSuggestion = [
  { track_name: "Track track track track track track 1", play_count: 3,track_whish:200,time: "5:05",track_download:321 },
  { track_name: "Track 2", play_count: 5,track_whish:200,time: "4:05",track_download:231 },
  { track_name: "Track 3", play_count: 6,track_whish:200,time: "8:40",track_download:11 },
  { track_name: "Track 4", play_count: 8,track_whish:200,time: "4:05",track_download:21 },
  { track_name: "Track 5", play_count: 32,track_whish:200,time: "7:05",track_download:3321 },
  { track_name: "Track 6", play_count: 23,track_whish:200,time: "5:54",track_download:3021},
 
];