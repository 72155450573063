import { Button, Col, Divider, Modal, Row } from 'antd';
import React, { useState } from 'react';
import SvgLoader from '../../common/SvgLoader';
import WavesurferPlayer from '@wavesurfer/react';
import { HiPause, HiPlay } from 'react-icons/hi';
import ReactApexChart from 'react-apexcharts';

export default function TracksReceivedDetailsModal({
  selectedTrackReceived,
  showTrackReceivedDetailsModal,
  setShowTrackReceivedDetailsModal,
}) {
  const tracks = [
    {
      id: 1,
      title: 'Track Option 1',
      isCompleted: true,
      size: 2.3,
    },
    {
      id: 2,
      title: 'Track Option 2',
      isCompleted: true,
      size: 2.3,
    },
    {
      id: 3,
      title: 'Track Option 3',
      isCompleted: true,
      size: 2.3,
    },
  ];

  const reasons = [
    {
      id: 1,
      reason: 'Track Option Sent not Matching',
    },
    {
      id: 2,
      reason: 'Duration Mismatch',
    },
    {
      id: 3,
      reason: 'Zone of Sound/Moods Mismatch',
    },
  ];

  const [selectedTrack, setSelectedTrack] = useState(tracks[0].id);
  const [showTrackSelection, setShowTrackSelection] = useState(true);
  const [showRejectionReasons, setShowRejectionReasons] = useState(false);
  const [selectedReason, setSelectedReason] = useState(1);
  const [showViewMoreTrackDetails, setShowViewMoreTrackDetails] =
    useState(false);

  const handleTracksReceivedAccept = (e) => {
    console.log('Accept this track', selectedTrack);
    setShowTrackReceivedDetailsModal(false);
  };

  const handleTracksReceivedRejectAll = () => {
    console.log('Reject All tracks clicked');
    // show rejection reason screen with submit button
    setShowTrackSelection(false);
    setShowRejectionReasons(true);
  };

  const handleTrackChange = (e) => {
    console.log('Track Selected: ', e.target.value);
    setSelectedTrack(e.target.value);
  };

  const handleRejectionReasonChange = (e) => {
    console.log('Reason Selected: ', e.target.value);
    setSelectedReason(e.target.value);
  };

  const handleSubmitRejectionWithReason = (e) => {
    console.log('handleSubmitRejectionWithReason called: ', selectedReason);
    // after api call response close clear states, close modal
    setShowRejectionReasons(false);
    setShowTrackReceivedDetailsModal(false);
    setShowTrackSelection(true);
  };

  // const handleViewMore = (e) => {
  //   console.log(e);
  //   setShowTrackSelection(false);
  //   setShowViewMoreTrackDetails(true);
  // };

  // const config = {
  //   options: {
  //     plotOptions: {
  //       radar: {
  //         polygons: {
  //           strokeColor: "#ffffff",
  //           strokeWidth: 2,
  //         },
  //       },
  //     },
  //     xaxis: {
  //       labels: {
  //         show: true,
  //         style: {
  //           colors: ["#ffffff"],
  //           fontSize: "16px",
  //           fontFamily: "Montserrat-Regular",
  //           fontWeight: 600,
  //         },
  //         offsetY: 5,
  //       },
  //       range: 1,
  //     },
  //     yaxis: {
  //       show: false,
  //       // stepSize: 0.1,
  //     },

  //     colors: ["#17B3FF"],
  //     labels: moodsList,
  //     stroke: {
  //       width: 2,
  //       colors: ["#ffffff"],
  //     },
  //     fill: {
  //       opacity: 0.6,
  //     },

  //     markers: {
  //       size: 0,
  //     },
  //     chart: {
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: "",
  //       data: moodsListData,
  //     },
  //   ],
  // };

  return (
    <div className="tracks-received-details-modal">
      <Modal
        wrapClassName="tracks-received-details-modal-wrap"
        title=""
        centered
        open={showTrackReceivedDetailsModal}
        onOk={() => setShowTrackReceivedDetailsModal(false)}
        onCancel={() => setShowTrackReceivedDetailsModal(false)}
        width={750}
        footer={false}
        closable={false}
      >
        <div className="title-wrapper">
          <SvgLoader svgName="track-upload-icon" height={60} width={60} />
          <div className="title">Master track XYZ</div>
        </div>

        <Divider />

        {!showViewMoreTrackDetails && (
          <div className="prompt">
            {showRejectionReasons
              ? 'Provide the reason for Rejection'
              : 'Kindly Approve the preferred track from the below options'}
          </div>
        )}

        {showTrackSelection && (
          <div className="track-selection-wrapper">
            {tracks.map((el) => {
              return (
                <div className="track-radio-card">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="selected-track"
                      value={el.id}
                      onChange={handleTrackChange}
                      checked={el.id == selectedTrack}
                    />
                    <span class="custom-radio"></span>
                  </label>
                  <SvgLoader svgName="track-thumbnail" height={69} width={69} />
                  <div className="track-radio-card-text">
                    <div className="track-title">{el.title}</div>
                    <div className="file-upload-text-wrap">
                      <span
                        className="file-upload-text"
                        style={{ color: '#A9ACB4' }}
                      >
                        {/* {false ? (2000 / 1000000).toFixed(1) : '0'} MB */}
                        {el.size} MB
                      </span>
                      <span className="file-upload-text">•</span>

                      <span className="file-upload-text">
                        {!el.isCompleted ? (
                          <>Uploading and Analyzing</>
                        ) : (
                          <>
                            <SvgLoader
                              svgName={'done'}
                              width={20}
                              height={20}
                            />
                            Completed
                          </>
                        )}
                      </span>
                      {true && (
                        <span
                          className="file-upload-text"
                          style={{ color: '#FFCD00', cursor: 'pointer' }}
                          onClick={() => {
                            // handleViewMore();
                          }}
                        >
                          View More
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {showTrackSelection && (
          <Row className="tracks-received-modal-buttons">
            <Col span={7}></Col>
            <Col span={5}>
              <Button
                onClick={handleTracksReceivedAccept}
                className="trm-btn-approve"
                size="large"
              >
                Approve
              </Button>
            </Col>
            <Col className="trm-btn-reject" span={5}>
              <Button onClick={handleTracksReceivedRejectAll} size="large">
                Reject All
              </Button>
            </Col>
            <Col span={7}></Col>
          </Row>
        )}

        {showRejectionReasons && (
          <div className="rejection-reason-wrapper">
            {/* Radio buttons - Done */}
            {/* <div className="rejection-reasons-radio-list">
              {reasons.map((el) => {
                return (
                  <div className="reason-radio-div">
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="selected-reason"
                        value={el.id}
                        onChange={handleRejectionReasonChange}
                        checked={el.id == selectedReason}
                      />
                      <span class="custom-radio"></span>
                      <span className="reason-text">{el.reason}</span>
                    </label>
                  </div>
                );
              })}
            </div> */}

            {/* Text Area - Done*/}
            <div className="other-reason-wrapper">
              <label for="other-reason" className="other-reason-label">
                Others(Please Specify)
              </label>
              <br />
              <textarea
                className="other-reason-textarea"
                name="other-reason"
                rows="5"
                cols="46"
                placeholder="Text inputs..."
              ></textarea>
            </div>

            {/* Submit button */}
            <div className="rejection-reason-btn-wrapper">
              <Row className="tracks-received-modal-buttons">
                <Col span={4}>
                  <Button
                    onClick={handleSubmitRejectionWithReason}
                    className="trm-btn-approve"
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}

        {/* {showViewMoreTrackDetails && (
          <div className="radar-and-player">
            <div className="radar-and-tags" style={{ display: 'flex' }}>
              <ReactApexChart
                options={config.options}
                series={config.series}
                type="radar"
                height="300"
                width={350}
                className="apex-chart-mood-radar"
              />
              <div className="tags-wrapper">
                {moodsList?.map((item, idx) => (
                  <Tag color="#AD2756" key={idx}>
                    {item}
                  </Tag>
                ))}
              </div>
            </div>
            <Divider />

            <div className="music-player-wrap">
              <div className="track-title">
                {trackTitle ? trackTitle : 'Music Title'}
              </div>
              <div className="track-details">
                <span>
                  <div>
                    Genre: {allTrackData?.GENRE?.map((item) => item + ', ')}
                  </div>
                  <div>
                    Rasa: {allTrackData?.RASA?.map((item) => item + ', ')}
                  </div>
                </span>
                <span style={{ borderRight: '1px solid #fff' }}></span>
                <span>
                  <div>BPM: {allTrackData?.BPM}</div>
                </span>
              </div>

              <div className="music-player-wave-wraper">
                <div>
                  {!!viewDetails && audioBlob !== undefined && (
                    <div className="wavesurfer">
                      <WavesurferPlayer
                        height={100}
                        waveColor="#FFFFFF40"
                        progressColor="#ffc400"
                        // autoplay
                        url={checkBlob}
                        onReady={onReady}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                      />
                    </div>
                  )}

                  {audioBlob && isPlaying ? (
                    <HiPause
                      size={60}
                      color="#fff"
                      onClick={onPlayPause}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <HiPlay
                      size={60}
                      color="#fff"
                      onClick={onPlayPause}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )} */}
      </Modal>
    </div>
  );
}
