/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */

import React, { useState } from "react";
import Header from "../../components/Header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineFileAdd } from "react-icons/ai";
import { MdOutlineLibraryMusic } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { BiLike } from "react-icons/bi";
import { MdOutlineComment } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

const MenuCard = ({ title, icon: Icon, count }) => {
    return (
        <div className="card-square" key={title}>
            <div className="icon-background">
                <Icon size={50} />
            </div>
            <div className="text-country">{title}</div>
            <div className="text-songs-count">{count}</div>
        </div>
    );
};
const MyGallary = () => {
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useState("My Briefs");

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="gallery-page-components">
                <div className="menu-bar">
                    <div className="top-heading">
                        {" "}
                        <div className="back-btn" onClick={() => navigate(-1)}>
                            <MdOutlineKeyboardBackspace />
                            Back
                        </div>
                        <div className="heading">My Gallery</div>
                    </div>

                    <div className="card-wraper">
                        <div onClick={() => setSelectedMenu("My Briefs")}>
                            <MenuCard
                                title="My Briefs"
                                icon={AiOutlineFileAdd}
                                count="15 Songs"
                            />
                        </div>
                        <div onClick={() => setSelectedMenu("My Collection")}>
                            <MenuCard
                                title="My Collection"
                                icon={MdOutlineLibraryMusic}
                                count="50 Songs"
                            />
                        </div>
                        <div onClick={() => setSelectedMenu("My Downloads")}>
                            <MenuCard
                                title="My Downloads"
                                icon={FiDownload}
                                count="250 Songs"
                            />
                        </div>
                        <div onClick={() => setSelectedMenu("Likes")}>
                            <MenuCard title="Likes" icon={BiLike} count="45 Songs" />
                        </div>
                        <div>
                            <MenuCard
                                title="My Notes"
                                icon={MdOutlineComment}
                                count="15 Songs"
                            />
                        </div>
                        <div>
                            <MenuCard title="Request New Brief" icon={FaPlus} count="" />
                        </div>
                    </div>
                </div>
                <div className="main-gallery-component">
                    {selectedMenu === "My Briefs" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Briefs</div>
                                    <div className="description">All briefs</div>
                                    <div className="description">15 songs</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedMenu === "My Collection" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Collection</div>
                                    <div className="description">All Collection</div>
                                    <div className="description">50 songs</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedMenu === "My Downloads" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Downloads</div>
                                    <div className="description">All Downloads</div>
                                    <div className="description">250 songs</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedMenu === "Likes" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">Likes</div>
                                    <div className="description">All Likes</div>
                                    <div className="description">45 songs</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyGallary;
