/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Collapse, Tabs } from 'antd';
import RequestTable from '../../components/ClientAdminRequestTable/RequestTable.jsx';
import AccessRequestDetailsModal from '../../components/AccessRequestDetailsModal/AccessRequestDetailsModal.jsx';
import BriefRequestDetailsModal from '../../components/BriefRequestDetailsModal/BriefRequestDetailsModal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccessRequestListAction,
  getBriefRequestListAction,
} from './logic.js';
import TracksReceivedDetailsModal from '../../components/TracksReceivedDetailsModal/TracksReceivedDetailsModal.jsx';
import ClientAdminReports from '../../components/ClientAdminReports/ClientAdminReports.jsx';
import NoDataTable from '../../components/NoDataTable/NoDataTable.jsx';
import { getCookie } from '../../common/utils/index.js';
import moment from 'moment';

const briefRequestData = [
  {
    id: '11530791-37da-40ad-ad08-a9a0a36dbba4',
    user_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
    client_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
    purposes: 'Corporate',
    track_genre: 'jazz',
    stems: 'Yes',
    charecter: '["Agressive", "Calm"]',
    moods: '["Relaxation", "Serenity", "Annoyance"]',
    vocals: 'Male',
    syncs: 'Yes',
    licences: 'Global',
    add_licences: 'Canada',
    additional_notes: 'This is addoitional notes.',
    file_name: 'Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
    file_url:
      'https://storage.googleapis.com/staging-sonic-vault-audio/Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
    first_dedline: '1724740697300',
    last_dedline: '1724740697300',
    durations: '09:00',
    status: 'PENDING',
    type: 'Brief',
    created_at: '1724740697300',
    updated_at: '1724740697300',
    name: 'John',
    email: 'j@gmail.com',
    deadline: {
      first_cut: '1724740697300',
      final_delivery: '1724740697300',
    },
  },
  {
    id: '11530791-37da-40ad-ad08-a9a0a36dbba4',
    user_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
    client_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
    purposes: 'Corporate',
    track_genre: 'jazz',
    stems: 'Yes',
    charecter: '["Agressive", "Calm"]',
    moods: '["Relaxation", "Serenity", "Annoyance"]',
    vocals: 'Male',
    syncs: 'Yes',
    licences: 'Global',
    add_licences: 'Canada',
    additional_notes: 'This is addoitional notes.',
    file_name: 'Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
    file_url:
      'https://storage.googleapis.com/staging-sonic-vault-audio/Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
    first_dedline: '04072024',
    last_dedline: '04072024',
    durations: '09:00',
    status: 'PENDING',
    type: 'Brief',
    created_at: '1724740697300',
    updated_at: '1724740697300',
  },
  {
    key: '1',
    name: 'Mike',
    email: 'a@b.com',
    request_date: '20-02-2024',
    valid_upto: '20-12-2024',
    created_at: '1724740697300',
    // permission: 'p q r s',
    status: 'PENDING',
    // organization_name: 'ICS',
    // purpose: 'test',
    contact: '+91-XXXXX-XXXXX',
    type: 'New Brief',
  },
  {
    key: '2',
    name: 'Mike',
    email: 'a@b.com',
    request_date: '20-02-2024',
    valid_upto: '20-12-2024',
    created_at: '1724740697300',
    // permission: 'p q r s',
    status: 'APPROVED',
    // organization_name: 'ICS',
    // purpose: 'test',
    contact: '+91-XXXXX-XXXXX',
    type: 'Edit Request',
  },
  {
    key: '3',
    name: 'Mike',
    email: 'a@b.com',
    request_date: '20-02-2024',
    valid_upto: '20-12-2024',
    created_at: '1724740697300',
    // permission: 'p q r s',
    status: 'REJECTED',
    // organization_name: 'ICS',
    // purpose: 'test',
    contact: '+91-XXXXX-XXXXX',
    type: 'Stem Request',
  },
];

const moodTopic = ['All', 'Zomato', 'HDFC', 'Infosys'];

const clientList = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: 'Infosys',
  },
  {
    value: 2,
    label: 'Zomato',
  },
  {
    value: 3,
    label: 'HDFC',
  },
];

export default function ClientAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAccessRequestDetailsModal, setShowAccessRequestDetailsModal] =
    useState(false);
  const [selectedAccessRequest, setSelectedAccessRequest] = useState(false);

  const [showBriefRequestDetailsModal, setShowBriefRequestDetailsModal] =
    useState(false);
  const [selectedBriefRequest, setSelectedBriefRequest] = useState(
    briefRequestData[0]
  );

  const [showTrackReceivedDetailsModal, setShowTrackReceivedDetailsModal] =
    useState(false);
  const [selectedTrackReceived, setSelectedTrackReceived] = useState(false);

  const role = getCookie('roles');
  console.log(role, role?.includes('Client Admin'));

  useEffect(() => {
    console.log('from client admin');
    dispatch(getAccessRequestListAction());
  }, []);

  // getting object in response, need array
  const { data: dataSource, flag: arFlag } = useSelector(
    (state) => state.clientAdminAccessRequestData
  );

  // const { data: briefRequestData } = useSelector(
  //   (state) => state.clientAdminBriefRequestData
  // );

  const onChangeTab = (key) => {
    console.log(key);
    if (key == 2) {
      dispatch(getBriefRequestListAction());
    }
  };

  function formatDateFromTimestamp(timestamp) {
    // Convert timestamp to milliseconds
    const date = new Date(timestamp * 1);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Format date as dd-mm-yyyy
    return `${day}-${month}-${year}`;
  }

  // const dataSource = [
  //   {
  //     key: '1',
  //     name: 'Mike',
  //     email: 'a@b.com',
  //     request_date: '20-02-2024',
  //     valid_upto: '',
  //     created_at: '1724740697300',
  //     permission: 'p q r s',
  //     status: 'PENDING',
  //     organization_name: 'ICS',
  //     purpose: 'test',
  //   },
  //   {
  //     key: '2',
  //     name: 'John',
  //     email: 'a@b.com',
  //     request_date: '20-02-2024',
  //     created_at: '1724740697300',
  //     valid_upto: '08/24',
  //     permission: 'a b c d',
  //     status: 'APPROVED',
  //     organization_name: 'PB',
  //     purpose: 'test',
  //   },
  //   {
  //     key: '3',
  //     name: 'Robe',
  //     email: 'a@b.com',
  //     request_date: '20-02-2024',
  //     created_at: '1724740697300',
  //     valid_upto: '',
  //     permission: 'a b c d',
  //     status: 'REJECTED',
  //     organization_name: 'ICS',
  //     purpose: 'test',
  //   },
  // ];

  const accessRequestColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (name, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 350,
      render: (name, record, index) => {
        return (
          <>
            <span className="ar-name-data">{name}</span>
            <br />
            <span className="ar-name-email">
              {record.email ? record.email : ''}
            </span>
          </>
        );
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD-MM-YYYY'),
    },
    {
      title: 'Valid Upto',
      dataIndex: 'valid_upto',
      key: 'valid_upto',
      render: (valid_upto) => (valid_upto ? valid_upto : '_ _/_ _'),
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
      width: 400,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          {status == 'PENDING' ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewARDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
              <span
                className="ar-approved-edit-text"
                onClick={() => {
                  handleViewARDetails(event, record);
                }}
              >
                Edit
              </span>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const briefRequestColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (name, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Requester',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name, record, index) => {
        return (
          <>
            <span className="ar-name-data">{name}</span>
            <br />
            <span className="ar-name-email">
              {record.email ? record.email : ''}
            </span>
          </>
        );
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => formatDateFromTimestamp(created_at),
    },
    {
      title: 'Deadline',
      dataIndex: 'last_dedline',
      key: 'last_dedline',
      render: (last_dedline) => formatDateFromTimestamp(last_dedline),
    },
    {
      title: 'Contact Info.',
      dataIndex: 'contact',
      key: 'contact',
      render: (contact) => <span>{contact ? contact : '+91-XXXXX-XXXXX'}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          {status == 'PENDING' ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewBRDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const tracksReceivedColumns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (name, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Received From',
      dataIndex: 'received_from',
      key: 'received_from',
      width: 300,
      render: (received_from, record, index) => {
        return (
          <>
            <span className="ar-name-data">{received_from}</span>
          </>
        );
      },
    },
    {
      title: 'Received Date',
      dataIndex: 'received_date',
      key: 'received_date',
      render: (received_date) => formatDateFromTimestamp(received_date),
    },
    {
      title: 'Requested On',
      dataIndex: 'requested_on',
      key: 'requested_on',
      render: (requested_on) => formatDateFromTimestamp(requested_on),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          {status == 'PENDING' ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewTRDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == 'APPROVED' ? (
            <>
              <div className="ar-approved-text">Approved</div>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const tracksReceivedData = [
    {
      id: '11530791-37da-40ad-ad08-a9a0a36dbba4',
      user_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
      client_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
      purposes: 'Corporate',
      track_genre: 'jazz',
      stems: 'Yes',
      charecter: '["Agressive", "Calm"]',
      moods: '["Relaxation", "Serenity", "Annoyance"]',
      vocals: 'Male',
      syncs: 'Yes',
      licences: 'Global',
      add_licences: 'Canada',
      additional_notes: 'This is addoitional notes.',
      file_name: 'Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
      file_url:
        'https://storage.googleapis.com/staging-sonic-vault-audio/Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
      first_dedline: '1724740697300',
      last_dedline: '1724740697300',
      durations: '09:00',
      status: 'PENDING',
      type: 'Brief',
      created_at: '1724740697300',
      updated_at: '1724740697300',
      name: 'John',
      email: 'j@gmail.com',
      deadline: {
        first_cut: '1724740697300',
        final_delivery: '1724740697300',
      },

      received_date: '1724740697300',
      requested_on: '1724740697300',
    },
    {
      id: '11530791-37da-40ad-ad08-a9a0a36dbba4',
      user_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
      client_id: 'YXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2Iz',
      purposes: 'Corporate',
      track_genre: 'jazz',
      stems: 'Yes',
      charecter: '["Agressive", "Calm"]',
      moods: '["Relaxation", "Serenity", "Annoyance"]',
      vocals: 'Male',
      syncs: 'Yes',
      licences: 'Global',
      add_licences: 'Canada',
      additional_notes: 'This is addoitional notes.',
      file_name: 'Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
      file_url:
        'https://storage.googleapis.com/staging-sonic-vault-audio/Happy_Birthday_170b9a2d-6043-4a9d-ae7d-87c5ab879d8a.mp3',
      first_dedline: '04072024',
      last_dedline: '04072024',
      durations: '09:00',
      status: 'PENDING',
      type: 'Brief',
      created_at: '1724740697300',
      updated_at: '1724740697300',
      received_date: '1724740697300',
      requested_on: '1724740697300',
    },
  ];
  const items = [
    {
      key: '1',
      label: 'Access Request',
      children:
        arFlag &&
        Array.isArray(dataSource?.data) &&
        dataSource?.data?.length > 0 ? (
          <RequestTable
            columns={accessRequestColumns}
            dataSource={dataSource?.data}
          />
        ) : (
          <NoDataTable textToDisplay={'No Access Requests Available'} />
        ),
    },
    {
      key: '2',
      // For BMQ admin change the label to 'Client Brief Request'
      label: role?.includes('BMQ Admin')
        ? 'Client Brief Request'
        : 'Brief Request',
      children:
        Array.isArray(briefRequestData) && briefRequestData.length > 0 ? (
          <RequestTable
            columns={briefRequestColumns}
            dataSource={briefRequestData}
          />
        ) : (
          <NoDataTable textToDisplay={'No Requests Available'} />
        ),
    },
    {
      key: '3',
      label: 'Track Received',
      children: (
        <RequestTable
          columns={tracksReceivedColumns}
          dataSource={tracksReceivedData}
        />
      ),
    },
    // {
    //   key: '4',
    //   label: 'Reports',
    //   children: <ClientAdminReports />,
    // },
  ];

  const handleViewARDetails = (e, data) => {
    // console.log(e, data)
    setSelectedAccessRequest(data);
    setShowAccessRequestDetailsModal(true);
  };

  const handleViewBRDetails = (e, data) => {
    setSelectedBriefRequest(data);
    setShowBriefRequestDetailsModal(true);
  };

  const handleViewTRDetails = (e, data) => {
    console.log('selectedTrack:', data);
    setSelectedTrackReceived(data);
    setShowTrackReceivedDetailsModal(true);
  };

  const handleSelectedClientChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onChangeMoodTopic = (e) => {
    console.log(e);
  };

  const clientItems = [
    {
      key: 'Clients',
      label: 'Clients',
      children: (
        <div>
          {moodTopic?.map((item) => (
            <>
              <Checkbox
                className="collapse-checkbox"
                onChange={onChangeMoodTopic}
              >
                {item}
              </Checkbox>
            </>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="home-page">
      <Header showSearch={true} />

      <div className="client-admin-component">
        <div
          className="back-btn"
          style={{ position: 'absolute' }}
          onClick={() => navigate(-1)}
        >
          <MdOutlineKeyboardBackspace />
          Back
        </div>

        <div className="client-admin-tabs-section">
          <AccessRequestDetailsModal
            selectedAccessRequest={selectedAccessRequest}
            showAccessRequestDetailsModal={showAccessRequestDetailsModal}
            setShowAccessRequestDetailsModal={setShowAccessRequestDetailsModal}
          />

          <BriefRequestDetailsModal
            selectedBriefRequest={selectedBriefRequest}
            showBriefRequestDetailsModal={showBriefRequestDetailsModal}
            setShowBriefRequestDetailsModal={setShowBriefRequestDetailsModal}
            formatDateFromTimestamp={formatDateFromTimestamp}
          />

          <TracksReceivedDetailsModal
            selectedTrackReceived={selectedTrackReceived}
            showTrackReceivedDetailsModal={showTrackReceivedDetailsModal}
            setShowTrackReceivedDetailsModal={setShowTrackReceivedDetailsModal}
          />

          <Tabs
            className="client-admin-tabs"
            defaultActiveKey="1"
            items={items}
            onChange={onChangeTab}
          />
        </div>

        {role?.includes('BMQ Admin') ? (
          <div className="client-dropdown">
            <div className="collapse-wraper">
              <Collapse
                className="filter-collapse"
                defaultActiveKey={''}
                expandIconPosition={'end'}
                ghost
                accordion
                items={clientItems}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
