import { Table } from 'antd';
import React from 'react';

export default function RequestTable({ columns, dataSource }) {
  return (
    <div className="client-admin-request-table">
      <Table
        dataSource={dataSource}
        columns={columns}
        rowHoverable={false}
        pagination={false}
        scroll={{ y: '65vh', x: 'max-content' }}
      />
    </div>
  );
}
