import React, { useState } from 'react';
import { Button, Col, Divider, Modal, Row } from 'antd';

export default function BriefRequestDetailsModal({
  showBriefRequestDetailsModal,
  setShowBriefRequestDetailsModal,
  selectedBriefRequest,
  formatDateFromTimestamp,
}) {
  const [showBriefRejectionReason, setShowBriefRejectionReason] =
    useState(false);

  const brief_details_col1_span = 16;
  const brief_details_col2_span = 8;

  const handleBriefAccept = (e) => {
    console.log('brief Rejected', e);
  };

  const handleBriefReject = (e) => {
    console.log('Brief rejected', e);
    setShowBriefRejectionReason(true);
  };

  const handleSubmitBriefRejectionWithReason = (e) => {
    console.log('handleSubmitRejectionWithReason called: ');
    // after api call response close clear states, close modal
    setShowBriefRejectionReason(false);
    setShowBriefRequestDetailsModal(false);
    // setShowTrackSelection(true);
  };

  return selectedBriefRequest ? (
    <div className="brief-request-details-modal">
      <Modal
        wrapClassName="brief-request-details-modal-wrap"
        title={showBriefRejectionReason ? 'Rejection Reason' : 'New Brief'}
        centered
        open={showBriefRequestDetailsModal}
        onOk={() => setShowBriefRequestDetailsModal(false)}
        onCancel={() => {
          setShowBriefRequestDetailsModal(false);
          setShowBriefRejectionReason(false);
        }}
        width={700}
        footer={false}
      >
        <Divider />

        {!showBriefRejectionReason ? (
          <>
            <div className="brief-request-brief-details">
              <Row>
                <Col span={brief_details_col1_span}>Duration:</Col>
                <Col span={brief_details_col2_span}>
                  {selectedBriefRequest.durations}
                </Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Vocals Needed:</Col>
                <Col span={brief_details_col2_span}>
                  {selectedBriefRequest.vocals}
                </Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Sync Needed:</Col>
                <Col span={brief_details_col2_span}>
                  {selectedBriefRequest.syncs}
                </Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Licence:</Col>
                <Col span={brief_details_col2_span}>
                  {selectedBriefRequest.licences}
                </Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Additional Notes:</Col>
                <Col span={brief_details_col2_span}>
                  {selectedBriefRequest.additional_notes}
                </Col>
              </Row>
              {selectedBriefRequest.deadline ? (
                <>
                  <Row>
                    <Col span={brief_details_col1_span}>Deadline:</Col>
                    <Col span={brief_details_col2_span}></Col>
                  </Row>
                  <div className="deadline-details">
                    <Row>
                      <Col span={brief_details_col1_span}>First Cut:</Col>
                      <Col span={brief_details_col2_span}>
                        {formatDateFromTimestamp(
                          selectedBriefRequest.deadline.first_cut
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={brief_details_col1_span}>Final Delivery:</Col>
                      <Col span={brief_details_col2_span}>
                        {formatDateFromTimestamp(
                          selectedBriefRequest.deadline.final_delivery
                        )}
                      </Col>
                    </Row>
                  </div>
                </>
              ) : null}
            </div>
            <Divider />
            <Row className="brief-modal-buttons">
              <Col span={2}></Col>
              <Col span={10}>
                <Button
                  onClick={handleBriefAccept}
                  className="bm-btn-approve"
                  size="large"
                >
                  Accept
                </Button>
              </Col>
              <Col className="bm-btn-reject" span={10}>
                <Button onClick={handleBriefReject} size="large">
                  Reject
                </Button>
              </Col>
              <Col span={2}></Col>
            </Row>
          </>
        ) : (
          <div className="rejection-reason-wrapper">
            {/* Text Area - Done*/}
            <div className="other-reason-wrapper">
              <label for="other-reason" className="other-reason-label">
                Others(Please Specify)
              </label>
              <br />
              <textarea
                className="other-reason-textarea"
                name="other-reason"
                rows="5"
                cols="46"
                placeholder="Text inputs..."
              ></textarea>
            </div>

            {/* Submit button */}
            <div className="rejection-reason-btn-wrapper">
              <Row className="tracks-received-modal-buttons">
                <Col span={4}>
                  <Button
                    onClick={handleSubmitBriefRejectionWithReason}
                    className="trm-btn-approve"
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}

        <div></div>
      </Modal>
    </div>
  ) : null;
}
