import React from 'react'

export default function NoDataTable({textToDisplay}) {
  return (
    <div className='no-data-table'>
        <div className='display-text'>
            {textToDisplay ? textToDisplay : "No Data Found"}
        </div>
    </div>
  )
}
