/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Steps,
    Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import SvgLoader from "../../common/SvgLoader";
import { useDispatch, useSelector } from "react-redux";
import { getCharactersListAction, getGenresListAction, getMoodsListAction, getThemesListAction } from "../topGenreDetail/logic";
import axios from "axios";
import { getCookie } from "../../common/utils";

const RequestNewTrack = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [stage, setStage] = useState(0);
    const [stepForm] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [seconds, setSeconds] = useState(0);


    useEffect(() => {
        dispatch(getGenresListAction({}));
        dispatch(getThemesListAction({}));
        dispatch(getMoodsListAction({}));
        dispatch(getCharactersListAction({}));

    }, []);


    const { data: genresList } = useSelector((state) => state?.genresList);
    // const { data: themesList } = useSelector((state) => state?.themesList);
    const { data: moodsList } = useSelector((state) => state?.moodsList)
    const { data: charactersList } = useSelector((state) => state?.charactersList)


    const [genresListOptions, setGenresListOptions] = useState();
    // const [themesListOptions, setThemesListOptions] = useState();
    const [moodsListOptions, setMoodsListOptions] = useState();
    const [characterListOptions, setCharacterListOptions] = useState();
    const [firstData, setFirstData] = useState();
    const [finalData, setFinalData] = useState();


    useEffect(() => {

        const genresMoods = genresList?.response?.genres?.map((genres) => ({
            label: genres.name,
            value: genres.id
        }));

        setGenresListOptions(genresMoods)

        const optionsMoods = moodsList?.response?.moods?.map((mood) => ({
            label: mood.name,
            value: mood.id
        }));
        setMoodsListOptions(optionsMoods)

        const charactersMoods = charactersList?.response?.characters?.map((characters) => ({
            label: characters.name,
            value: characters.id
        }));
        setCharacterListOptions(charactersMoods)

    }, [moodsList, genresList, charactersList])

    const onFinish = (values) => {

        stepForm
            .validateFields()
            .then(() => {
                setFormData((prev) => ({ ...prev, ...values }));



                axios
                    .post(
                        `${process.env.apiUrl}requestTrack`,
                        {


                            formData
                        },
                        {
                            headers: {
                                Authorization: getCookie("accessToken"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log({ response });
                        navigate("/home")

                    })
                    .catch((err) => {
                        console.log(err?.response?.statusText);

                    });










            })
            .catch((errorInfo) => {
                console.log("Failed:", errorInfo);
            });

        console.log({ formData });
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const purpuseList = [
        "Corporate",
        "Events",
        "Social Media",
        "Shorts and Reels",
        "Advertisements",
        "Podcasts",
        "Explainer Videos",
        "Apps",
        "Tutorials",
    ];
    const onChangePurpuse = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };



    const onChangeDuration = (value) => {
        setSeconds(value);
        console.log("Selected duration in seconds:", value);
    };

    // const genreList = [
    //     "Pop",
    //     "Rock",
    //     "Indian Classical",
    //     "Western Classical",
    //     "Jazz",
    //     "Hip Hop",
    //     "Indi Pop",
    //     "Reggaton",
    // ];
    const onChangeGenre = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };

    // const charactorList = [
    //     "Compassionate",
    //     "Heroic",
    //     "Aggressive",
    //     "Romantic",
    //     "Anxious",
    //     "Calm",
    //     "Joyous",
    //     "Dark",
    //     "Surprising",
    // ];
    const onChangeCharactor = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };

    const onChangeStems = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };

    const onChangeVocals = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };

    const onChangeSync = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };

    const onChangeLicence = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };

    // const moodsList = [
    //     "Harmony",
    //     "Stillness",
    //     "Relaxation",
    //     "Contentment",
    //     "Satisfaction",
    //     "Serenity",
    //     "Calmness",
    //     "Frustration",
    //     "Irritation",
    //     "Annoyance",
    //     "Agitation",
    //     "Stress",
    //     "Hostility",
    //     "Hate",
    // ];
    const onChangeMoods = (checkedValues) => {
        console.log("checked = ", checkedValues);
    };


    const handleNext = () => {
        stepForm
            .validateFields()
            .then((values) => {
                setFormData((prev) => ({ ...prev, ...values }));
                setStage(stage + 1);
            })
            .catch((errorInfo) => {
                console.log("Failed:", errorInfo);
            });
    };

    useEffect(() => {
        stepForm.setFieldsValue(formData);
    }, [formData, stage, stepForm]);
    const Step1Form = () => {
        return (
            <>
                <Row className="step-1">
                    <Col span={8}>
                        <div className="heding">Select Purpose of use:</div>
                        <div className="checkbox-wrap">
                            <Form.Item label="" name="purpuse">
                                <Checkbox.Group
                                    options={purpuseList}
                                    onChange={onChangePurpuse}
                                />
                            </Form.Item>
                        </div>
                        <div className="heding">Select Duration:</div>

                        <Form.Item label="" name="duration">
                            {/* <TimePicker
                                onChange={onChangeDuration}
                                popupStyle={{ fontSize: 20 }}
                                className="custom-timepicker"
                                format="ss" // Show only seconds
                                showHour={false} // Hide the hour column
                                showMinute={false}
                                defaultOpenValue={moment("00", "ss")}
                            /> */}

                            <Input

                                min={0}
                                max={3600}
                                // value={seconds}
                                onChange={(e) => onChangeDuration(e)}
                                style={{ width: "200px" }}
                                placeholder="Time: 00 Sec"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <img src="/images/partition-line.png" alt="" />
                    </Col>
                    <Col span={7}>
                        <div className="heding">Select Genre:</div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="genre">
                                <Checkbox.Group options={genresListOptions} onChange={onChangeGenre} />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={1}>
                        <img src="/images/partition-line.png" alt="" />
                    </Col>
                    <Col span={7}>
                        <div className="heding">Select Character:</div>
                        <div className="note">(Prioritise any two)</div>
                        <div className="checkbox-wrap">
                            <Form.Item label="" name="charactor">
                                <Checkbox.Group
                                    options={characterListOptions}
                                    onChange={onChangeCharactor}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </>
        );
    };

    const Step2Form = () => {
        return (
            <>
                <Row className="step-1">
                    <Col span={8}>
                        <div className="heding">Select Moods:</div>
                        <div className="note">(Select Any 3)</div>
                        <div className="checkbox-wrap">
                            <Form.Item label="" name="moods">
                                <Checkbox.Group options={moodsListOptions} onChange={onChangeMoods} />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={1}>
                        <img src="/images/partition-line.png" alt="" />
                    </Col>
                    <Col span={7}>
                        <div className="heding">Stems Needed:</div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="stems">
                                <Checkbox.Group
                                    options={["Yes", "No"]}
                                    /* value={gender} */
                                    onChange={onChangeStems}
                                />
                            </Form.Item>
                        </div>

                        <div className="heding">Vocals Needed:</div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="vocals">
                                <Checkbox.Group
                                    options={["Male", "Female"]}
                                    onChange={onChangeVocals}
                                />
                            </Form.Item>
                        </div>

                        <div className="heding">Sync Needed:</div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="syncs">
                                <Checkbox.Group
                                    options={["Yes", "No"]}
                                    onChange={onChangeSync}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={1}>
                        <img src="/images/partition-line.png" alt="" />
                    </Col>
                    <Col span={7}>

                        <div className="heding">Edits Needed:</div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="edits">
                                <Checkbox.Group
                                    options={["Yes", "No"]}
                                    /* value={gender} */
                                    onChange={onChangeStems}
                                />
                            </Form.Item>
                        </div>

                        <div className="heding">Licence:DEFAULT:</div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="licence">
                                <Checkbox.Group
                                    options={["Global", "Digital"]}
                                    onChange={onChangeLicence}
                                />
                            </Form.Item>
                        </div>

                        {/* <div className="heding" style={{ marginLeft: 0 }}>
                            Add Licences:<span>(Mass Media - OTT/TV/Cinema)</span>
                        </div> */}

                        {/* <div className="checkbox-wrap">
                            <Form.Item label="" name="addLicence">
                                <Select
                                    showSearch
                                    // style={{
                                    //     width: 200,
                                    // }}
                                    placeholder="Select"
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? "").toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: "United States",
                                            label: "United States",
                                        },
                                        {
                                            value: "United Kingdom",
                                            label: "United Kingdom",
                                        },
                                        {
                                            value: "India",
                                            label: "India",
                                        },
                                        {
                                            value: "Canada",
                                            label: "Canada",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div> */}
                        <div className="heding" style={{ marginLeft: 0 }}>
                            Additional Notes:
                        </div>

                        <div className="checkbox-wrap">
                            <Form.Item label="" name="additional_notes">
                                <TextArea rows={4} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </>
        );
    };

    const Step3Form = () => {
        return (
            <>
                <Row className="step-1" style={{ paddingRight: "10%" }}>
                    <Col span={12} style={{ marginTop: "5%" }}>
                        <div className="heding">Add URL of reference tracks if any:</div>
                        <div className="checkbox-wrap" style={{ height: "60%" }}>
                            <Form.Item label="" name="upload">
                                {/* <Upload>
                                    <Button icon={<UploadOutlined />}>
                                        Upload Reference Track
                                    </Button>
                                </Upload> */}
                                <Input placeholder="Add URL here...." />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={2}>
                        <img src="/images/partition-line.png" alt="" />
                    </Col>
                    <Col span={10} style={{ marginTop: "5%" }}>
                        <div className="heding">Deadlines:</div>

                        <div className="checkbox-wrap" style={{
                            display: "flex", alignItems: "center"
                        }}>
                            <div className="note" style={{ width: "30%" }}>First Cut</div>

                            <Form.Item label="" name="deadline_first">
                                <DatePicker onChange={(e) => setFirstData(e)} />
                            </Form.Item>
                        </div>
                        <div className="checkbox-wrap" style={{
                            display: "flex", alignItems: "center"
                        }}>
                            <div className="note" style={{ width: "30%" }}>Final Delivery</div>

                            <Form.Item label="" name="deadline_final">
                                <DatePicker onChange={(e) => setFinalData(e)} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </>
        );
    };


    const formSteps = [
        {
            title: "",
            content: <Step1Form />,
        },
        {
            title: "",
            content: <Step2Form />,
        },
        {
            title: "",
            content: <Step3Form />,
        },

    ];


    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="request-page-wraper">
                <div className="top-heading">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="heading">Request New Track</div>
                </div>
                <div className="request-card-wrap">
                    <div className="steps-wrap">
                        <Steps current={stage}>
                            {formSteps?.map((item, idx) => (
                                <Steps.Step key={idx} title={item.title} />
                            ))}
                        </Steps>
                    </div>

                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        id="test"
                        // onSubmit={onFinish}
                        form={stepForm}
                    >
                        <div className="steps-content">{formSteps[stage]?.content}</div>

                        {stage < 3 && (
                            <>
                                <div className="disclaimer">
                                    Disclaimer: Select the fields according to the preferences of
                                    your music choice.*
                                </div>
                                <div className="btn-wraper">
                                    <Button className="form-btn1" onClick={() => navigate("/home")}>
                                        Cancel
                                    </Button>
                                    {stage === 2 ? (
                                        <Button
                                            className="form-btn2"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button
                                            className="form-btn2"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}

                        {
                            stage >= 3 && (
                                <Row className="step-1" style={{ display: "flex", justifyContent: "center", marginTop: "12%" }}>

                                    <div className="submit-page-wrpaer">
                                        <img src="" alt="" />
                                        <SvgLoader svgName="done" height={100} width={100} />
                                        <div className="heding1" >Thank you for your request for a New Track</div>
                                        <div className="note1" >On approval, Your request will be Sent to BMQ Admin </div>
                                        <div className="hiperlink" onClick={() => navigate("/home")}>Home</div>
                                    </div>

                                </Row>
                            )
                        }
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default RequestNewTrack;
