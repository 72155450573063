import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getCookie } from '../../common/utils';
import { useDispatch } from 'react-redux';
import { getBriefRequestListAction } from '../../pages/ClientAdmin/logic';
import moment from 'moment';

export default function BriefRequestDetailsModal({
  showBriefRequestDetailsModal,
  setShowBriefRequestDetailsModal,
  selectedBriefRequest,
  formatDateFromTimestamp, title,
  role,
  selectedClients,
}) {
  const dispatch = useDispatch();

  const [showBriefRejectionReason, setShowBriefRejectionReason] =
    useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [analyzedData, setAnalyzedData] = useState(false);
  const brief_details_col1_span = 16;
  const brief_details_col2_span = 8;

  useEffect(() => {
    if (selectedBriefRequest?.analyse_data) {
      // console.log(JSON.parse(selectedBriefRequest.analyse_data));
      // setAnalyzedData(JSON.parse(selectedBriefRequest.analyse_data));
      setAnalyzedData(selectedBriefRequest.analyse_data);
    }
  }, [selectedBriefRequest]);

  const changeBriefRequestStatus = async (type) => {
    try {
      let url = '';
      if (role.includes('BMQ Admin')) {
        url = 'briefRequestBMQAdmin';
      } else if (role.includes('Client Admin')) {
        url = 'briefRequestClientAdmin';
      }

      let status = '';
      if (type == 'Approve') {
        status = 'APPROVED';
      } else if (type == 'Reject') {
        status = 'REJECTED';
      }

      let reject_reason_param = '';
      if (status == 'REJECTED') {
        reject_reason_param = `&reject_reason=${rejectionReason}`;
      }

      const response = await axios.put(
        `${process.env.apiUrl}admin/${url}?status=${status}&brief_id=${selectedBriefRequest.id}${reject_reason_param}`,
        [],
        {
          headers: {
            Authorization: getCookie('accessToken'),
            accept: 'application/json',
          },
        }
      );

      if (response.status === 200 || response.data.data) {
        console.log('Status updated Successfully', response.data);
        toast.success(
          response?.data?.message || 'Status Updated Successfully',
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

        if (role?.includes('BMQ Admin')) {
          console.log(selectedClients);
          dispatch(
            getBriefRequestListAction(
              [],
              `briefRequestBMQAdmin?client_id=${selectedClients}`
            )
          );
        } else {
          dispatch(getBriefRequestListAction());
        }
        setShowBriefRequestDetailsModal(false);
      }
    } catch (err) {
      console.log('Error in updating BR Status', err);
      toast.error(
        err.response?.data?.message
          ? err.response.data.message
          : 'Error in updating Status',
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleBriefAccept = (e) => {
    changeBriefRequestStatus('Approve');
  };

  const handleBriefReject = (e) => {
    console.log('Brief rejected', e);
    setShowBriefRejectionReason(true);
  };

  const handleSubmitBriefRejectionWithReason = (e) => {
    console.log('handleSubmitRejectionWithReason called: ');
    // after api call response close clear states, close modal
    changeBriefRequestStatus('Reject');
    setShowBriefRejectionReason(false);
    setShowBriefRequestDetailsModal(false);
    // setShowTrackSelection(true);
  };

  function formatSecondsToHHMMSS(seconds) {
    const duration = moment.duration(seconds, 'seconds');

    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const secs = String(duration.seconds()).padStart(2, '0');

    return `${hours}:${minutes}:${secs}`;
  }

  return selectedBriefRequest ? (
    <div className="brief-request-details-modal">
      <Modal
        wrapClassName="brief-request-details-modal-wrap"
        title={title ? title : showBriefRejectionReason ? 'Rejection Reason' : 'New Brief'}
        centered
        open={showBriefRequestDetailsModal}
        onOk={() => setShowBriefRequestDetailsModal(false)}
        onCancel={() => {
          setShowBriefRequestDetailsModal(false);
          setShowBriefRejectionReason(false);
        }}
        width={700}
        footer={false}
      >
        <Divider />

        {!showBriefRejectionReason && analyzedData ? (
          <>
            <div className="brief-request-brief-details">
              <Row>
                <Col span={brief_details_col1_span}>Duration:</Col>
                <Col span={brief_details_col2_span}>
                  {formatSecondsToHHMMSS(analyzedData?.duration ?? 0)}
                </Col>
              </Row>
              {/* <Row>
                <Col span={brief_details_col1_span}>Vocals Needed:</Col>
                <Col span={brief_details_col2_span}>
                  {JSON.parse(selectedBriefRequest?.analyse_data?.vocals ?? []).join(
                    ', '
                  )}
                </Col>
              </Row> */}
              <Row>
                <Col span={brief_details_col1_span}>Sync Needed:</Col>
                <Col span={brief_details_col2_span}>{analyzedData.syncs}</Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Licence:</Col>
                <Col span={brief_details_col2_span}>
                  {analyzedData.licences}
                </Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Additional Notes:</Col>
                <Col span={brief_details_col2_span}>
                  {analyzedData.additional_notes}
                </Col>
              </Row>
              <Row>
                <Col span={brief_details_col1_span}>Deadline:</Col>
                <Col span={brief_details_col2_span}></Col>
              </Row>
              <div className="deadline-details">
                <Row>
                  <Col span={brief_details_col1_span}>First Cut:</Col>
                  <Col span={brief_details_col2_span}>
                    {moment(analyzedData.deadline_first).format('Do MMMM YYYY')}
                  </Col>
                </Row>
                <Row>
                  <Col span={brief_details_col1_span}>Final Delivery:</Col>
                  <Col span={brief_details_col2_span}>
                    {moment(analyzedData.deadline_final).format('Do MMMM YYYY')}
                  </Col>
                </Row>
              </div>
            </div>
            <Divider />
            <Row className="brief-modal-buttons">
              <Col span={2}></Col>
              {role.includes('BMQ User') ? (
                <>
                  <Col span={5}></Col>
                  <Col span={10}>
                    <Button
                      onClick={() => {
                        setShowBriefRequestDetailsModal(false);
                      }}
                      className="bm-btn-approve"
                      size="large"
                    >
                      Back
                    </Button>
                  </Col>
                  <Col span={5}></Col>
                </>
              ) : (
                <>
                  <Col span={10}>
                    <Button
                      onClick={handleBriefAccept}
                      className="bm-btn-approve"
                      size="large"
                    >
                      Accept
                    </Button>
                  </Col>
                  <Col className="bm-btn-reject" span={10}>
                    <Button onClick={handleBriefReject} size="large">
                      Reject
                    </Button>
                  </Col>
                </>
              )}

              <Col span={2}></Col>
            </Row>
          </>
        ) : (
          <div className="rejection-reason-wrapper">
            {/* Text Area - Done*/}
            <div className="other-reason-wrapper">
              <label for="other-reason" className="other-reason-label">
                Others(Please Specify)
              </label>
              <br />
              <textarea
                className="other-reason-textarea"
                name="other-reason"
                rows="5"
                cols="46"
                placeholder="Text inputs..."
                value={rejectionReason}
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                }}
              ></textarea>
            </div>

            {/* Submit button */}
            <div className="rejection-reason-btn-wrapper">
              <Row className="tracks-received-modal-buttons">
                <Col span={4}>
                  <Button
                    onClick={handleSubmitBriefRejectionWithReason}
                    className="trm-btn-approve"
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}

        <div></div>
      </Modal>
    </div>
  ) : null;
}
