import React from "react";
import SvgLoader from "../../common/SvgLoader";

const SquareCard = ({ name, count, icon, index, imageWidth, imageHeight }) => {
  return (
    <div className="card-square" key={index}>
      <SvgLoader
        svgName={`genre-${icon}-icon` || "genre-default"}
        height={imageHeight}
        width={imageWidth}
        className="svg-image"
      />
      <div className="text-country">{name}</div>
      <div className="text-songs-count">{`${count} Songs`}</div>
    </div>
  );
};

export default SquareCard;
