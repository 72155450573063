/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Header from "../../components/Header";
import SvgLoader from "../../common/SvgLoader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setCookie } from "../../common/utils";
import { toast } from "react-toastify";

function LoginPage() {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState("login");

  const getToken = async (data) => {
    try {
      axios
        .post(`${process.env.apiUrl}login`, data)
        .then((response) => {
          const bearer = `${response?.data?.access_token}`;

          setCookie(JSON.stringify(response?.data?.roles), "roles");
          setCookie(
            JSON.stringify(response?.data?.permissions),
            "permissions"
          );
          setCookie(bearer, "accessToken");
          if (response?.data?.profile) {

            setCookie(response?.data?.profile[0]?.username, "user");
          }
          navigate("/home");
        })
        .catch((err) => {
          toast.error(err?.data?.error || "Something Went Wrong", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    } catch (err) {
      console.log(err);

    }
  };

  const handleLogin = (e) => {
    getToken(e);
  };

  const handleResetPassword = (data) => {

    axios
      .post(`${process.env.apiUrl}forgetPassword`, data)
      .then((response) => {
        toast.success(response?.data?.message || "New Password is send to your Email", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          setShowContent("login");
        }, [2000]);
      })
      .catch((err) => {
        toast.error(err?.data?.error || "Something Went Wrong", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // handleLogout()
      });


  };

  return (
    // <div className="login-page">
    //   <Header showSearch={false} />

    //   <div className="login-section">
    //     <div className="title-1">Sync into Symphony</div>
    //     <div className="title-2">
    //       Sign up to Explore&apos;s Soniq Vault&apos;s Music
    //     </div>
    //     {showContent === "login" && (
    //       <div className="login-content">
    //         <div className="title-3">Login</div>
    //         <div>Please fill your information below</div>
    //         {/* <Input
    //           className="input-box"
    //           placeholder="Login Name"
    //           prefix={<SvgLoader svgName="Profile" height={20} width={20} />}
    //         />
    //         <Input
    //           className="input-box"
    //           placeholder="Password"
    //           prefix={
    //             <SvgLoader svgName="password-icon" width={20} height={20} />
    //           }
    //         /> */}
    //         {/* <div className="login-forgot">Forgot Password?</div> */}
    //         {/* <Button
    //           className="login-btn"
    //         // onClick={() => setShowContent("reset-password")}
    //         // onClick={() => loginWithRedirect()}
    //         >
    //           Login
    //           <RightOutlined />
    //         </Button> */}
    //         <div id="container" style={{ width: "60%" }}></div>
    //       </div>
    //     )}

    //     {showContent === "reset-password" && (
    //       <div className="login-content">
    //         <div className="title-3">Reset New Password</div>
    //         <div>Please fill your information below</div>
    //         <Input
    //           className="input-box"
    //           placeholder="Old Password"
    //           prefix={<SvgLoader svgName="Profile" height={20} width={20} />}
    //         />
    //         <Input
    //           className="input-box"
    //           placeholder="New Password"
    //           prefix={
    //             <SvgLoader svgName="password-icon" width={20} height={20} />
    //           }
    //         />
    //         <Button className="login-btn" onClick={() => navigate("/home")}>
    //           Reset Password
    //           <RightOutlined />
    //         </Button>
    //       </div>
    //     )}
    //   </div>
    // </div>

    <div className="login-page">
      <Header />
      <div className="login-section">
        <div className="title-1">Sync into Symphony</div>
        <div className="title-2">
          Sign up to Explore&apos;s Soniq Vault&apos;s Music
        </div>{" "}
        {showContent === "login" && (
          <>
            <div className="login-content">
              <Form name="basic" className="login-form-wrap" onFinish={handleLogin}>

                <div className="title-3">Login</div>
                <div style={{ color: "#fff" }}>
                  Please fill your information below
                </div>
                <Form.Item
                  label=""
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input
                    className="input-box"
                    placeholder="Email ID"
                    type="email"
                    prefix={
                      <SvgLoader svgName="Profile" height={20} width={20} />
                    }
                  />
                </Form.Item>
                <Form.Item label="" name="password">
                  <Input
                    className="input-box"
                    placeholder="Password"
                    type="password"
                    prefix={
                      <SvgLoader svgName="password-icon" width={20} height={20} />
                    }
                  />
                </Form.Item>
                <div
                  className="login-forgot"
                  style={{ cursor: "pointer", justifyContent: "flex-end" }}
                  onClick={() => setShowContent("reset-password")}
                >
                  Forgot Password?
                </div>
                <Button className="login-btn" htmlType="submit">
                  Login
                  <RightOutlined />
                </Button>


              </Form>
              <div
                className="login-forgot"
                style={{ justifyContent: "center", marginTop: "2%" }}
              >
                New to Soniq Vault{" "}
                <span
                  style={{ color: "#FFCD00", cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  {" "}
                  &nbsp; Signup
                </span>
              </div>
            </div>

          </>
        )}
        {showContent === "reset-password" && (
          <div className="login-content">
            <div className="title-3">Forgot Password</div>
            <div>Please fill your information below</div>
            <Form name="basic" onFinish={handleResetPassword}>
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  className="input-box"
                  placeholder="Email ID"
                  type="email"
                  prefix={
                    <SvgLoader svgName="Profile" height={20} width={20} />
                  }
                />
              </Form.Item>
              <div className="reset-pass-btn-wrap">
                <Button className="login-btn" htmlType="submit">
                  Reset Password
                  <RightOutlined />
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
