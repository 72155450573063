import {
  map, catchError, switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import apiCall from "../../common/utils";

const FETCH_IMAGES = "FETCH_IMAGES";
const IMAGES_SUCCESS = "IMAGES_SUCCESS";
const FETCH_IMAGES_FAILUR = "FETCH_IMAGES_FAILUR";

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
};

export const getImagesAction = (payload) => ({
  type: FETCH_IMAGES,
  payload,
});

const getImagesSuccess = (payload) => ({
  type: IMAGES_SUCCESS,
  payload,
});

// eslint-disable-next-line no-unused-vars
const searchImages = (action) => {
  axios.get("https://api.unsplash.com/search/photos", {
    headers: {
      Authorization: "Client-ID 8O50V7bNzfKdVixwS9W9nZVdr0VnrCv9gmeimfdvp6Y",
    },
    params: {
      query: action.params.query,
    },
  })
    .then((response) => getImagesSuccess(response.data.results))
    .catch((error) => error);
};

export const getImagesEpic = (actions$) => actions$.pipe(
  ofType(FETCH_IMAGES),
  switchMap((action) => ajax(apiCall(`${action.payload.appUrl}?client_id=${action.payload.headers.Authorization}&query=${action.payload.params.query}`, "GET")).pipe(
    map((res) => getImagesSuccess(res)),
    catchError((err) => of({
      type: FETCH_IMAGES_FAILUR,
      payload: err,
      showNotification: true,
    })),
  )),
);

export const getImagesReducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
  case FETCH_IMAGES:
    return {
      ...state,
      data: [],
      loading: true,
      error: false,
    };
  case IMAGES_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: false,
    };
  case FETCH_IMAGES_FAILUR:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: true,
    };
  default:
    return state;
  }
};
