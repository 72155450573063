/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Header from "../../components/Header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Row, Upload } from "antd";

import SvgLoader from "../../common/SvgLoader";
import { UploadOutlined } from "@ant-design/icons";

const UploadTrack = () => {
    const navigate = useNavigate();
    const [stepForm] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [uploadSucess, setUploadSucess] = useState(false);

    const onFinish = (values) => {
        console.log("values", values)
        stepForm
            .validateFields()
            .then(() => {
                setFormData((prev) => ({ ...prev, ...values }));
                setUploadSucess(true);
            })
            .catch((errorInfo) => {
                console.log("Failed:", errorInfo);
            });

        console.log({ formData });
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleValueChange = (v) => {
        console.log(v)
    }

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="request-page-wraper">
                <div className="top-heading">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="heading">Upload Track</div>
                </div>
                <div className="request-card-wrap">
                    {!uploadSucess ? (
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            // onSubmit={onFinish}
                            form={stepForm}
                            onValuesChange={handleValueChange}
                        >
                            <div className="step-1">

                                <div className="checkbox-wrap-upload" >
                                    <Form.Item label="" name="upload">
                                        <Upload accept=".mp3">

                                            <Button icon={< UploadOutlined style={{ color: "#fff" }} />}>
                                                <div>
                                                    <span style={{ color: "#fff" }}>Click Here to Browse</span> or Drop your file here
                                                    <br />
                                                    File Supported (mp3,WAV, FLAC & Zip)
                                                </div>
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className="title-wrapper">
                                    <Form.Item label="Track Title" name="track-title">
                                        <Input placeholder="Track Title" />
                                    </Form.Item>
                                </div>
                                </div>
                               
                            <div className="btn-wraper-upload">
                                <Button className="form-btn" onClick={() => navigate("/home")}>Cancel</Button>

                                <Button className="form-btn" type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    ) : (
                        <Row
                            className="step-1"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "12%",
                            }}
                        >
                            <div className="submit-page-wrpaer">
                                <img src="" alt="" />
                                <SvgLoader svgName="done" height={100} width={100} />
                                <div className="heding1">
                                    Thank you for your request for a New Track
                                </div>
                                <div className="note1">
                                    On approval, Your request will be Sent to BMQ Admin{" "}
                                </div>
                                <div className="hiperlink" onClick={() => navigate("/home")}>
                                    Home
                                </div>
                            </div>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadTrack;
