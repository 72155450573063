/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { Button, Card, Divider, Form, Input, Modal, Popover } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../common/utils";
import { toast } from "react-toastify";
import SvgLoader from "../../common/SvgLoader";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { HiPause, HiPlay } from "react-icons/hi";
import WavesurferPlayer from "@wavesurfer/react";
import { IoClose } from "react-icons/io5";
import { MdOutlineInsertComment } from "react-icons/md";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { LuLink } from "react-icons/lu";
import { storeTrackIdAction } from "../../pages/explorePage/logic";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlayListAction } from "../../pages/MyGallery/logic";

const TrackCard = ({
    getPlayListData,
    trackListData,
    resetList,
    setResetList,
}) => {
    console.log(resetList);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [openIdx, setOpenIdx] = useState(false);
    const [comment, setComment] = useState("");
    const [commentsList, setCommentsList] = useState("");
    const [clickedTrack, setClickedTrack] = useState("");
    const [trackAddedPlaylist, setTrackAddedPlaylist] = useState();
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
    const [showPlaylistFlag, setShowPlaylistFlag] = useState(true);
    const [currentSongIndex, setCurrentSongIndex] = useState();
    const [wavesurfer, setWavesurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioBlob, setAudioBlob] = useState();

    const blankAudio = "/music/silent_quarter-second.mp3";

    const { data: storeTrackId } = useSelector((state) => state?.storeTrackId);



    useEffect(() => {
        storeTrackId &&
            axios
                .get(
                    `${process.env.apiUrl}playTrack?track_id=${storeTrackId?.track_id}`,
                    {
                        headers: {
                            Authorization: getCookie("accessToken"),
                        },
                        responseType: "arraybuffer",
                    }
                )
                .then((response) => {
                    setIsPlaying(true);
                    const blob = new Blob([response.data]);

                    setAudioBlob(URL.createObjectURL(blob));
                })
                .catch((err) => {
                    console.log(err?.response?.statusText);
                });
    }, [storeTrackId]);

    useEffect(() => {
        dispatch(getPlayListAction({}));
    }, [showPlaylistFlag, clickedTrack, trackAddedPlaylist]);


    const handlePlayCurrent = (song, idx) => {
        // wavesurfer && wavesurfer.playPause();
        setCurrentSongIndex(idx);
        const data = {
            track_id: song?.id,
            title: song?.title,
            duration: song?.duration,
            genre_icon: song?.genre_icon,
            is_favourite: song?.is_favourite,
            notes: song?.notes,
        };

        dispatch(storeTrackIdAction(data));
        setIsPlaying(true);
    };

    const onPlayPause = () => {
        wavesurfer && wavesurfer.playPause();
        // setCurrentSongIndex(null);
    };
    const [currentTime, setCurrentTime] = useState(0);
    // const [duration, setDuration] = useState(0);

    const onReady = (ws) => {
        setWavesurfer(ws);
        setIsPlaying(true);
        // setDuration(ws.getDuration());
        ws.on("audioprocess", () => {
            setCurrentTime(ws.getCurrentTime());
        });
    };

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        const formattedHours = h > 0 ? `${h}:` : "";
        const formattedMinutes = m < 10 ? `0${m}` : m;
        const formattedSeconds = s < 10 ? `0${s}` : s;

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    };

    const showAddPlaylistModal = (song) => {
        setClickedTrack(song);
        setIsAddPlaylistModalOpen(true);

        axios
            .get(`${process.env.apiUrl}trackInPlaylist?track_id=${song?.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setTrackAddedPlaylist(response?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        setShowPlaylistFlag(true);
        setIsAddPlaylistModalOpen(false);
    };

    const hide = () => {
        setOpen(false);
        setComment("");
    };
    const handleOpenChange = (song, idx) => {
        setOpen(true);
        setOpenIdx(idx);

        axios
            .get(`${process.env.apiUrl}note?track_id=${song?.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setCommentsList(response?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleComment = (song) => {
        const data = {
            track_id: song?.id,
            note_text: comment,
        };

        axios
            .post(`${process.env.apiUrl}note`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setComment("");
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                hide();
            })
            .catch((err) => {
                console.log(err);
                hide();
                toast.error(err?.response?.request?.statusText || "Error", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });

        // hide();
    };

    const handleFavorite = (song) => {
        const data = {
            track_id: song?.id,
            action: !song?.is_liked,
        };

        axios
            .post(`${process.env.apiUrl}like`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setResetList((prev) => !prev);
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "Error", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    const addTrackToPlaylist = (song, addRemove) => {
        const data = {
            track_id: clickedTrack?.id,
            playlist_id: song?.id,
            is_add: addRemove,
        };
        axios
            .post(`${process.env.apiUrl}addToPlaylist`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setShowPlaylistFlag(true);
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                handleCancel();
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "Error", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    const createNewPlaylist = (value) => {
        const data = {
            playlist_name: value?.playlistName,
        };
        axios
            .post(`${process.env.apiUrl}CreatePlaylist`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setShowPlaylistFlag(true);
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "Error", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    return (
        <>
            <Modal
                centered
                open={isAddPlaylistModalOpen}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="plylist-title">Add to Playlist</div>
                <Divider />
                {showPlaylistFlag ? (
                    <div className="outer-wraper">
                        {getPlayListData && getPlayListData?.playlist?.length !== 0 ? (
                            <div
                                className="outer-wraper"
                                style={{ maxHeight: "40vh", overflow: "auto" }}
                            >
                                {getPlayListData?.playlist?.map((item, idx) => (
                                    <div key={idx} className="collection-card-wrap">
                                        <div
                                            className="collection-card"
                                        // onClick={() =     playlist_name: value?.playlistName,> handleCollection(item)}
                                        >
                                            <div className="title">{item?.playlist_name}</div>
                                            <div className="count">{item?.total_songs} Songs</div>
                                        </div>
                                        {trackAddedPlaylist?.includes(item?.id) ? (
                                            <Button
                                                className="add-playlist-btn"
                                                style={{ background: "#AD2756" }}
                                                onClick={() => addTrackToPlaylist(item, false)}
                                            >
                                                Remove from Playlist
                                            </Button>
                                        ) : (
                                            <Button
                                                className="add-playlist-btn"
                                                onClick={() => addTrackToPlaylist(item, true)}
                                            >
                                                {" "}
                                                Add to Playlist
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ color: "#fff" }}>No Playlist Found</div>
                        )}
                        <Divider />

                        <div className="bottom-btn-wrap">
                            <Button
                                className="add-playlist-btn"
                                onClick={() => handleCancel()}
                            >
                                {" "}
                                Cancel
                            </Button>

                            <Button
                                className="add-playlist-btn"
                                onClick={() => setShowPlaylistFlag(false)}
                            >
                                {" "}
                                Create new Playlist
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Form
                        name="basic"
                        onFinish={createNewPlaylist}
                        className="outer-wraper"
                    >
                        <Form.Item
                            label="Playlist Name"
                            name="playlistName"
                            rules={[
                                {
                                    required: true,
                                    message: "Playlist Name can not be empty!",
                                },
                            ]}
                        >
                            <Input className="input-box" placeholder="Playlist Name" />
                        </Form.Item>
                        <Divider />
                        <div className="bottom-btn-wrap">
                            <Button
                                className="add-playlist-btn"
                                onClick={() => setShowPlaylistFlag(true)}
                            >
                                {" "}
                                Cancel
                            </Button>

                            <Button className="add-playlist-btn" htmlType="submit">
                                {" "}
                                Create
                            </Button>
                        </div>
                    </Form>
                )}
            </Modal>

            <div className="song-list-wrap">
                {trackListData && trackListData?.length !== 0 ? (
                    trackListData?.map((song, idx) => (
                        <Card className="song-list-card" key={idx}>
                            <>
                                <div
                                    className="thumbnail-wraper"
                                    onClick={() => navigate(`/track-details?${song?.id}`)}
                                >
                                    <SvgLoader
                                        svgName={
                                            song?.genres.length !== 0
                                                ? `genre-${song?.genres[0]?.logo_url?.split(" ")[0]
                                                }-icon`
                                                : "genre-default"
                                        }
                                        className="thumbnail-image"
                                    />
                                    <IoMdInformationCircleOutline
                                        size={50}
                                        className="info-button"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>

                                <div>
                                    {currentSongIndex === idx ? (
                                        isPlaying === true ? (
                                            <HiPause
                                                className="pause-btn"
                                                size={60}
                                                color="#fff"
                                                onClick={() => onPlayPause(song, idx)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        ) : (
                                            <HiPlay
                                                size={60}
                                                color="#fff"
                                                onClick={() => onPlayPause(song, idx)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        )
                                    ) : (
                                        <HiPlay
                                            size={60}
                                            color="#fff"
                                            onClick={() => handlePlayCurrent(song, idx)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )}
                                </div>

                                <div
                                    className="audio-player-text"
                                    style={{ width: "150px", cursor: "pointer" }}
                                    onClick={() => navigate(`/track-details?${song?.id}`)}
                                >
                                    {song?.title}
                                </div>
                                <div style={{ width: "200px" }}>
                                    <WavesurferPlayer
                                        height={100}
                                        width={200}
                                        waveColor="#363636"
                                        progressColor="#FFCD00"
                                        autoplay={currentSongIndex === idx}
                                        url={currentSongIndex === idx ? audioBlob : blankAudio}
                                        onReady={(e) => onReady(e)}
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                    />
                                </div>
                                <div className="audio-player-text">
                                    <span>
                                        {currentSongIndex === idx
                                            ? formatTime(currentTime)
                                            : formatTime(0)}
                                        /
                                        {/* {currentSongIndex === idx
                                                    ? formatTime(duration)
                                                    : formatTime(0)} */}
                                        {song?.duration}
                                    </span>
                                    <br />
                                    <span>{song?.bpm} BPM</span>
                                </div>
                                {/* <div
                                                className="audio-player-text"
                                                style={{ width: "200px" }}
                                            >
                                                {song?.album} <br />
                                                {song?.artist}
                                            </div> */}
                                <div className="audio-player-icons">
                                    <Popover
                                        content={
                                            <div>
                                                <div className="comment-text">
                                                    My Note{" "}
                                                    <IoClose
                                                        size={20}
                                                        color="#fff"
                                                        className="all-icons"
                                                        onClick={() => hide()}
                                                    />
                                                </div>
                                                <Divider />

                                                {commentsList.length !== 0 && (
                                                    <>
                                                        <div className="comment-text">
                                                            <ul>
                                                                {commentsList?.map((item) => (
                                                                    <li key={item}>{item}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <Divider />
                                                    </>
                                                )}
                                                <div className="comment-text">
                                                    <textarea
                                                        className="comment-text"
                                                        rows="3"
                                                        placeholder="add a comment"
                                                        value={comment}
                                                        onChange={(e) => setComment(e?.target?.value)}
                                                    />
                                                    <SvgLoader
                                                        svgName="save-comment"
                                                        // className="thumbnail-image"
                                                        width={40}
                                                        height={40}
                                                        // onClick={hide}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleComment(song)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        // title="Title"
                                        trigger="click"
                                        open={open && openIdx === idx}
                                        onOpenChange={() => handleOpenChange(song, idx)}
                                        placement="bottomRight"
                                        arrow={false}
                                    >
                                        <MdOutlineInsertComment
                                            size={30}
                                            color="#fff"
                                            className="all-icons"
                                        />
                                    </Popover>
                                    {song?.is_liked ? (
                                        <GoHeartFill
                                            size={30}
                                            color={"#ff0000"}
                                            className="all-icons"
                                            onClick={() => handleFavorite(song)}
                                        />
                                    ) : (
                                        <GoHeart
                                            size={30}
                                            color={"#fff"}
                                            className="all-icons"
                                            onClick={() => handleFavorite(song)}
                                        />
                                    )}
                                    <FiPlus
                                        size={30}
                                        color="#fff"
                                        className="all-icons"
                                        onClick={() => showAddPlaylistModal(song)}
                                    />
                                    {/* <BsDownload size={30} color="#fff" className="all-icons"/> */}
                                    <LuLink size={30} color="#fff" className="all-icons" />
                                </div>
                            </>
                        </Card>
                    ))
                ) : (
                    <div
                        className="section-genre-title"
                        style={{
                            display: "flex",
                            height: "30vh",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No Data Found
                    </div>
                )}
            </div>
        </>
    );
};

export default TrackCard;
