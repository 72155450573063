/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-constant-condition */
/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import {
    Button,
    Card,
    Checkbox,
    Collapse,
    Divider,
    Dropdown,
    Input,
    Menu,
    Pagination,
    Popover,
    Slider,
} from "antd";
import Search from "antd/es/transfer/search";
import { MdOutlineInsertComment, MdSort } from "react-icons/md";
import { HiPause, HiPlay } from "react-icons/hi";
import { SiApplemusic } from "react-icons/si";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { GoHeartFill } from "react-icons/go";
import { GoHeart } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { LuLink } from "react-icons/lu";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    storeTrackIdAction,
    getTrackListAction,
    getGenresListAction,
    getThemesListAction,
    getMoodsListAction,
} from "./logic";
import WavesurferPlayer, { useWavesurfer } from "@wavesurfer/react";
import SvgLoader from "../../common/SvgLoader";
import axios from "axios";
import { getCookie } from "../../common/utils";

// eslint-disable-next-line no-unused-vars
const TopGenreDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // const [genreId, setGenreId] = useState();
    const [orderBy, setOrderBy] = useState("ASC");
    const [pageNo, setPageNo] = useState(1);
    const [search, setSearch] = useState("");
    const [selectedMood, setSelectedMood] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState([]);
    const [selectedBPM, setSelectedBPM] = useState([]);


    const [BPMvalue, setBPMvalue] = useState([-120, 200]);

    useEffect(() => {
        dispatch(getGenresListAction({}));
        dispatch(getThemesListAction({}));
        dispatch(getMoodsListAction({}));
    }, []);

    const { data: genresList } = useSelector((state) => state?.genresList);
    const { data: themesList } = useSelector((state) => state?.themesList);
    const { data: moodsList } = useSelector((state) => state?.moodsList)


    useEffect(() => {
        const data = {
            // genre_id: location.state.genreId,
            order_by: orderBy,
            page: pageNo,
            search: search,
            mood: selectedMood.join(", "),
            theme: selectedTheme.join(", "),
            // min_bpm: BPMvalue[0],
            // max_bpm: BPMvalue[1],

        };
        dispatch(getTrackListAction(data));
    }, [location?.state?.genreId, orderBy, pageNo, BPMvalue, selectedTheme, selectedMood]);

    const { data: trackListData } = useSelector((state) => state.trackListData);

    const [audioBlob, setAudioBlob] = useState();

    const { data: storeTrackId } = useSelector((state) => state?.storeTrackId);

    useEffect(() => {
        axios
            .get(
                `${process.env.apiUrl}playTrack?track_id=${storeTrackId?.track_id}`,
                {
                    headers: {
                        Authorization: getCookie("accessToken"),
                    },
                    responseType: "arraybuffer",
                }
            )
            .then((response) => {
                setIsPlaying(true);
                const blob = new Blob([response.data]);

                setAudioBlob(URL.createObjectURL(blob));
            })
            .catch((err) => {
                console.log(err?.response?.statusText);
                // dispatch(downloadDocumentFailure(err));
            });
    }, [storeTrackId]);


    const [filteredData, setFilteredData] = useState();



    useEffect(() => {

        setFilteredData({
            moodTopic: moodsList?.response?.moods,
            themeTopic: themesList?.response?.themes,
        });

    }, [themesList, moodsList, genresList])

    const onSearchSideFilter = (e) => {
        const searchValue = e.toLowerCase();


        const filtered = Object?.fromEntries(
            Object?.entries(filteredData)?.map(([key, value]) => [
                key,
                value?.filter((item) => item?.name?.toLowerCase()?.includes(searchValue)),
            ])
        );
        setFilteredData(filtered);
    };

    const onChangeComplete = (value) => {
        setBPMvalue(value);
    };

    console.log({ filteredData });

    const items = [
        {
            key: "Mood",
            label: "Mood",
            children: (
                <div>
                    {filteredData?.moodTopic?.map((item) => (
                        <>
                            <Checkbox
                                className="collapse-checkbox"
                                onChange={(e) => onChangeMoodTopic(e, item?.id)}
                            >
                                {item?.name}
                            </Checkbox>
                        </>
                    ))}
                </div>
            ),
        },
        {
            key: "theme",
            label: "Theme",
            children: (
                <div>
                    {filteredData?.themeTopic?.map((item) => (
                        <>
                            <Checkbox
                                className="collapse-checkbox"
                                onChange={(e) => onChangeThemeTopic(e, item?.id)}
                            >
                                {item?.name}
                            </Checkbox>
                        </>
                    ))}
                </div>
            ),
        },
        {
            key: "BPM",
            label: "BPM",
            children: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="bpm-count">{BPMvalue[0]}</div>
                    <Slider
                        range
                        defaultValue={BPMvalue}
                        max={200}
                        min={-120}
                        style={{ width: "100%" }}
                        onChangeComplete={onChangeComplete}
                    />
                    <div className="bpm-count">{BPMvalue[1]}</div>
                </div>
            ),
        },
    ];

    const onChangeMoodTopic = (e, idx) => {
        setSelectedMood((prev) => [...prev, idx])
    };

    const onChangeThemeTopic = (e, idx) => {
        setSelectedTheme((prev) => [...prev, idx])
    };


    const [currentSongIndex, setCurrentSongIndex] = useState();
    const [wavesurfer, setWavesurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // const wavesurferRef = useRef();

    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(255, 205, 0, 0);
    gradient.addColorStop(0, "rgb(255, 205, 0)");
    gradient.addColorStop(1, "rgb(96, 63, 221)");

    const [visible, setVisible] = useState(false);
    // const [currentTime, setCurrentTime] = useState(0);

    const handleClose = () => {
        setVisible(false);
    };

    const menuItems = [
        {
            key: "DESC",
            label: "Oldest to Newest",
            onClick: () => {
                setOrderBy("DESC");
            },
        },
        {
            key: "ASC",
            label: "Newest to Oldest",
            onClick: () => {
                setOrderBy("ASC");
            },
        },
    ];
    const blankAudio = "/music/silent_quarter-second.mp3";

    const audioRef = useRef(null);

    const handlePlayCurrent = (song, idx) => {
        // wavesurfer && wavesurfer.playPause();
        setCurrentSongIndex(idx);
        const data = {
            track_id: song?.id,
            title: song?.title,
            duration: song?.duration,
            genre_icon: song?.genre_icon,
            is_favourite: song?.is_favourite,
            notes: song?.notes,
        };

        dispatch(storeTrackIdAction(data));
        setIsPlaying(true);
    };

    const onPlayPause = (song, idx) => {
        wavesurfer && wavesurfer.playPause();
        // setCurrentSongIndex(null);
    };
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    // const [wavesurfer1, setWavesurfer1] = useState(null);

    const onReady = (ws) => {
        setWavesurfer(ws);
        setIsPlaying(true);
        setDuration(ws.getDuration());
        ws.on("audioprocess", () => {
            setCurrentTime(ws.getCurrentTime());
        });
    };

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        const formattedHours = h > 0 ? `${h}:` : "";
        const formattedMinutes = m < 10 ? `0${m}` : m;
        const formattedSeconds = s < 10 ? `0${s}` : s;

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    };

    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState("");

    const hide = () => {
        setOpen(false);
        // setComment("");
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleComment = (song) => {
        hide();
    };

    const handleFavorite = (song) => { };

    const handlePageChange = (page, pageSize) => {
        console.log(page, pageSize);
        setPageNo(page);
    }

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="home-page-components">
                <div className="menu-bar">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="collapse-wraper">
                        <Search
                            placeholder=""
                            allowClear
                            onChange={(e) => onSearchSideFilter(e.target.value)}
                            className="search-field"
                        />
                        <Collapse
                            className="filter-collapse"
                            defaultActiveKey={"Mood"}
                            expandIconPosition={"end"}
                            ghost
                            accordion
                            items={items}
                        />
                    </div>
                </div>
                <div className="home-content">
                    <div className="genre-detail-wrap">
                        <div className="filters">
                            <span className="heading">{location?.state?.title}</span>

                            <Dropdown
                                overlay={<Menu onClick={handleClose} items={menuItems} />}
                                visible={visible}
                                onVisibleChange={setVisible}
                                trigger={["click"]}
                            >
                                <span className="chip-btn">
                                    <MdSort />
                                    <span>Sort by</span>
                                </span>
                            </Dropdown>
                            {/* <span className="chip-btn">
                                <HiPlay />
                                Play all
                            </span> */}
                            <span className="chip-btn">
                                <SiApplemusic />
                                Add to collection
                            </span>
                            <span className="chip-btn" style={{ padding: "15px" }}>
                                {true ? <GoHeartFill /> : <GoHeart />}
                            </span>
                        </div>
                        <div className="song-list-wrap">
                            {console.log({ trackListData })}
                            {trackListData?.data?.map((song, idx) => (
                                <Card className="song-list-card" key={idx}>
                                    <>
                                        <div
                                            className="thumbnail-wraper"
                                            onClick={() => navigate(`/track-details?${song?.id}`)}
                                        >
                                            <SvgLoader
                                                svgName={`genre-${song?.genres[0]?.logo_url?.split(" ")[0]}-icon`}
                                                className="thumbnail-image"
                                            />
                                            <IoMdInformationCircleOutline
                                                size={30}
                                                className="info-button"
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                        <div>
                                            {currentSongIndex === idx ? (
                                                isPlaying === true ? (
                                                    <HiPause
                                                        className="pause-btn"
                                                        size={60}
                                                        color="#fff"
                                                        onClick={() => onPlayPause(song, idx)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                ) : (
                                                    <HiPlay
                                                        size={60}
                                                        color="#fff"
                                                        onClick={() => onPlayPause(song, idx)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                )
                                            ) : (
                                                <HiPlay
                                                    size={60}
                                                    color="#fff"
                                                    onClick={() => handlePlayCurrent(song, idx)}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            )}
                                        </div>

                                        <div
                                            className="audio-player-text"
                                            style={{ width: "150px" }}
                                        >
                                            {song?.title}
                                        </div>
                                        <div style={{ width: "200px" }}>
                                            <WavesurferPlayer
                                                height={100}
                                                width={200}
                                                waveColor="#FFFFFF40"
                                                progressColor="#ffc400"
                                                autoplay={currentSongIndex === idx}
                                                url={currentSongIndex === idx ? audioBlob : blankAudio}
                                                onReady={(e) => onReady(e)}
                                                onPlay={() => setIsPlaying(true)}
                                                onPause={() => setIsPlaying(false)}
                                            />
                                        </div>
                                        <div className="audio-player-text">
                                            {currentSongIndex === idx
                                                ? formatTime(currentTime)
                                                : formatTime(0)}
                                            /
                                            {currentSongIndex === idx
                                                ? formatTime(duration)
                                                : formatTime(0)}
                                            <br /> {song?.bpm} BPM
                                        </div>
                                        <div
                                            className="audio-player-text"
                                            style={{ width: "200px" }}
                                        >
                                            {song?.album} <br />
                                            {song?.artist}
                                        </div>
                                        <div className="audio-player-icons">
                                            <Popover
                                                content={
                                                    <div>
                                                        <div className="comment-text">My Note</div>
                                                        <Divider />
                                                        {song?.comment && (
                                                            <>
                                                                <div className="comment-text">
                                                                    {" "}
                                                                    {song?.comment}
                                                                </div>
                                                                <Divider />
                                                            </>
                                                        )}
                                                        <div className="comment-text">
                                                            <textarea
                                                                className="comment-text"
                                                                rows="3"
                                                                placeholder="add a comment"
                                                                onChange={(e) => setComment(e)}
                                                            />
                                                            <SvgLoader
                                                                svgName="save-comment"
                                                                // className="thumbnail-image"
                                                                width={40}
                                                                height={40}
                                                                // onClick={hide}
                                                                onClick={(song) => handleComment(song)}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                // title="Title"
                                                trigger="click"
                                                open={open}
                                                onOpenChange={handleOpenChange}
                                                placement="bottomRight"
                                                arrow={false}
                                            >
                                                <MdOutlineInsertComment
                                                    size={30}
                                                    color="#fff"
                                                    className="all-icons"
                                                />
                                            </Popover>
                                            <GoHeart
                                                size={30}
                                                color={song?.is_favourite ? "#ff0000" : "#fff"}
                                                className="all-icons"
                                                onClick={(song) => handleFavorite(song)}
                                            />
                                            <FiPlus size={30} color="#fff" className="all-icons" />
                                            {/* <BsDownload size={30} color="#fff" className="all-icons"/> */}
                                            <LuLink size={30} color="#fff" className="all-icons" />
                                        </div>
                                    </>
                                </Card>
                            ))}
                        </div>
                        <Pagination onChange={handlePageChange} defaultCurrent={trackListData?.page} total={trackListData?.total_count || 0} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopGenreDetail;
