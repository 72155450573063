export const NOTIFICATION = "NOTIFICATION";
export const CUSTOM_MESSAGE_FAILURE = "CUSTOM_MESSAGE_FAILURE";
export const CUSTOM_MESSAGE_SUCCESS = "CUSTOM_MESSAGE_SUCCESS";
export const NOTIFICATION_CANCEL = "NOTIFICATION_CANCEL";

const INTIAL_STATE = {
  type: NOTIFICATION_CANCEL,
  notificationType: "",
  message: "",
};

export const sendSuccessNotification = (payload) => ({
  type: CUSTOM_MESSAGE_SUCCESS,
  payload,
});

export const sendErrorNotification = (payload) => ({
  type: CUSTOM_MESSAGE_FAILURE,
  payload,
});

export const sendNotification = (payload) => ({
  type: NOTIFICATION,
  payload,
});

export const cancelNotification = () => ({
  type: NOTIFICATION_CANCEL,
});

export const notificationReducer = (state = INTIAL_STATE, action = null) => {
  switch (action.type) {
  case NOTIFICATION: {
    return {
      ...action.payload,
    };
  }
  // case NOTIFICATION_CANCEL: {
  //   return INTIAL_STATE;
  // }
  default:
    return state;
  }
};

export const customNotificationReducer = (state = {}, action = null) => {
  switch (action.type) {
  case CUSTOM_MESSAGE_SUCCESS: {
    return action.payload;
  }
  case CUSTOM_MESSAGE_FAILURE: {
    return action.payload;
  }
  default:
    return state;
  }
};
