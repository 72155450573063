/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Divider, Form, Input, Upload, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { getCookie } from "../../common/utils";
import axios from "axios";
import { RiDeleteBinLine } from "react-icons/ri";
import Loader from "../Loader/Loader.jsx";
import { toast } from "react-toastify";

export default function UploadMultipleTracksForm({
  setAllTrackData,
  setShowUploadTrackToProcess,
  setViewDetails,
  viewDetails,
  uploading,
  setUploading,
  allTrackData,
  setAudioBlob,
  audioBlob,
  setTrackTitle,
  trackTitle,
  blobSize,
  briefRequestId,
  uploadedTracks,
  setUploadedTracks,
  setUploadedTracksDetail,
  UploadedTracksDetail,
  setCurrentView,
}) {
  const [stepForm] = Form.useForm();
  const [trackOptionList, settrackOptionList] = useState(null);

  const [selectedTracks, setSelectedTracks] = useState([]);

  const onFinish = (values) => {
    console.log("values", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  console.log({ briefRequestId });
  const handleValueChange = (v) => {
    if (v.track_title) {
      setTrackTitle(v.track_title);
    }
  };

  const fetchData = () => {
    console.log("first");

    axios
      .get(`${process.env.apiUrl}trackOptionList`, {
        params: { brief_request_id: briefRequestId },
        headers: {
          Authorization: `${getCookie("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("calling");
        if (response.status === 200) {
          console.log("Track received successfully");
          setUploadedTracks(response?.data?.response);
        }
      })
      .catch((error) => {
        console.error("Unexpected error:", error);
      });
  };

  useEffect(() => {
    fetchData();
    console.log(getCookie("csrf_token"));
  }, [briefRequestId]);

  const handleUploadAction = ({ file }) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("title", file?.name);
    formData.append("file", file);
    formData.append("type", file?.type);
    formData.append("brief_request_id", briefRequestId);

    console.log("done setting file");
    axios
      .post(`${process.env.apiUrl}uploadTrackView`, formData, {
        headers: {
          Authorization: getCookie("accessToken"),
          "X-CSRF-Token": getCookie("csrf_token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            'Track Uploaded Successfully',
            {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
          fetchData();
        }
      })
      .catch((err) => {
        console.error("Upload Track Error:", err);
        toast.error("Error in Uploading Track", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleDelete = (track) => {
    axios
      .delete(`${process.env.apiUrl}uploadTrackView`, {
        params: { track_id: track?.id },
        headers: {
          Authorization: `${getCookie("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log({response})
        if (response.status === 200) {
          toast.success(
            'Track Deleted Successfully',
            {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
          fetchData();
        }
      })
      .catch((err) => {
        console.error("Delete Track Error:", err);
        toast.error("Error in Deleting Track", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const handleCheckboxChange = (track) => {
    setSelectedTracks((prev) => {
      if (prev.includes(track.id)) {
        return prev.filter((id) => id !== track.id);
      } else {
        return [...prev, track.id];
      }
    });
  };

  const sendForApproval = () => {
    if (selectedTracks?.length > 0) {
      axios
        .patch(
          `${process.env.apiUrl}trackOptionList`,
          { track_ids: selectedTracks, brief_request_id: briefRequestId },
          {
            headers: {
              Authorization: getCookie("accessToken"),
              accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("Tracks sent for approval successfully");
            toast.success(
              'Tracks Sent for Approval Successfully',
              {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
            fetchData()
            setSelectedTracks([])
          }
        })
        .catch((error) => {
          console.error("Error sending tracks for approval", error);
          toast.error(
            'Error in sending track/s for approval',
            {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        });
    }
  };

  console.log({ uploadedTracks });

  return (
    <div className="upload-track-for-processing">
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={stepForm}
        onValuesChange={handleValueChange}
      >
        <div className="step-1">
          <div className="title-wrapper">
            <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            <Form.Item label="" name="track_title">
              <Input placeholder="Type Track Name" />
            </Form.Item>
            {Array.isArray(selectedTracks) && selectedTracks?.length > 0 && (
              <Button className="send-button" onClick={sendForApproval}>
                Send for Approval
              </Button>
            )}
          </div>

          <Divider />
          {!uploading ? 
          <Form.Item label="" name="file_url">
            <Upload
              customRequest={handleUploadAction}
              showUploadList={false}
              // fileList={uploadedTracks}
              onRemove={handleDelete}
              multiple
              accept=".mp3,.flac,.wav"
            >
              <Button
                icon={
                  <SvgLoader
                    svgName="upload-track-outline-white-icon"
                    className="upload-white-icon"
                    height={40}
                    width={40}
                  />
                }
              >
                <div>
                  <span style={{ color: "#fff" }}>
                    Choose files or drag & drop them here
                  </span>{" "}
                  <br />
                  Supports MP3, FLAC, WAV formats, up to 50MB
                </div>
              </Button>
            </Upload>
          </Form.Item>
          : <Loader height={10} marginTop={15}/>}
          <div className="checkbox-wrap-upload">
            {console.log({ uploadedTracks })}
            {uploadedTracks?.length > 0 && (
              <div>
                {uploadedTracks.map((track, index) => (
                  <div key={track.id} className="file-upload-card">
                    <div className="file-upload-card-text">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Checkbox
                            disabled={track.bmq_employee_status == "sent_to_client"}
                            checked={selectedTracks?.includes(track?.id)}
                            onChange={() => handleCheckboxChange(track)}
                          ></Checkbox>
                          <SvgLoader
                            svgName={"track-thumbnail"}
                            width={50}
                            height={50}
                          />
                          <div className="file-upload-text-wrap">
                            <span className="file-upload-text">
                              {track.title}
                            </span>
                          </div>
                        </div>
                        <div>
                          <RiDeleteBinLine
                            size={25}
                            style={{ cursor: "pointer", marginLeft: 10 }}
                            onClick={() => handleDelete(track)}
                          />
                        </div>
                      </div>
                      <div
                        className="file-upload-text-wrap"
                        style={{ marginLeft: "100px" }}
                      >
                        <span className="file-upload-text">
                          {(track.size / 1000000).toFixed(1)} MB
                        </span>
                        {track.bpm != '0.0' && (
                          <div
                            className="file-upload-text"
                            style={{ color: '#FFCD00', cursor: 'pointer' }}
                            onClick={() => {
                              setCurrentView('details');
                              setUploadedTracksDetail(track);
                            }}
                          >
                            {' '}
                            View More
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
