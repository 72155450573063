/* eslint-disable indent */
import { genericActionCreator } from "../../app/epics";
import { getCookie } from "../../common/utils";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

// Access Request Action Types
const GET_ACCESS_REQUEST_LIST = "GET_ACCESS_REQUEST_LIST";
const GET_ACCESS_REQUEST_LIST_SUCCESS = "GET_ACCESS_REQUEST_LIST_SUCCESS";
const GET_ACCESS_REQUEST_LIST_FAILURE = "GET_ACCESS_REQUEST_LIST_FAILURE";
const GET_ACCESS_REQUEST_LIST_RESET = "GET_ACCESS_REQUEST_LIST_RESET";

// Brief Request Action Types
const GET_BRIEF_REQUEST_LIST = "GET_BRIEF_REQUEST_LIST";
const GET_BRIEF_REQUEST_LIST_SUCCESS = "GET_BRIEF_REQUEST_LIST_SUCCESS";
const GET_BRIEF_REQUEST_LIST_FAILURE = "GET_BRIEF_REQUEST_LIST_FAILURE";
const GET_BRIEF_REQUEST_LIST_RESET = "GET_BRIEF_REQUEST_LIST_RESET";

export const getAccessRequestListAction = (payload) =>
  genericActionCreator(GET_ACCESS_REQUEST_LIST, payload, {
    url: `${process.env.apiUrl}admin/accessRequest`,
    method: "GET",
    auth: true,
    headers: {
      Authorization: getCookie("accessToken"),
    },
    // params: {
    //   genre_id: payload.genreId,
    //   order_by: payload.orderBy,
    //   page: payload.page,
    //   page_size: payload.pageSize,
    // },
  });

export const getBriefRequestListAction = (payload) =>
  genericActionCreator(GET_BRIEF_REQUEST_LIST, payload, {
    url: `${process.env.apiUrl}/admin/brif`,
    method: "GET",
    auth: true,
    headers: {
      Authorization: getCookie("accessToken"),
    },
    // params: {
    //   genre_id: payload.genreId,
    //   order_by: payload.orderBy,
    //   page: payload.page,
    //   page_size: payload.pageSize,
    // },
  });

export const getAccessRequestListResetAction = () => ({
  type: GET_ACCESS_REQUEST_LIST_RESET,
});

export const getBriefRequestListResetAction = () => ({
  type: GET_BRIEF_REQUEST_LIST_RESET,
});

export function getAccessRequestListReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_ACCESS_REQUEST_LIST_RESET: {
      return INITIAL_STATE;
    }
    case GET_ACCESS_REQUEST_LIST: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_ACCESS_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_ACCESS_REQUEST_LIST_FAILURE: {
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}

export function getBriefRequestListReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_BRIEF_REQUEST_LIST_RESET: {
      return INITIAL_STATE;
    }
    case GET_BRIEF_REQUEST_LIST: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_BRIEF_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_BRIEF_REQUEST_LIST_FAILURE: {
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}
