import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import ErrorPage from "./common/ErrorPage";
import "../public/styles/main.scss";
import "./app.css";
import LoginPage from "./pages/login";
import Home from "./pages/home";
import TopGenreDetail from "./pages/topGenreDetail";
import Demo from "./pages/demo";
import SignupPage from "./pages/signup";
import TrackDetails from "./pages/trackDetails";
import MyGallary from "./pages/MyGallery";
import RequestNewTrack from "./pages/RequestNewTrack";
import UploadTrack from "./pages/UploadTrack";
import ClientAdmin from "./pages/ClientAdmin/index.jsx";
import UploadedTracks from "./pages/UploadedTracks/UploadedTracks.jsx";
import { Bounce, ToastContainer } from "react-toastify";
// import MusicPlayerState from "./components/MusicPlayerCheck/index.js"; // Import your MusicPlayer component

// Create a layout component that includes the MusicPlayer
const MainLayout = () => (

  <div>
    {/* <MusicPlayerState /> */}
    <Outlet />
  </div>
);

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <SignupPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/demo",
        element: <Demo />,
      },
      {
        path: "/top-genre-detail",
        element: (
          <TopGenreDetail
            currentPlaying={"currentPlaying"}
            SetCurrentPlaying={"SetCurrentPlaying"}
          />
        ),
      },
      {
        path: "/track-details",
        element: <TrackDetails />,
      },
      {
        path: "/my-gallery",
        element: <MyGallary />,
      },
      {
        path: "/request-new-track",
        element: <RequestNewTrack />,
      },
      {
        path: "/upload-track",
        element: <UploadTrack />,
      },
      {
        path: "/client-admin",
        element: <ClientAdmin />,
      },
      {
        path: "/uploaded-tracks",
        element: <UploadedTracks />,
      },
    ],
  },
]);

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Bounce}
    />
  </Provider>
);
