/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Menu } from "antd";
import SvgLoader from "../../common/SvgLoader";
import SquareCard from "../../components/SquareCard";
import CircularCard from "../../components/CircularCard";
import {
  ClockCircleOutlined,
  DownloadOutlined,
  HeartOutlined,
  // MoreOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getHomeCardsAction, getRecentlyAddedAction, getSuggestionsAction } from "./logic";
import { getCookie, handleLogout } from "../../common/utils";
import WavesurferPlayer from "@wavesurfer/react";
import axios from "axios";
import { HiPause, HiPlay } from "react-icons/hi";
import { toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [genreId, setGenreId] = useState("");
  const [audioBlob, setAudioBlob] = useState();
  const blankAudio = "/music/silent_quarter-second.mp3";
  const [isPlaying, setIsPlaying] = useState(false);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [currentSongIndex, setCurrentSongIndex] = useState();
  const [isSuggestion, setIsSuggestion] = useState();

  useEffect(() => {
    dispatch(getHomeCardsAction({}));
    dispatch(getRecentlyAddedAction({}));
    dispatch(getSuggestionsAction({}));
  }, []);

  const { data: homeCardData } = useSelector((state) => state.homeCardData);
  const { data: recentlyAddedTracks } = useSelector(
    (state) => state.recentlyAddedTracks
  );
  const { data: suggestionsData } = useSelector((state) => state.suggestionsData)

  const role = getCookie("roles");

  const items = [
    {
      key: "home",
      icon: <SvgLoader svgName="home-icon" height={40} width={40} />,
      label: "Home",
    },
    {
      key: "explore",
      icon: <SvgLoader svgName="explore-icon" height={40} width={40} />,
      label: "Explore",
    },
    {
      key: "gallery",
      icon: <SvgLoader svgName="gallery-icon" height={40} width={40} />,
      label: "My Gallery",
    },
    {
      key: "request",
      icon: <SvgLoader svgName="request-icon" height={40} width={40} />,
      label: "Request New Brief",
    },
    {
      key: "upload",
      icon: <UploadOutlined style={{ fontSize: "40px" }} />,
      label: "Upload Track",
    },
    (role?.includes("Client Admin") ||
      role?.includes("BMQ Admin") ||
      role?.includes("BMQ User")) && {
      key: "admin-panel",
      icon: <MdOutlineAdminPanelSettings size={40} />,
      label: "Admin Panel",
    },
    {
      key: "logout",
      icon: <SvgLoader svgName="logout-icon" height={40} width={40} />,
      label: "Logout",
    },
  ];
  const onClick = (e) => {
    if (e.key === "explore") {
      navigate("/explore", {
        state: { genreId: genreId, title: "All Tracks" },
      });
    }
    if (e.key === "gallery") {
      navigate("/my-gallery");
    }
    if (e.key === "request") {
      navigate("/request-new-track");
    }
    if (e.key === "upload") {
      navigate("/uploaded-tracks");
    }
    if (e.key === "admin-panel") {
      navigate("/admin");
    }
    if (e.key === "logout") {
      navigate("/login");
      handleLogout();
    }
  };

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(true);
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
    // setIsPlaying(false);
  };

  const playSong = (song, idx) => {
    setCurrentSongIndex(idx);

    axios
      .get(`${process.env.apiUrl}playTrack?track_id=${song.id}`, {
        headers: {
          Authorization: getCookie("accessToken"),
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data]);

        setAudioBlob(URL.createObjectURL(blob));
      })
      .catch((err) => {
        toast.error(err?.response?.request?.statusText || "File Not Found", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });

    setIsPlaying(true);
  };
  return (
    <div className="home-page">
      {" "}
      <Header showSearch={true} />
      <div className="home-page-components">
        <div className="menu-bar">
          <Menu
            onClick={onClick}
            defaultSelectedKeys={["home"]}
            // defaultOpenKeys={["sub1"]}
            mode="inline"
            items={items}
            className="menu"
          />
        </div>
        <div className="home-content">
          {/* {homeCardData && */}
          <div className="section-genre">
            <div className="section-genre-title">Top Genre</div>
            <div className="section-genre-top">
              {homeCardData?.data?.genres?.map((e, i) => {
                return (
                  <>

                    <div
                      onClick={() =>
                        navigate("/explore", {
                          state: { genreId: e?.name, title: `Genre: ${e?.name}` },
                        })
                      }
                    >
                      <SquareCard
                        key={i}
                        name={e?.name}
                        count={e?.count}
                        icon={e?.logo_url?.split(" ")[0]}
                        index={i}
                        imageHeight={150}
                        imageWidth={180}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          {/* } */}
          <div className="section-mood">
            <div className="section-mood-title">Top Mood</div>
            <div className="section-mood-top">
              {homeCardData?.data?.moods?.map((e, i) => {
                return (
                  <>
                    <div
                      onClick={() =>
                        navigate("/explore", {
                          state: { moodId: e?.name, title: `Mood: ${e?.name}` },
                        })
                      }
                    >
                      <CircularCard
                        name={e?.name}
                        count={e?.count}
                        icon={e?.logo_url?.split(" ")[0]}
                        index={i}
                        key={i}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="section-genre">
            <div className="section-genre-title">Top Theme</div>
            <div className="section-genre-top">
              {homeCardData?.data?.themes?.map((e, i) => {
                return (
                  <>
                    <div
                      onClick={() =>
                        navigate("/explore", {
                          state: { themeId: e?.name, title: `Theme: ${e?.name}` },
                        })
                      }
                    >
                      <SquareCard
                        key={i}
                        name={e?.name}
                        count={e?.count}
                        icon={e?.logo_url?.split(" ")[0]}
                        index={i}
                        imageHeight={150}
                        imageWidth={180}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          {/* <div className="section-mood">
            <div className="section-mood-title">Popular/Trending</div>
            <div className="section-mood-top">
              {homeDataMood?.map((e, i) => {
                return <CircularCard data={e} index={i} key={i} />;
              })}
            </div>
          </div> */}
          <div className="section-recently-suggestion">
            <div className="section-recently">
              <div className="title-4">
                <span>Recently Added</span>
                <span
                  className="title-see-all"
                  onClick={() =>
                    navigate("/explore", {
                      state: { genreId: genreId, title: "All Tracks" },
                    })
                  }
                >
                  See All
                </span>
              </div>
              <div className="track-list">
                {recentlyAddedTracks?.data?.map((e, i) => {
                  return (
                    <div className="track-details" key={i}>
                      <span className="track-desc">
                        <div className="track-image">
                          <SvgLoader
                            svgName="no-image-found"
                            height={30}
                            width={30}
                          />
                        </div>
                        <div className="title-5">
                          {e?.title} <br />{" "}
                          <span className="song-type">
                            {e?.genres?.map((e) => String(e.name))?.join(", ")}
                          </span>
                        </div>
                      </span>
                      <div className="play-count">
                        {!isSuggestion && currentSongIndex === i ? (
                          isPlaying ? (
                            <HiPause
                              className="pause-btn"
                              size={30}
                              color="#fff"
                              onClick={() => onPlayPause(e, i)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <HiPlay
                              size={30}
                              color="#fff"
                              onClick={() => onPlayPause(e, i)}
                              style={{ cursor: "pointer" }}
                            />
                          )
                        ) : (
                          <HiPlay
                            size={30}
                            color="#fff"
                            onClick={() => { setIsSuggestion(false), playSong(e, i) }}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        <WavesurferPlayer
                          height={0}
                          width={0}
                          waveColor="#363636"
                          progressColor="#FFCD00"
                          autoplay={true}
                          url={currentSongIndex === i ? audioBlob : blankAudio}
                          onReady={(e) => onReady(e)}
                          onPlay={() => setIsPlaying(true)}
                          onPause={() => setIsPlaying(false)}
                        />
                        <span className="viewed-count">
                          {e?.play_count} Plays
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="section-suggestion">
              <div className="title-4">
                <span>Suggestions</span>
                <span className="title-see-all"
                  onClick={() =>
                    navigate("/explore", {
                      state: { genreId: genreId, title: "All Tracks" },
                    })
                  }
                > See All</span>
              </div>
              <div className="track-list">
                {suggestionsData?.data?.map((e, i) => {
                  return (
                    <div className="track-details" key={i}>
                      <span className="track-desc">
                        <div className="track-image">
                          <SvgLoader
                            svgName="no-image-found"
                            height={30}
                            width={30}
                          />
                        </div>
                        <div className="title-5">
                          {e?.title} <br />{" "}
                          <span className="song-type">Genre: {e?.genre?.map((e) => String(e.name))?.join(", ")}</span>
                        </div>
                      </span>
                      <div className="play-count">
                        {isSuggestion && currentSongIndex === i ? (
                          isPlaying ? (
                            <HiPause
                              className="pause-btn"
                              size={30}
                              color="#fff"
                              onClick={() => onPlayPause(e, i)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <HiPlay
                              size={30}
                              color="#ffcd00"
                              onClick={() => onPlayPause(e, i)}
                              style={{ cursor: "pointer" }}
                            />
                          )
                        ) : (
                          <HiPlay
                            size={30}
                            color="#ffcd00"
                            onClick={() => { setIsSuggestion(true), playSong(e, i) }}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        <span className="viewed-count">{e?.play_count}</span>
                      </div>
                      <div className="track-stats">
                        <span>
                          <HeartOutlined className="track-icons" />{" "}
                          {e?.liked_count}
                        </span>
                        <span>
                          <DownloadOutlined className="track-icons" />{" "}
                          {e?.download_count}
                        </span>
                        <span>
                          <ClockCircleOutlined className="track-icons" />{" "}
                          {e?.duration}
                        </span>
                        {/* <span>
                          <MoreOutlined
                            className="track-icons more"
                            id="more-icon"
                          />
                        </span> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Home;
