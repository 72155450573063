/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Divider, Form, Input, Upload } from "antd";
import React, { useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { getCookie } from "../../common/utils";
import axios from "axios";
import { RiDeleteBinLine } from "react-icons/ri";

export default function UploadedTracksForm({
  setAllTrackData,
  setShowUploadTrackToProcess,
  setViewDetails,
  viewDetails,
  uploading,
  setUploading,
  allTrackData,
  setAudioBlob,
  audioBlob,
  setTrackTitle,
  trackTitle,
  blobSize,
}) {
  const [stepForm] = Form.useForm();

  const onFinish = (values) => {
    console.log("values", values);
    // stepForm
    //   .validateFields()
    //   .then(() => {
    //     setFormData((prev) => ({ ...prev, ...values }));
    //     setUploadSucess(true);
    //   })
    //   .catch((errorInfo) => {
    //     console.log('Failed:', errorInfo);
    //   });

    // console.log({ formData });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleValueChange = (v) => {
    if (v.track_title) {
      setTrackTitle(v.track_title);
    }
  };

  const handleUploadAction = async (file) => {
    try {
      setUploading(true);
      const blob = new Blob([file?.file], { type: file?.file?.type });
      setAudioBlob(blob);
      const response = await axios.post(
        `${process.env.apiUrl}admin/get_mood_list`,
        { file_url: file.file },
        {
          headers: {
            Authorization: getCookie("accessToken"),
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      if (response.status === 200 && response.data.data) {
        console.log("Upload Successful", response.data);
        // let data = response.data.data;
        // data.moods = data.moods.random();
        setAllTrackData(response.data.data);
        // setShowUploadTrackToProcess(false);
        setUploading(false);
      }
      return null;
    } catch (err) {
      console.log("Uppload Track Error");
      return null;
    }
  };

  const handleDelete = () => {
    setAllTrackData(false);
  };

  return (
    <div className="upload-track-for-processing">
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // onSubmit={onFinish}
        form={stepForm}
        onValuesChange={handleValueChange}
      >
        <div className="step-1">
          <div className="title-wrapper">
            <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            <Form.Item label="" name="track_title">
              <Input placeholder="Type Track Name" />
            </Form.Item>
          </div>

          <Divider />

          <div className="checkbox-wrap-upload">
            {uploading || allTrackData ? (
              <div className="file-upload-card">
                <SvgLoader svgName={"track-thumbnail"} width={50} height={50} />
                <div className="file-upload-card-text">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      className="file-upload-text"
                      style={{ fontSize: "25px" }}
                    >
                      Track Option 1
                    </span>
                    {!uploading && (
                      <span onClick={handleDelete}>
                        <RiDeleteBinLine
                          size={25}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                  <div className="file-upload-text-wrap">
                    <span
                      className="file-upload-text"
                      style={{ color: "#A9ACB4" }}
                    >
                      {blobSize ? (blobSize / 1000000).toFixed(1) : "0"} MB
                    </span>
                    <span className="file-upload-text">•</span>

                    <span className="file-upload-text">
                      {" "}
                      {uploading ? (
                        <>Uploading and Analyzing</>
                      ) : (
                        <>
                          <SvgLoader svgName={"done"} width={20} height={20} />
                          Completed
                        </>
                      )}
                    </span>
                    {!uploading && (
                      <span
                        className="file-upload-text"
                        style={{ color: "#FFCD00", cursor: "pointer" }}
                        onClick={() => setViewDetails(true)}
                      >
                        View Details
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Form.Item label="" name="file_url">
                <Upload
                  customRequest={handleUploadAction}
                  showUploadList={false}
                  onChange={onFinish}
                  accept=".mp3"
                  previewFile={(blob) => {
                    console.log({ blob });
                  }}
                >
                  <Button
                    icon={
                      <SvgLoader
                        svgName="upload-track-outline-white-icon"
                        className="upload-white-icon"
                        height={40}
                        width={40}
                      />
                    }
                  >
                    <div>
                      <span style={{ color: "#fff" }}>
                        Choose a file or drag & dropo it here
                      </span>{" "}
                      <br />
                      Supports MP3, FLAC, WAV formats, up to 50MB
                    </div>
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
