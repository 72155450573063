/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/index.js";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Form, Modal, Tabs, Tag, Input } from "antd";
import RequestTable from "../../components/ClientAdminRequestTable/RequestTable.jsx";
import AccessRequestDetailsModal from "../../components/AccessRequestDetailsModal/AccessRequestDetailsModal.jsx";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import WavesurferPlayer from "@wavesurfer/react";
import { HiPause, HiPlay } from "react-icons/hi";
import UploadedTracksForm from "../../components/UploadedTracksForm/UploadedTracksForm.jsx";
import { useWavesurfer } from "@wavesurfer/react";
import SvgLoader from "../../common/SvgLoader/index.js";

export default function UploadedTracks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAccessRequestDetailsModal, setShowAccessRequestDetailsModal] =
    useState(false);
  const [selectedAccessRequest, setSelectedAccessRequest] = useState(false);
  const [audioBlob, setAudioBlob] = useState();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadTrackToProcess, setShowUploadTrackToProcess] =
    useState(true);

  const [allTrackData, setAllTrackData] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [trackTitle, setTrackTitle] = useState(false);
  const [blobSize, setBlobSize] = useState(false);

  useEffect(() => {
    console.log("from client admin");
    // dispatch(getAccessRequestListAction());
  }, []);
  const [checkBlob, setCheckBlob] = useState();
  useEffect(() => {
    if (audioBlob !== undefined) {
      setBlobSize(audioBlob.size);
      const demoBlob = URL.createObjectURL(audioBlob);
      setCheckBlob(demoBlob);
    }
  }, [audioBlob !== undefined]);

  function formatDateFromTimestamp(timestamp) {
    // Convert timestamp to milliseconds
    const date = new Date(timestamp * 1);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Format date as dd-mm-yyyy
    return `${day}-${month}-${year}`;
  }

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      email: "a@b.com",
      request_date: "20-02-2024",
      valid_upto: "08/24",
      created_at: "1724740697300",
      permission: "p q r s",
      status: "APPROVED",
      organization_name: "ICS",
      purpose: "test",
    },
    {
      key: "2",
      name: "John",
      email: "a@b.com",
      request_date: "20-02-2024",
      created_at: "1724240697300",
      valid_upto: "08/24",
      permission: "a b c d",
      status: "REJECTED",
      organization_name: "PB",
      purpose: "test",
    },
    {
      key: "3",
      name: "Robe",
      email: "a@b.com",
      request_date: "20-02-2024",
      created_at: "1724740697300",
      valid_upto: "08/24",
      permission: "a b c d",
      status: "APPROVED",
      organization_name: "ICS",
      purpose: "test",
    },
  ];

  const accessRequestColumns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Track Name",
      dataIndex: "name",
      key: "name",
      render: (name, record, index) => {
        return (
          <>
            <span className="ar-name-data">{name}</span>
            <br />
            <span className="ar-name-email">
              {record.email ? record.email : ""}
            </span>
          </>
        );
      },
    },
    {
      title: "Uploaded On",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => formatDateFromTimestamp(created_at),
    },
    {
      title: "Stems",
      dataIndex: "valid_upto",
      key: "valid_upto",
      render: (name, record, index) => {
        return (
          <>
            <span>Stem:1</span>
            <br />
            <span>Stem:2</span>
            <br />
            <span>Stem:3</span>
          </>
        );
      },
    },
    {
      title: "Edits",
      dataIndex: "permission",
      key: "permission",
      render: (name, record, index) => {
        return (
          <>
            <span>Edit:1</span>
            <br />
            <span>Edit:2</span>
            <br />
            <span>Edit:3</span>
          </>
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <div>
          {status == "PENDING" ? (
            <span
              className="ar-pending-text"
              onClick={() => {
                handleViewARDetails(event, record);
              }}
            >
              View Details
            </span>
          ) : status == "APPROVED" ? (
            <>
              <div className="ar-approved-text">Approved</div>
            </>
          ) : (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Your Uploads",
      children: (
        <RequestTable columns={accessRequestColumns} dataSource={dataSource} />
      ),
    },
  ];

  const handleViewARDetails = (e, data) => {
    // console.log(e, data)
    setSelectedAccessRequest(data);
    setShowAccessRequestDetailsModal(true);
  };

  const [moodsList, setMoodList] = useState([]);
  const [moodsListData, setMoodListData] = useState([]);
  // const moodsList = [
  //   "Energetic",
  //   "Uplifting",
  //   "Happy",
  //   "Chill",
  //   "Calm",
  //   "Sad",
  //   "Dark",
  //   "Aggressive",
  //   "Ethereal",
  //   "Sexy",
  //   "Epic",
  //   "Romantic",
  // ];

  useEffect(() => {
    if (allTrackData) {
      allTrackData?.moods?.map((mood) => {
        setMoodList((prev) => [...prev, mood?.mood_name]);
        setMoodListData((prev) => [...prev, mood?.score]);
      });
    }
  }, [allTrackData]);
  const config = {
    options: {
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: "#ffffff",
            strokeWidth: 2,
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: ["#ffffff"],
            fontSize: "16px",
            fontFamily: "Montserrat-Regular",
            fontWeight: 600,
          },
          offsetY: 5,
        },
        range: 1,
      },
      yaxis: {
        show: false,
        // stepSize: 0.1,
      },

      colors: ["#17B3FF"],
      labels: moodsList,
      stroke: {
        width: 2,
        colors: ["#ffffff"],
      },
      fill: {
        opacity: 0.6,
      },

      markers: {
        size: 0,
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: moodsListData,
      },
    ],
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  // const containerRef = useRef(null);

  // const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
  //   container: containerRef,
  //   url: audioBlob,
  //   waveColor: "purple",
  //   height: 100,
  // });

  return (
    <div className="home-page">
      <Modal
        wrapClassName="upload-track-modal-wrap"
        title=""
        centered
        open={showUploadModal}
        onOk={() => setShowUploadModal(false)}
        onCancel={() => setShowUploadModal(false)}
        width={750}
        footer={false}
      >
        {/* <Input placeholder="Track title" /> */}

        {showUploadTrackToProcess && !viewDetails ? (
          <UploadedTracksForm
            setViewDetails={setViewDetails}
            setAllTrackData={setAllTrackData}
            setShowUploadTrackToProcess={setShowUploadTrackToProcess}
            viewDetails={viewDetails}
            uploading={uploading}
            setUploading={setUploading}
            allTrackData={allTrackData}
            setAudioBlob={setAudioBlob}
            audioBlob={audioBlob}
            setTrackTitle={setTrackTitle}
            trackTitle={trackTitle}
            blobSize={blobSize}
          />
        ) : (
          <div className="radar-and-player">
            {/* <div className="title-wrapper">
            <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            <div label="" name="track-title">
              <Input placeholder="Type Track Name" />
            </div>
          </div> */}
            <div className="radar-and-tags" style={{ display: "flex" }}>
              <ReactApexChart
                options={config.options}
                series={config.series}
                type="radar"
                height="300"
                width={350}
                className="apex-chart-mood-radar"
              />
              {/* <div className="radar-tags-divider"></div> */}

              <div className="tags-wrapper">
                {moodsList?.map((item, idx) => (
                  <Tag color="#AD2756" key={idx}>
                    {item}
                  </Tag>
                ))}
              </div>
            </div>
            <Divider />

            <div className="music-player-wrap">
              <div className="track-title">
                {trackTitle ? trackTitle : "Music Title"}
              </div>
              <div className="track-details">
                <span>
                  <div>
                    Genre: {allTrackData?.GENRE?.map((item) => item + ", ")}
                  </div>
                  <div>
                    Rasa: {allTrackData?.RASA?.map((item) => item + ", ")}
                  </div>
                </span>
                <span style={{ borderRight: "1px solid #fff" }}></span>
                <span>
                  <div>BPM: {allTrackData?.BPM}</div>
                </span>
              </div>

              <div className="music-player-wave-wraper">
                <div>
                  {!!viewDetails && audioBlob !== undefined && (
                    <div className="wavesurfer">
                      <WavesurferPlayer
                        height={100}
                        // width={200}
                        waveColor="#FFFFFF40"
                        progressColor="#ffc400"
                        // autoplay
                        url={checkBlob}
                        onReady={onReady}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                      />
                    </div>
                  )}
                  {/* <div ref={containerRef} /> */}

                  {audioBlob && isPlaying ? (
                    <HiPause
                      size={60}
                      color="#fff"
                      onClick={onPlayPause}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <HiPlay
                      size={60}
                      color="#fff"
                      onClick={onPlayPause}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Header showSearch={true} />

      <div className="uploaded-tracks-component">
        <div
          className="back-btn"
          style={{ position: "absolute" }}
          onClick={() => navigate(-1)}
        >
          <MdOutlineKeyboardBackspace />
          Back
        </div>

        <Button
          onClick={() => {
            setShowUploadModal(true);
          }}
          className="btn-upload-track"
          size="large"
        >
          Upload New Track
        </Button>

        <div className="client-admin-tabs-section">
          <AccessRequestDetailsModal
            selectedAccessRequest={selectedAccessRequest}
            showAccessRequestDetailsModal={showAccessRequestDetailsModal}
            setShowAccessRequestDetailsModal={setShowAccessRequestDetailsModal}
          />

          <Tabs
            className="client-admin-tabs"
            defaultActiveKey="1"
            items={items}
            // onChange={onChangeTab}
          />
        </div>
      </div>
    </div>
  );
}
