/* eslint-disable indent */
import { genericActionCreator } from "../../app/epics";
const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const GET_TRACK_DETAILS = "GET_TRACK_DETAILS";
const GET_TRACK_DETAILS_SUCCESS = "GET_TRACK_DETAILS_SUCCESS";
const GET_TRACK_DETAILS_FAILURE = "GET_TRACK_DETAILS_FAILURE";
const GET_TRACK_DETAILS_RESET = "GET_TRACK_DETAILS_RESET";


const GET_EDITS_DETAILS = "GET_EDITS_DETAILS";
const GET_EDITS_DETAILS_SUCCESS = "GET_EDITS_DETAILS_SUCCESS";
const GET_EDITS_DETAILS_FAILURE = "GET_EDITS_DETAILS_FAILURE";
const GET_EDITS_DETAILS_RESET = "GET_EDITS_DETAILS_RESET";



export const getTrackDetailsAction = (payload) =>
    genericActionCreator(
        // console.log(payload.id),
        GET_TRACK_DETAILS,
        { track_id: payload.id },
        {
            url: `${process.env.apiUrl}trackDetails`,
            method: "GET",
            auth: true,

            // params: {
            //     track_id: payload.id,
            // },
        }
    );
export const getTrackDetailsResetAction = () => ({
    type: GET_TRACK_DETAILS_RESET,
});

export function getTrackDetailsReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_TRACK_DETAILS_RESET: {
            return INITIAL_STATE;
        }
        case GET_TRACK_DETAILS: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_TRACK_DETAILS_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_TRACK_DETAILS_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}



export const getEditsDetailsAction = (payload) =>
    genericActionCreator(
        // console.log(payload.id),
        GET_EDITS_DETAILS,
        {
            track_id: payload.id,
            type: payload.type
        },
        {
            url: `${process.env.apiUrl}trackEditsStems`,
            method: "GET",
            auth: true,
        }
    );
export const getEditsDetailsResetAction = () => ({
    type: GET_EDITS_DETAILS_RESET,
});

export function getEditsDetailsReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_EDITS_DETAILS_RESET: {
            return INITIAL_STATE;
        }
        case GET_EDITS_DETAILS: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_EDITS_DETAILS_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_EDITS_DETAILS_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}
