import React from "react";
import SvgLoader from "../../common/SvgLoader";
import Search from "antd/es/transfer/search";
// import { Badge } from "antd";
// import { HiOutlineBell } from "react-icons/hi2";
import { getCookie } from "../../common/utils";
import { useNavigate } from "react-router-dom";

function Header({ showSearch }) {
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const navigate = useNavigate();
  return (
    <div className="header">
      {/* <div className="logo"></div> */}
      <SvgLoader
        svgName="soniq-vault-logo"
        width={150}
        height={130}
        className="soniq-vault-logo"

        onClick={() => navigate("/home")}
      />
      <div className="search-box" style={{ visibility: showSearch ? "visible" : "hidden" }}>
        <Search
          placeholder="Search Genre, Moods...."
          allowClear
          onSearch={onSearch}
          style={{
            width: 200,
          }}
          className="search-field"

        />
      </div>
      <div className="user-details" style={{ visibility: showSearch ? "visible" : "hidden" }}>
        <div className="user-name">{getCookie("user")}</div>
        <div className="logo">
          <SvgLoader svgName="no-image-found" width={50} height={50} />
        </div>
        {/* <Badge count={5} size="small" offset={[-10, 0]}>
          <HiOutlineBell color="#fff" size={40} />
        </Badge> */}
      </div>
    </div>
  );
}

export default Header;
