/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
    MdOutlineInsertComment,
    MdOutlineKeyboardBackspace,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineFileAdd } from "react-icons/ai";
import { MdOutlineLibraryMusic } from "react-icons/md";
import { FiDownload, FiPlus } from "react-icons/fi";
import { BiLike } from "react-icons/bi";
import { MdOutlineComment } from "react-icons/md";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    getGalleryCountAction,
    getMyBriefsAction,
    getMyCollectionAction,
    getMyDownloadsAction,
    getMyLikesAction,
    getMyNotesAction,
    getPlayListAction,
} from "./logic";
import RequestTable from "../../components/ClientAdminRequestTable/RequestTable.jsx";
import { Card, Divider, Popover } from "antd";
import SvgLoader from "../../common/SvgLoader/index.js";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { HiPause, HiPlay } from "react-icons/hi";
import WavesurferPlayer from "@wavesurfer/react";
import { IoClose } from "react-icons/io5";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { LuLink } from "react-icons/lu";
import { storeTrackIdAction } from "../explorePage/logic.js";
import axios from "axios";
import { getCookie } from "../../common/utils/index.js";
import Loader from "../../components/Loader/Loader.jsx";
import { toast } from "react-toastify";
import BriefRequestDetailsModal from "../../components/BriefRequestDetailsModal/BriefRequestDetailsModal.jsx";
import TrackCard from "../../components/TrackCard/index.js";

const MenuCard = ({ selectedMenu, title, icon: Icon, count }) => {
    return (
        <div
            className={`card-square ${selectedMenu === title && "card-square-active"
                }`}
            key={title}
        >
            <div className="icon-background">
                <Icon size={60} />
            </div>
            <div className="text-country">{title}</div>
            <div className="text-songs-count">{count}</div>
        </div>
    );
};
const MyGallery = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedMenu, setSelectedMenu] = useState("My Briefs");
    const [selectedBriefRequest, setSelectedBriefRequest] = useState(false);
    const [showBriefRequestDetailsModal, setShowBriefRequestDetailsModal] =
        useState(false);
    const role = getCookie("roles");
    const [currentSongIndex, setCurrentSongIndex] = useState();
    const [wavesurfer, setWavesurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [resetList, setResetList] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const [audioBlob, setAudioBlob] = useState();
    const [playlistData, setPlaylistData] = useState();

    const blankAudio = "/music/silent_quarter-second.mp3";

    const handleViewBRDetails = (e, data) => {
        setSelectedBriefRequest(data);
        setShowBriefRequestDetailsModal(true);
    };

    useEffect(() => {
        dispatch(getGalleryCountAction({}));
        dispatch(getMyBriefsAction({}));
    }, []);

    const onChangeGalleryTabs = (tab) => {
        setSelectedMenu(tab);

        if (tab === "My Briefs") {
            dispatch(getMyBriefsAction({}));
        }

        if (tab === "My Collection") {
            dispatch(getPlayListAction({}));
        }

        if (tab === "My Downloads") {
            dispatch(getMyDownloadsAction({}));
        }
        if (tab === "Likes") {
            dispatch(getMyLikesAction({}));
        }
        if (tab === "My Notes") {
            dispatch(getMyNotesAction({}));
        }
    };

    const { data: getGalleryCountData, loading: getGalleryCountLoading } =
        useSelector((state) => state.getGalleryCountData);

    const { data: getMyBriefsData, loading: getMyBriefsLoading } = useSelector(
        (state) => state.getMyBriefsData
    );
    const { data: getPlayListData, loading: getPlayListLoading } = useSelector(
        (state) => state.getPlayListData
    );
    const { data: getMyCollectionData, loading: getMyCollectionLoading } =
        useSelector((state) => state.getMyCollectionData);
    const { data: getMyDownloadsData, loading: getMyDownloadsLoading } =
        useSelector((state) => state.getMyDownloadsData);
    const { data: getMyLikesData, loading: getMyLikesLoading } = useSelector(
        (state) => state.getMyLikesData
    );
    const { data: getMyNotesData, loading: getMyNotesLoading } = useSelector(
        (state) => state.getMyNotesData
    );

    const { data: storeTrackId } = useSelector((state) => state?.storeTrackId);
    const handleModalOpen = (data) => {
        setSelectedBriefRequest(data);
        setShowBriefRequestDetailsModal(true);
    };
    const briefRequestColumns = [
        {
            title: "",
            dataIndex: "key",
            key: "key",
            render: (name, record, index) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 300,
            render: (name, record, index) => {
                return <>Brief Request</>;
            },
        },
        {
            title: "Request Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
        },
        {
            title: "Deadline",
            dataIndex: "deadline_final",
            key: "deadline_final",
            render: (deadline_final) =>
                deadline_final ? moment(deadline_final).format("DD-MM-YYYY") : "  ",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type, record) => {
                if (type !== "Brief") {
                    return (
                        <span
                            onClick={() => handleModalOpen(record)}
                            style={{ cursor: "pointer" }}
                        >
                            {type ? type : "New Brief"}
                        </span>
                    );
                } else {
                    return <span>{type ? type : "New Brief"}</span>;
                }
            },
        },
        {
            title: "Link to Track",
            dataIndex: "linkToTrack",
            key: "linkToTrack",
            render: (link, record) => {
                if (record.type === "Brief") {
                    return null;
                } else {
                    return (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate(`/track-details?${record?.parent_track_id}`)
                            }
                        >
                            {link ? link : "Master"}
                        </span>
                    );
                }
            },
        },

        {
            title: "Status",
            dataIndex: "final_status",
            key: "final_status",
            render: (status) => (
                <div>
                    {status == "PENDING" ? (
                        <>
                            <div className="ar-pending-text" style={{ border: "none" }}>
                                Pending
                            </div>
                        </>
                    ) : status == "APPROVED" ? (
                        <>
                            <div className="ar-approved-text">Approved</div>
                        </>
                    ) : (
                        <div className="ar-rejected-text">Rejected</div>
                    )}
                </div>
            ),
        },
    ];

    const handleCollection = (data) => {
        setSelectedMenu("Collection Details");
        setPlaylistData(data);
        dispatch(getMyCollectionAction({ playlist_id: data?.id }));
    };

    useEffect(() => {
        if (playlistData?.id) {
            dispatch(getMyCollectionAction({ playlist_id: playlistData?.id }));
        }
        dispatch(getMyDownloadsAction({}));
        dispatch(getMyLikesAction({}));
    }, [resetList]);

    useEffect(() => {
        if (storeTrackId?.track_id) {
            axios
                .get(
                    `${process.env.apiUrl}playTrack?track_id=${storeTrackId?.track_id}`,
                    {
                        headers: {
                            Authorization: getCookie("accessToken"),
                        },
                        responseType: "arraybuffer",
                    }
                )
                .then((response) => {
                    setIsPlaying(true);
                    const blob = new Blob([response.data]);

                    setAudioBlob(URL.createObjectURL(blob));
                })
                .catch((err) => {
                    console.log(err?.response?.statusText);
                    toast.error(err?.response?.request?.statusText || "File Not Found", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                });
        }
    }, [storeTrackId]);

    const onPlayPause = (song, idx) => {
        wavesurfer && wavesurfer.playPause();
        // setCurrentSongIndex(null);
    };

    const handlePlayCurrent = (song, idx) => {
        // wavesurfer && wavesurfer.playPause();
        setCurrentSongIndex(idx);
        const data = {
            track_id: song?.id,
            title: song?.title,
            duration: song?.duration,
            genre_icon: song?.genre_icon,
            is_favourite: song?.is_favourite,
            notes: song?.notes,
        };

        dispatch(storeTrackIdAction(data));
        setIsPlaying(true);
    };

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const onReady = (ws) => {
        setWavesurfer(ws);
        setIsPlaying(true);
        setDuration(ws.getDuration());
        ws.on("audioprocess", () => {
            setCurrentTime(ws.getCurrentTime());
        });
    };

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        const formattedHours = h > 0 ? `${h}:` : "";
        const formattedMinutes = m < 10 ? `0${m}` : m;
        const formattedSeconds = s < 10 ? `0${s}` : s;

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    };

    const [open, setOpen] = useState(false);
    const [openIdx, setOpenIdx] = useState(false);
    const [comment, setComment] = useState("");
    const [commentsList, setCommentsList] = useState("");

    const hide = () => {
        setOpen(false);
        setComment("");
    };
    const handleOpenChange = (song, idx) => {
        setOpen(true);
        setOpenIdx(idx);

        axios
            .get(`${process.env.apiUrl}note?track_id=${song?.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setCommentsList(response?.data?.data);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "Not Found", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    const handleComment = (song) => {
        const data = {
            track_id: song?.id,
            note_text: comment,
        };

        axios
            .post(`${process.env.apiUrl}note`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                // setComment(response?.data?.data);
                setComment("");
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                hide();
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "Error", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                hide();
            });

        // hide();
    };

    const handleFavorite = (song) => {
        const data = {
            track_id: song?.id,
            action: !song?.is_liked,
        };

        axios
            .post(`${process.env.apiUrl}like`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setResetList((prev) => !prev);
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                // handleCollection(song);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.error || "File Not Found", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    const handlePageChange = (page, pageSize) => {
        setPageNo(page);
    };
    function formatDateFromTimestamp(timestamp) {
        // Convert timestamp to milliseconds
        const date = new Date(timestamp * 1);

        // Extract day, month, and year
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();

        // Format date as dd-mm-yyyy
        return `${day}-${month}-${year}`;
    }
    return (
        <div className="home-page">
            <Header showSearch={true} />
            <BriefRequestDetailsModal
                selectedBriefRequest={selectedBriefRequest}
                showBriefRequestDetailsModal={showBriefRequestDetailsModal}
                setShowBriefRequestDetailsModal={setShowBriefRequestDetailsModal}
                formatDateFromTimestamp={formatDateFromTimestamp}
                role={role}
                title={selectedBriefRequest?.type}
            // selectedClients={selectedClients}
            />
            <div className="gallery-page-components">
                <div className="menu-bar">
                    <div className="top-heading">
                        {" "}
                        <div className="back-btn" onClick={() => navigate(-1)}>
                            <MdOutlineKeyboardBackspace />
                            Back
                        </div>
                        <div className="heading">My Gallery</div>
                    </div>

                    <div className="card-wraper">
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Briefs")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Briefs"
                                icon={AiOutlineFileAdd}
                                count={`${getGalleryCountData?.data?.my_brief || 0} Request`}
                            />

                            {console.log({ selectedMenu })}
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Collection")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Collection"
                                icon={MdOutlineLibraryMusic}
                                count={`${getGalleryCountData?.data?.my_collection || 0} Songs`}
                            />
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Downloads")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Downloads"
                                icon={FiDownload}
                                count={`${getGalleryCountData?.data?.my_download || 0} Songs`}
                            />
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("Likes")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="Likes"
                                icon={BiLike}
                                count={`${getGalleryCountData?.data?.my_likes || 0} Songs`}
                            />
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Notes")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Notes"
                                icon={MdOutlineComment}
                                count={`${getGalleryCountData?.data?.my_notes || 0} Songs`}
                            />
                        </div>
                        {/* <div>
                            <MenuCard title="Request New Brief" icon={FaPlus} count="" />
                        </div> */}
                    </div>
                </div>
                <div className="main-gallery-component">
                    {selectedMenu === "My Briefs" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Briefs</div>
                                    <div className="description">All briefs</div>
                                    <div className="description">{`${getGalleryCountData?.data?.my_brief || 0
                                        } Request`}</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                            <div className="gallery-briefs-table-wrap">
                                {getMyBriefsLoading ? (
                                    <Loader height={0} marginTop={0} />
                                ) : (
                                    <div
                                        className="client-admin-tabs-section"
                                        style={{ padding: 0 }}
                                    >
                                        <RequestTable
                                            columns={briefRequestColumns}
                                            dataSource={getMyBriefsData?.response}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {selectedMenu === "My Collection" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Collection</div>
                                    <div className="description">{` ${(getPlayListData && getPlayListData?.playlist?.length) || 0
                                        } Playlist`}</div>
                                    <div className="description">{`${getGalleryCountData?.data?.my_collection || 0
                                        } Songs`}</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>

                            <div className="gallery-collection-card-wrap">
                                {getPlayListLoading ? (
                                    <Loader height={0} marginTop={0} />
                                ) : (
                                    <>
                                        {getPlayListData &&
                                            getPlayListData?.playlist?.map((item, idx) => (
                                                <div
                                                    className="collection-card"
                                                    key={idx}
                                                    onClick={() => handleCollection(item)}
                                                >
                                                    <div className="title">{item?.playlist_name}</div>
                                                    <div className="count">{item?.total_songs} Songs</div>
                                                </div>
                                            ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {selectedMenu === "Collection Details" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">
                                        {getMyCollectionData?.playlist_name}
                                    </div>
                                    <div className="description"></div>
                                    <div className="description">{`${getMyCollectionData?.record_count || 0
                                        } Songs`}</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                            {getMyCollectionLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyCollectionData && (
                                    <TrackCard
                                        getPlayListData={getPlayListData}
                                        trackListData={getMyCollectionData.tracks}
                                        resetList={resetList}
                                        setResetList={setResetList}
                                    />
                                )
                            )}
                        </div>
                    )}

                    {selectedMenu === "My Downloads" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Downloads</div>
                                    <div className="description">All Downloads</div>
                                    <div className="description">{`${getGalleryCountData?.data?.my_download || 0
                                        } Songs`}</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>

                            {getMyDownloadsLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyDownloadsData && (
                                    <TrackCard
                                        getPlayListData={getPlayListData}
                                        trackListData={getMyDownloadsData.tracks}
                                        resetList={resetList}
                                        setResetList={setResetList}
                                    />
                                )
                            )}
                        </div>
                    )}

                    {selectedMenu === "Likes" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">Likes</div>
                                    <div className="description">All Likes</div>
                                    <div className="description">{`${getGalleryCountData?.data?.my_likes || 0
                                        } Songs`}</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                            {getMyLikesLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyLikesData && (
                                    <TrackCard
                                        getPlayListData={getPlayListData}
                                        trackListData={getMyLikesData.tracks}
                                        resetList={resetList}
                                        setResetList={setResetList}
                                    />
                                )
                            )}
                        </div>
                    )}

                    {selectedMenu === "My Notes" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <MdOutlineComment size={70} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Notes</div>
                                    <div className="description">All Tracks</div>
                                    <div className="description">{`${getGalleryCountData?.data?.my_notes || 0
                                        } Songs`}</div>
                                    <div className="note">
                                        Music that you requested on Soniq Vault app will be shown
                                        here.
                                    </div>
                                    <div className="note">You can change this in Settings.</div>
                                </div>
                            </div>
                            {getMyNotesLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                <div className="song-list-wrap">
                                    {getMyNotesData?.tracks &&
                                        getMyNotesData?.tracks?.length !== 0 ? (
                                        getMyNotesData?.tracks?.map((song, idx) => (
                                            <Card className="song-list-card" key={idx}>
                                                <>
                                                    <div
                                                        className="thumbnail-wraper"
                                                        onClick={() =>
                                                            navigate(`/track-details?${song?.id}`)
                                                        }
                                                    >
                                                        <SvgLoader
                                                            svgName={
                                                                song?.genres.length !== 0
                                                                    ? `genre-${song?.genres[0]?.logo_url?.split(" ")[0]
                                                                    }-icon`
                                                                    : "genre-default"
                                                            }
                                                            className="thumbnail-image"
                                                        />
                                                        <IoMdInformationCircleOutline
                                                            size={30}
                                                            className="info-button"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </div>
                                                    <div>
                                                        {currentSongIndex === idx ? (
                                                            isPlaying === true ? (
                                                                <HiPause
                                                                    className="pause-btn"
                                                                    size={60}
                                                                    color="#fff"
                                                                    onClick={() => onPlayPause(song, idx)}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            ) : (
                                                                <HiPlay
                                                                    size={60}
                                                                    color="#fff"
                                                                    onClick={() => onPlayPause(song, idx)}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            )
                                                        ) : (
                                                            <HiPlay
                                                                size={60}
                                                                color="#fff"
                                                                onClick={() => handlePlayCurrent(song, idx)}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        )}
                                                    </div>

                                                    <div
                                                        className="audio-player-text"
                                                        style={{ width: "150px", cursor: "pointer" }}
                                                        onClick={() =>
                                                            navigate(`/track-details?${song?.id}`)
                                                        }
                                                    >
                                                        {song?.title}
                                                    </div>
                                                    <div style={{ width: "0px" }}>
                                                        <WavesurferPlayer
                                                            height={0}
                                                            width={0}
                                                            waveColor="#363636"
                                                            progressColor="#FFCD00"
                                                            autoplay={currentSongIndex === idx}
                                                            url={
                                                                currentSongIndex === idx
                                                                    ? audioBlob
                                                                    : blankAudio
                                                            }
                                                            onReady={(e) => onReady(e)}
                                                            onPlay={() => setIsPlaying(true)}
                                                            onPause={() => setIsPlaying(false)}
                                                        />
                                                    </div>
                                                    <div className="audio-player-text">
                                                        {currentSongIndex === idx
                                                            ? formatTime(currentTime)
                                                            : formatTime(0)}
                                                        /
                                                        {currentSongIndex === idx
                                                            ? formatTime(duration)
                                                            : formatTime(0)}
                                                        <br /> {song?.bpm} BPM
                                                    </div>
                                                    <div
                                                        className="audio-player-text"
                                                        style={{ width: "200px" }}
                                                    >
                                                        {song?.genres.map((item) => item?.name).join(", ")}
                                                    </div>
                                                    <div className="audio-player-icons">
                                                        <Popover
                                                            content={
                                                                <div>
                                                                    <div className="comment-text">
                                                                        My Note{" "}
                                                                        <IoClose
                                                                            size={20}
                                                                            color="#fff"
                                                                            className="all-icons"
                                                                            onClick={() => hide()}
                                                                        />
                                                                    </div>
                                                                    <Divider />

                                                                    {commentsList.length !== 0 && (
                                                                        <>
                                                                            <div className="comment-text">
                                                                                <ul>
                                                                                    {commentsList?.map((item) => (
                                                                                        <li key={item}>{item}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            <Divider />
                                                                        </>
                                                                    )}
                                                                    <div className="comment-text">
                                                                        <textarea
                                                                            className="comment-text"
                                                                            rows="3"
                                                                            placeholder="add a comment"
                                                                            // defaultValue={comment}
                                                                            onChange={(e) =>
                                                                                setComment(e?.target?.value)
                                                                            }
                                                                        />
                                                                        <SvgLoader
                                                                            svgName="save-comment"
                                                                            // className="thumbnail-image"
                                                                            width={40}
                                                                            height={40}
                                                                            // onClick={hide}
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => handleComment(song)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            // title="Title"
                                                            trigger="click"
                                                            open={open && openIdx === idx}
                                                            onOpenChange={() => handleOpenChange(song, idx)}
                                                            placement="bottomRight"
                                                            arrow={false}
                                                        >
                                                            <MdOutlineInsertComment
                                                                size={30}
                                                                color="#fff"
                                                                className="all-icons"
                                                            />
                                                        </Popover>
                                                    </div>
                                                </>
                                            </Card>
                                        ))
                                    ) : (
                                        <div className="section-genre-title">No Data Found</div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyGallery;
