/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
    MdOutlineInsertComment,
    MdOutlineKeyboardBackspace,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import WavesurferPlayer from "@wavesurfer/react";
import { Button, Divider, Popover, Table, Tabs } from "antd";
import { BsDownload } from "react-icons/bs";
import { MdOutlineLayers } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { LuLink } from "react-icons/lu";
import { HiPause, HiPlay } from "react-icons/hi";
import axios from "axios";
import { getCookie } from "../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { getEditsDetailsAction, getTrackDetailsAction } from "./logic";
import Loader from "../../components/Loader/Loader.jsx";
import SvgLoader from "../../common/SvgLoader/index.js";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";

const TrackDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [wavesurfer, setWavesurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [audioBlob, setAudioBlob] = useState();
    const [TabValue, setTabValue] = useState("Edits");
    const blankAudio = "/music/silent_quarter-second.mp3";

    const { data: getTrackDetailsData } = useSelector(
        (state) => state.getTrackDetailsData
    );
    const { data: getEditsDetailsData, loading: editsStemsTableLoading } =
        useSelector((state) => state.getEditsDetailsData);

    const config = {
        options: {
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColor: "#ffffff",
                        strokeWidth: 2,
                    },
                },
            },
            xaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: ["#ffffff"],
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                        fontWeight: 600,
                    },
                    offsetY: 5,
                },
            },
            yaxis: {
                show: false,
            },

            colors: ["#17B3FF"],
            labels:
                getTrackDetailsData?.response?.data[0]?.characters.map(
                    (item) => item.name
                ) || [],
            stroke: {
                width: 2,
                colors: ["#ffffff"],
            },
            fill: {
                opacity: 0.6,
            },
            // markers: {
            //     // size: (seriesIndex) => (seriesIndex === 0 ? 0 : 5),
            //     size: 5,
            //     // colors: "#FF0000",
            //     // colors: (seriesIndex) => (seriesIndex === 0 ? "#ffffff" : "#FA3750"),
            //     // colors: ["#8AC900", "#FFAE00", "#CD53FF", "#FA3750", "#008DD2", "#FFFFFF", "#FFFFFF", "#E31E23", "#009B13", "#F5B3B6", "#FFFFFF", "#FF7100"],

            // },
            markers: {
                size: 4,
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return `
                    <div class="arrow_box"> 
                        <span>
                            ${w.globals.labels[dataPointIndex]} 
                                : 
                            ${series[seriesIndex][dataPointIndex]} 
                        </span>
                    </div>`;
                },
            },
            chart: {
                toolbar: {
                    show: false,
                },
            },
        },
        series: [
            {
                name: "",
                data:
                    getTrackDetailsData?.response?.data[0]?.characters.map(
                        (item) => item.score
                    ) || [],
            },
            // {
            //     name: "",
            //     data: ["100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%"],
            // },
        ],
    };

    const onPlayPause = () => {
        wavesurfer && wavesurfer.playPause();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`;
    };

    const onChangeTab = (key) => {
        setEditStemsTableData([]);
        if (key == 1) {
            setTabValue("Edits");
            dispatch(
                getEditsDetailsAction({
                    id: location?.search?.substring(1),
                    type: "Edits",
                })
            );
        }
        if (key == 2) {
            setTabValue("Stem");
            dispatch(
                getEditsDetailsAction({
                    id: location?.search?.substring(1),
                    type: "Stem",
                })
            );
        }
    };

    const [editStemsTableData, setEditStemsTableData] = useState([]);
    const [editStemsDownload, setEditStemsDownload] = useState([]);
    const [commentsList, setCommentsList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openIdx, setOpenIdx] = useState(false);
    const [comment, setComment] = useState("");
    const [resetList, setResetList] = useState(false);
    const [currentSongIndex, setCurrentSongIndex] = useState();
    const [wavesurfer1, setWavesurfer1] = useState(null);
    const [isPlaying1, setIsPlaying1] = useState(false);

    const hide = () => {
        setOpen(false);
        setComment("");
    };

    const handleOpenChange = (song, idx) => {
        setOpen(true);
        setOpenIdx(idx);
        setCommentsList([]);

        axios
            .get(`${process.env.apiUrl}note?track_id=${song?.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setCommentsList(response?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleComment = (song) => {
        const data = {
            track_id: song?.id,
            note_text: comment,
        };

        axios
            .post(`${process.env.apiUrl}note`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setComment("");
                hide();
            })
            .catch((err) => {
                console.log(err);
                hide();
            });

        // hide();
    };

    const handleFavorite = (song) => {
        const data = {
            track_id: song?.id,
            action: !song?.is_liked,
        };

        axios
            .post(`${process.env.apiUrl}like`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setResetList((prev) => !prev);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onReady1 = (ws) => {
        setWavesurfer1(ws);
        setIsPlaying1(true);
    };

    const onPlayPause1 = () => {
        wavesurfer1 && wavesurfer1.playPause();
        // setIsPlaying(false);
    };

    const playSong = (song, idx) => {
        setCurrentSongIndex(idx);

        axios
            .get(`${process.env.apiUrl}playTrack?track_id=${song.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
                responseType: "arraybuffer",
            })
            .then((response) => {
                const blob = new Blob([response.data]);

                setAudioBlob(URL.createObjectURL(blob));
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "File Not Found", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });

        setIsPlaying(true);
    };

    useEffect(() => {
        const newTableData = getEditsDetailsData?.response?.data.map(
            (item, idx) => {
                return {
                    key: idx,
                    title: (
                        <div className="edit-stems-tabs-name">
                            <span className="edit-stems-title">{item?.title}</span>
                            <span className="edit-stems-player">
                                {currentSongIndex === idx ? (
                                    isPlaying ? (
                                        <HiPause
                                            className="pause-btn"
                                            size={30}
                                            color="#fff"
                                            onClick={() => onPlayPause1()}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <HiPlay
                                            size={30}
                                            color="#fff"
                                            onClick={() => onPlayPause1()}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )
                                ) : (
                                    <HiPlay
                                        size={30}
                                        color="#fff"
                                        onClick={() => playSong(item, idx)}
                                        style={{ cursor: "pointer" }}
                                    />
                                )}
                                <WavesurferPlayer
                                    height={0}
                                    width={0}
                                    waveColor="#363636"
                                    progressColor="#FFCD00"
                                    autoplay={true}
                                    url={currentSongIndex === idx ? audioBlob : blankAudio}
                                    onReady={(e) => onReady1(e)}
                                    onPlay={() => setIsPlaying1(true)}
                                    onPause={() => setIsPlaying1(false)}
                                />
                            </span>
                        </div>
                    ),
                    duration: item?.duration,
                    bpm: item?.bpm,
                    btn: (
                        <div
                            className="audio-player-icons"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <Popover
                                content={
                                    <div>
                                        <div className="comment-text">
                                            My Note{" "}
                                            <IoClose
                                                size={20}
                                                color="#fff"
                                                className="all-icons"
                                                onClick={hide}
                                            />
                                        </div>
                                        <Divider />
                                        {commentsList.length !== 0 && (
                                            <>
                                                <div className="comment-text">
                                                    <ul>
                                                        {commentsList?.map((commentItem, idx) => (
                                                            <li key={idx}>{commentItem}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <Divider />
                                            </>
                                        )}
                                        <div className="comment-text">
                                            <textarea
                                                className="comment-text"
                                                rows="3"
                                                placeholder="add a comment"
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                            <SvgLoader
                                                svgName="save-comment"
                                                width={40}
                                                height={40}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleComment(item)}
                                            />
                                        </div>
                                    </div>
                                }
                                trigger="click"
                                open={open && openIdx === idx}
                                onOpenChange={() => handleOpenChange(item, idx)}
                                placement="bottomRight"
                                arrow={false}
                            >
                                <MdOutlineInsertComment
                                    size={25}
                                    color="#fff"
                                    className="all-icons"
                                />
                            </Popover>
                            {item?.is_liked ? (
                                <GoHeartFill
                                    size={25}
                                    color={"#ff0000"}
                                    className="all-icons"
                                    onClick={() => handleFavorite(item)}
                                />
                            ) : (
                                <GoHeart
                                    size={25}
                                    color={"#fff"}
                                    className="all-icons"
                                    onClick={() => handleFavorite(item)}
                                />
                            )}
                            <FiPlus size={25} color="#fff" className="all-icons" />
                            <LuLink size={25} color="#fff" className="all-icons" />
                        </div>
                    ),
                };
            }
        );

        setEditStemsTableData(newTableData);

        setEditStemsDownload(
            getEditsDetailsData?.response?.data?.map((item) => item?.id)
        );
    }, [getEditsDetailsData, open, comment, isPlaying, commentsList]);

    const columns = [
        {
            title: "Music Title",
            dataIndex: "title",
            key: "title",
            width: "40%",
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
        },
        {
            title: "BPM",
            dataIndex: "bpm",
            key: "bpm",
        },
        {
            title: (
                <div className="table-header-btn-wrap">
                    <Button
                        className="header-btn"
                        shape="round"
                        icon={<MdOutlineLayers />}
                        onClick={() =>
                            navigate("/request-edits-stems", {
                                state: {
                                    type: "Stems",
                                    track_id: location?.search?.substring(1),
                                },
                            })
                        }
                    >
                        Request Stems
                    </Button>
                    <Button
                        className="header-btn"
                        shape="round"
                        icon={<MdOutlineModeEdit />}
                        onClick={() =>
                            navigate("/request-edits-stems", { state: { type: "Edits" } })
                        }
                    >
                        Request New Edit
                    </Button>
                    <BsDownload
                        size={30}
                        color="#fff"
                        style={{ cursor: "pointer" }}
                        onClick={() => downloadTrack()}
                    />
                </div>
            ),
            dataIndex: "btn",
            key: "btn",
        },
    ];

    const items = [
        {
            key: "1",
            label: "Edits",
            children: editsStemsTableLoading ? (
                <Loader height={0} marginTop={0} />
            ) : (
                <Table
                    dataSource={editStemsTableData}
                    columns={columns}
                    bordered={false}
                // scroll={{ y: 55 * 5 }}

                />
            ),
        },
        {
            key: "2",
            label: "Stems",
            children: <Table dataSource={editStemsTableData} columns={columns} />,
        },
    ];

    useEffect(() => {
        setEditStemsTableData([]);

        dispatch(
            getEditsDetailsAction({
                id: location?.search?.substring(1),
                type: TabValue,
            })
        );
    }, [resetList]);

    useEffect(() => {
        dispatch(getTrackDetailsAction({ id: location?.search?.substring(1) }));

        axios
            .get(
                `${process.env.apiUrl}playTrack?track_id=${location?.search?.substring(
                    1
                )}`,
                {
                    headers: {
                        Authorization: getCookie("accessToken"),
                    },
                    responseType: "arraybuffer",
                }
            )
            .then((response) => {
                setIsPlaying(true);
                const blob = new Blob([response.data]);

                setAudioBlob(URL.createObjectURL(blob));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const onReady = (ws) => {
        setWavesurfer(ws);
        setIsPlaying(false);
        setDuration(ws.getDuration());
        ws.on("audioprocess", () => {
            setCurrentTime(ws.getCurrentTime());
        });
    };

    const downloadTrack = () => {
        axios
            .get(`${process.env.apiUrl}download?track_ids=${editStemsDownload}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
                responseType: "arraybuffer",
            })
            .then((response) => {
                const blob = new Blob([response.data], { type: "application/zip" });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.zip");
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.request?.statusText || "File Not Found", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="home-page-components">
                <div
                    className="back-btn"
                    style={{ position: "absolute" }}
                    onClick={() => navigate(-1)}
                >
                    <MdOutlineKeyboardBackspace />
                    Back
                </div>
                <div className="radar-and-player">
                    {getTrackDetailsData?.response?.data[0]?.characters.length !== 0 ? (
                        <ReactApexChart
                            options={config.options}
                            series={config.series}
                            type="radar"
                            height="400"
                            width={450}
                        />
                    ) : (
                        <div className="no-data-taxt">No data Found</div>
                    )}
                    <div className="music-player-wrap">
                        <div className="track-title">
                            {getTrackDetailsData?.response?.data[0]?.title}
                        </div>
                        <div className="track-details">
                            <span>
                                <div>
                                    Genre: &nbsp;{" "}
                                    {getTrackDetailsData?.response?.data[0]?.genres
                                        .map((itm) => itm?.name)
                                        .join(", ")}
                                </div>
                                <div>
                                    Moods: &nbsp;{" "}
                                    {getTrackDetailsData?.response?.data[0]?.moods
                                        .map((itm) => itm?.name)
                                        .join(", ")}
                                </div>
                            </span>
                            <span style={{ borderRight: "1px solid #fff" }}></span>
                            <span>
                                <div>
                                    Duration: {formatTime(currentTime)} / {formatTime(duration)}
                                </div>
                                <div>
                                    BPM: &nbsp; {getTrackDetailsData?.response?.data[0]?.bpm}
                                </div>
                            </span>
                        </div>

                        <div className="music-player-wave-wraper">
                            {audioBlob &&
                                (
                                    isPlaying === true ? (
                                        <HiPause
                                            className="pause-btn"
                                            size={60}
                                            color="#fff"
                                            onClick={() => onPlayPause()}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <HiPlay
                                            size={60}
                                            color="#fff"
                                            onClick={() => onPlayPause()}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ))}
                            <div>
                                <div>
                                    <WavesurferPlayer
                                        height={80}
                                        waveColor="#363636"
                                        progressColor="#FFCD00"
                                        autoplay={false}
                                        url={audioBlob || blankAudio}
                                        onReady={(e) => onReady(e)}
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs-section">
                <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
            </div>
        </div>
    );
};

export default TrackDetails;
