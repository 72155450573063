const MODAL = "MODAL";
const MODAL_CLOSE = "MODAL_CLOSE";
const INITIAL_STATE = {
  type: "",
  open: false,
  dialog: false,
  size: "large",
  data: {},
  className: "",
};

export const modalAction = (payload) => ({
  type: MODAL,
  payload,
});

export const modalCloseAction = () => ({
  type: MODAL_CLOSE,
  payload: INITIAL_STATE,
});

export const modalReducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
  case MODAL: {
    return {
      ...state,
      open: true,
      ...action.payload,
    };
  }
  case MODAL_CLOSE: {
    return {
      ...state,
      ...action.payload,
    };
  }
  default:
    return state;
  }
};
