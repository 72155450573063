import React from "react";
import SvgLoader from "../../common/SvgLoader";

const CircularCard = ({ name, count, icon, i }) => {
  return (
    <div className="card-circular" key={i}>
      <div className="card-circular-image">
        {/* <img src="/images/eodb-logo.png" alt=""></img> */}
        <SvgLoader

          svgName={`genre-${icon}-icon`}
          height={100}
          width={100}
          className="svg-image-card"
        />
      </div>
      <div className="text-mood">{name}</div>
      <div className="text-mood-count">{count} Songs</div>
    </div>
  );
};

export default CircularCard;
