import React, { useState } from "react";
import SvgLoader from "../../common/SvgLoader";
// import Search from "antd/es/transfer/search";
// import { Badge } from "antd";
// import { HiOutlineBell } from "react-icons/hi2";
import { getCookie } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "antd";
import axios from "axios";

function Header({ showSearch }) {
  // const onSearch = (value, _e, info) => console.log(info?.source, value);
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  const getPanelValue = (searchText) => {

    axios
      .get(`${process.env.apiUrl}search?search=${searchText}`, {
        headers: {
          Authorization: getCookie("accessToken"),
        },
      })
      .then((response) => {
        // let data = response?.data?.data;
        console.log("response", response);


        const data = response?.data?.data.map((item) => ({
          label: `${Object.keys(item)} : ${Object.values(item)}`,
          value: `${Object.keys(item)} : ${Object.values(item)}`,
        }));

        setOptions(data);
      })
      .catch((err) => {
        console.log(err);
      });

  }
  // !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];

  const onSelect = (data) => {
    console.log("onSelect", data);

    const [key, value] = data.split(" : ");

    const jsonObject = {
      [key]: value
    };

    console.log(Object.keys(jsonObject));

    console.log({ jsonObject });

    if (Object.keys(jsonObject)[0] === "Genre") {
      navigate("/explore", {
        state: { genreId: Object.values(jsonObject)[0], title: `Genre: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Mood") {
      navigate("/explore", {
        state: { moodId: Object.values(jsonObject)[0], title: `Mood: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Theme") {
      navigate("/explore", {
        state: { themeId: Object.values(jsonObject)[0], title: `Theme: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Character") {
      navigate("/explore", {
        state: { characterId: Object.values(jsonObject)[0], title: `Character: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Track") {
      navigate("/explore", {
        state: { trackId: Object.values(jsonObject)[0], title: `Track: ${Object.values(jsonObject)[0]}` },
      })
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <div className="header">
      {/* <div className="logo"></div> */}
      <SvgLoader
        svgName="soniq-vault-logo"
        width={150}
        height={130}
        className="soniq-vault-logo"
        onClick={() => navigate("/home")}
      />
      <div className="search-box" style={{ visibility: showSearch ? "visible" : "hidden" }}>
        {/* <Search
          placeholder="Search Genre, Moods...."
          allowClear
          onSearch={onSearch}
          style={{
            width: 200,
          }}
          className="search-field"

        /> */}
        <AutoComplete
          value={value}
          options={options}
          style={{
            width: "100%",
          }}
          allowClear
          onSelect={onSelect}
          onSearch={(text) => setOptions(getPanelValue(text))}
          onChange={onChange}
          placeholder="Search Genre, Moods...."
          className="search-field"
        />
      </div>
      <div className="user-details" style={{ visibility: showSearch ? "visible" : "hidden" }}>
        <div className="user-name">{getCookie("user")}</div>
        <div className="logo">
          {/* <SvgLoader svgName="no-image-found" width={50} height={50} /> */}
        </div>
        {/* <Badge count={5} size="small" offset={[-10, 0]}>
          <HiOutlineBell color="#fff" size={40} />
        </Badge> */}
      </div>
    </div>
  );
}

export default Header;
