/* eslint-disable indent */
import { genericActionCreator } from "../../app/epics";

const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const GET_HOME_CARDS = "GET_HOME_CARDS";
const GET_HOME_CARDS_SUCCESS = "GET_HOME_CARDS_SUCCESS";
const GET_HOME_CARDS_FAILURE = "GET_HOME_CARDS_FAILURE";
const GET_HOME_CARDS_RESET = "GET_HOME_CARDS_RESET";

const GET_RECENTLY_ADDED = "GET_RECENTLY_ADDED";
const GET_RECENTLY_ADDED_SUCCESS = "GET_RECENTLY_ADDED_SUCCESS";
const GET_RECENTLY_ADDED_FAILURE = "GET_RECENTLY_ADDED_FAILURE";
const GET_RECENTLY_ADDED_RESET = "GET_RECENTLY_ADDED_RESET";


export const getHomeCardsAction = (payload) => genericActionCreator(
    GET_HOME_CARDS,
    payload.params,
    {
        url: `${process.env.apiUrl}home`,
        method: "GET",
        auth: true,
    },
);

export const getHomeCardsResetAction = () => ({
    type: GET_HOME_CARDS_RESET,
});

export function getHomeCardsReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_HOME_CARDS_RESET: {
            return INITIAL_STATE;
        }
        case GET_HOME_CARDS: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_HOME_CARDS_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_HOME_CARDS_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}




export const getRecentlyAddedAction = (payload) => genericActionCreator(
    GET_RECENTLY_ADDED,
    payload.params,
    {
        url: `${process.env.apiUrl}recentlyAdded`,
        method: "GET",
        auth: true,
    },
);

export const getRecentlyAddedResetAction = () => ({
    type: GET_RECENTLY_ADDED_RESET,
});

export function getRecentlyAddedReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_RECENTLY_ADDED_RESET: {
            return INITIAL_STATE;
        }
        case GET_RECENTLY_ADDED: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_RECENTLY_ADDED_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_RECENTLY_ADDED_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

